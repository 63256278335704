import { headers, getOperator, globalConfig, deepClone } from "@/utils";
import { Picklist, ScaleBuisinessRuleSection } from "@/types";
import { settings } from "@/settings";
import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;
import {
  beautifyJSONString,
  formatDateLocale,
  isEmpty,
} from "@/utils/configuration/formatters-config";
import { api, apiAnonymousToken } from "@/auth/api";
import moment from "moment";
import { extract, setOrderedRule, setRule } from "@/commons/businessRules";
import store from "@/store";

const odm_supportingdocument = `odm-supportingdocument`;
const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}`;

export const ActionContextReeavaluateValueKeys: string[] = ["itemUID"];

export const ActionContextClosingValueKeys: string[] = ["itemUID"];

export const ActionContextIntValueKeys: string[] = ["itemUID"];

export const outputFormConfiguration: any = {
  itemUID: ["itemUID"],
  
};
export const outputFormInitConfiguration: any = {
  itemUID: ["itemUID"],
  
};
const buisinessRuleResults = {
  itemUID: '',
};


export function searchAssessmentContextList(filter: any): Promise<Picklist> {
  return new Promise<Picklist>(async (resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: "supportingdocument-assessment-context",
      },
    };
    qc_header.qc.parameters = {
      ...filter,
    };
    const operators: any = [];

    if (filter && !isEmpty(filter.id)) {
      operators.push(
        getOperator(
          "supportingdocument_assessment_context",
          "id",
          "LIKE",
          globalConfig.formatters.getLikeValue(filter.id),
          "id"
        )
      );
    }

    if (filter && !isEmpty(filter.contextType)) {
      operators.push(
        getOperator(
          "supportingdocument_assessment_context",
          "context_type",
          "LIKE",
          globalConfig.formatters.getLikeValue(filter.contextType),
          "context_type"
        )
      );
    }

    if (filter && !isEmpty(filter.validity_from)) {
      operators.push(
        getOperator(
          "supportingdocument_assessment_context",
          "validity_from",
          "EQUAL",
          globalConfig.formatters.getEqualValue(moment(filter.validity_from, 'DD/MM/YYYY').format('YYYY-MM-DD')),
          "validity_from"
        )
      );
    }

    if (filter && !isEmpty(filter.validity_until)) {
      operators.push(
        getOperator(
          "supportingdocument_assessment_context",
          "validity_until",
          "EQUAL",
          globalConfig.formatters.getEqualValue(moment(filter.validity_until, 'DD/MM/YYYY').format('YYYY-MM-DD')),
          "validity_until"
        )
      );
    }

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
      qc_header.qc.otherOrders = null;
      qc_header.qc.offset = 0;
      qc_header.qc.limit = 100;
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });

    axios
      .get(url, {
        headers: _headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        Notify.create({
          message: `Query returned an error ${err}`,
          color: "negative",
        });
      });
  });
}

export async function getActionContext(resourceUid: string) {

  const response= await   api()
      .get(`${baseUrl}/assessmentcontext/${resourceUid}/`)
      let   res: any 
      if (response.data){
          res =response.data
         
        res.id = res?.resourceUid ? res.resourceUid : '';
        res.contextType = res?.contextType?.rawValue ? res.contextType.rawValue : '';
        res.validity_from  = res?.validity?.from ? formatDateLocale(res.validity?.from, "YYYY-MM-DD", "fr", "DD/MM/YYYY") : '';
        res.validity_until = res?.validity?.until ? formatDateLocale(res.validity?.until, "YYYY-MM-DD", "fr", "DD/MM/YYYY") : '';
       
      /*  if (res.reevaluateRule) {
          const rule = setRule(res.reevaluateRule);
          const orderedRule: { [p: string]: string } = setOrderedRule(
            rule,
            ActionContextReeavaluateValueKeys
          );
      
          res.reevaluateRule.advancedConfigurationToggle
            ? ((
                store.state.queryBuilderModule.queryBuilder[
                  ScaleBuisinessRuleSection.REEVALUATIONBR as any
                ] as any
              ).selectedBuisinessRule.returnMultipleResults = true)
            : false;
          res.residualValue = {
            ruleEngineId: null,
            rule: 
                (
                  store.state.queryBuilderModule.queryBuilder[
                    ScaleBuisinessRuleSection.RESIDUALVALUE as any
                  ] as any
                )?.selectedBuisinessRule
             
          };
        }*/
     
          if (res.reevaluateRule && res.reevaluateRule.rule) {
            const advancedResult = deepClone(
              res.reevaluateRule.rule?.returnMultipleResults
            );
            if (res.reevaluateRule.rule.returnMultipleResults) {
              (
                store.state.queryBuilderModule.queryBuilder[
                  ScaleBuisinessRuleSection.REEVALUATIONBR as any
                ] as any
              ).selectedBuisinessRule = res.reevaluateRule.rule;
            }
            const extracRule = extract(
              res.reevaluateRule.rule,
              buisinessRuleResults
            );
            store.dispatch("queryBuilderModule/setbrResluts", {
              extracRule: extracRule,
              currentSection: ScaleBuisinessRuleSection.REEVALUATIONBR,
            });
            res.reevaluateRule = extracRule[0] || {};
            if (extracRule[0] && advancedResult) {
              res.reevaluateRule.advancedConfigurationToggle = true;
            } else {
              res.reevaluateRule.advancedConfigurationToggle = false;
            }
          }

          if (res.closingRule && res.closingRule.rule) {
            const advancedResult = deepClone(
              res.closingRule.rule?.returnMultipleResults
            );
            if (res.closingRule.rule.returnMultipleResults) {
              (
                store.state.queryBuilderModule.queryBuilder[ 
                  ScaleBuisinessRuleSection.CLOSINGBR as any
                ] as any
              ).selectedBuisinessRule = res.closingRule.rule;
            }
            const extracRule = extract(
              res.closingRule.rule,
              buisinessRuleResults
            );
            store.dispatch("queryBuilderModule/setbrResluts", {
              extracRule: extracRule,
              currentSection: ScaleBuisinessRuleSection.CLOSINGBR,
            });
            res.closingRule = extracRule[0] || {};
            if (extracRule[0] && advancedResult) {
              res.closingRule.advancedConfigurationToggle = true;
            } else {
              res.closingRule.advancedConfigurationToggle = false;
            }
          }

          if (res.intRule && res.intRule.rule) {
            
              (
                store.state.queryBuilderModule.queryBuilder[ 
                  ScaleBuisinessRuleSection.INTBR as any
                ] as any
              ).selectedBuisinessRule = res.intRule.rule;
            
            const extracRule = extract(
              res.intRule.rule,
              buisinessRuleResults
            );
            store.dispatch("queryBuilderModule/setbrResluts", {
              extracRule: extracRule,
              currentSection: ScaleBuisinessRuleSection.INTBR,
            });
            res.intRule = extracRule || {};
            for(const extract of extracRule){
                extract.advancedConfigurationToggle = true;
            }
            
             
          }

        return res

      }


}

export async function createActionContext(payload: any) {
  const url = `${baseUrl}/assessmentcontext/`;
  const body : any = {
    "resourceUid": payload.id,
    "objectType": "odm.supportingdocument.assessmentcontext",
    "systemUid": "odm-supportingdocument",
    "businessData": null,
    "daaq": "/",
    "contextType": {
      "rawValue": payload.contextType
    },
    "validity": {
      "from": !isEmpty(payload.validity_from) ? formatDateLocale(payload.validity_from, "DD/MM/YYYY", "fr", "YYYY-MM-DD") : null,
      "until": !isEmpty(payload.validity_until) ? formatDateLocale(payload.validity_until, "DD/MM/YYYY", "fr", "YYYY-MM-DD") : null,
    },
    "intRule": null,
    "reevaluateRule":null,
    "closingRule": null
  }
  if (payload.reevaluateRule ) {
    const rule = setRule(payload.reevaluateRule);
    const orderedRule: { [p: string]: string } = setOrderedRule(
      rule,
      ActionContextReeavaluateValueKeys
    );

    payload.reevaluateRule.advancedConfigurationToggle
      ? ((
          store.state.queryBuilderModule.queryBuilder[
            ScaleBuisinessRuleSection.REEVALUATIONBR as any
          ] as any
        ).selectedBuisinessRule.returnMultipleResults = true)
      : false;
      body.reevaluateRule = {
      ruleEngineId: null,
      rule: 
          (
            store.state.queryBuilderModule.queryBuilder[
              ScaleBuisinessRuleSection.REEVALUATIONBR as any
            ] as any
          )?.selectedBuisinessRule
    };
  }

  if (payload.closingRule ) {
    const rule = setRule(payload.closingRule);
    const orderedRule: { [p: string]: string } = setOrderedRule(
      rule,
      ActionContextClosingValueKeys
    );

    payload.closingRule.advancedConfigurationToggle
      ? ((
          store.state.queryBuilderModule.queryBuilder[
            ScaleBuisinessRuleSection.CLOSINGBR as any
          ] as any
        ).selectedBuisinessRule.returnMultipleResults = true)
      : false;
      body.closingRule = {
      ruleEngineId: null,
      rule: 
          (
            store.state.queryBuilderModule.queryBuilder[
              ScaleBuisinessRuleSection.CLOSINGBR as any
            ] as any
          )?.selectedBuisinessRule
    };
  }

  if (payload.intRule ) {
    const rule = setRule(payload.intRule);
    const orderedRule: { [p: string]: string } = setOrderedRule(
      rule,
      ActionContextIntValueKeys
    );

      ((
          store.state.queryBuilderModule.queryBuilder[
            ScaleBuisinessRuleSection.INTBR as any
          ] as any
        ).selectedBuisinessRule.returnMultipleResults = true)
    
      body.intRule = {
      ruleEngineId: null,
      rule: 
          (
            store.state.queryBuilderModule.queryBuilder[
              ScaleBuisinessRuleSection.INTBR as any
            ] as any
          )?.selectedBuisinessRule
    };
  }


  const data = await api().post(url, body);
  return data
}

export async function updateActionContext(payload: any) {
  const url = `${baseUrl}/assessmentcontext/${payload.id}/`;
  const body: any = {
    "resourceUid": payload.id,
    "objectType": "odm.supportingdocument.assessmentcontext",
    "systemUid": "odm-supportingdocument",
    "businessData": null,
    "daaq": "/",
    "contextType": {
      "rawValue": payload.contextType
    },
    "validity": {
      "from": payload.validity_from !== '' ? formatDateLocale(payload.validity_from, "DD/MM/YYYY", "fr", "YYYY-MM-DD") : null ,
      "until": payload.validity_until !== '' ? formatDateLocale(payload.validity_until, "DD/MM/YYYY", "fr", "YYYY-MM-DD") :  null ,
    },
    "intRule": null,
    "reevaluateRule": null,
    "closingRule": null, 
    
  }
  if (payload.reevaluateRule) {
    const rule = setRule(payload.reevaluateRule);
    const orderedRule: { [p: string]: string } = setOrderedRule(
      rule,
      ActionContextReeavaluateValueKeys
    );

    ((
          store.state.queryBuilderModule.queryBuilder[
            ScaleBuisinessRuleSection.REEVALUATIONBR as any
          ] as any
        ).selectedBuisinessRule.returnMultipleResults = true)
      
      body.reevaluateRule = {
      ruleEngineId: null,
      rule: 
          (
            store.state.queryBuilderModule.queryBuilder[
              ScaleBuisinessRuleSection.REEVALUATIONBR as any
            ] as any
          )?.selectedBuisinessRule
    };
  }

  if (payload.closingRule) {
    const rule = setRule(payload.closingRule);
    const orderedRule: { [p: string]: string } = setOrderedRule(
      rule,
      ActionContextClosingValueKeys
    );

    payload.closingRule.advancedConfigurationToggle
      ? ((
          store.state.queryBuilderModule.queryBuilder[
            ScaleBuisinessRuleSection.CLOSINGBR as any
          ] as any
        ).selectedBuisinessRule.returnMultipleResults = true)
      : false;
      body.closingRule = {
      ruleEngineId: null,
      rule: 
          (
            store.state.queryBuilderModule.queryBuilder[
              ScaleBuisinessRuleSection.CLOSINGBR as any
            ] as any
          )?.selectedBuisinessRule
    };
  }

  if (payload.intRule) {
    const rule = setRule(payload.intRule);
    const orderedRule: { [p: string]: string } = setOrderedRule(
      rule,
      ActionContextIntValueKeys
    );

     ((
          store.state.queryBuilderModule.queryBuilder[
            ScaleBuisinessRuleSection.INTBR as any
          ] as any
        ).selectedBuisinessRule.returnMultipleResults = true)
 
      body.intRule = {
      ruleEngineId: null,
      rule: 
          (
            store.state.queryBuilderModule.queryBuilder[
              ScaleBuisinessRuleSection.INTBR as any
            ] as any
          )?.selectedBuisinessRule
    };
  }


  const data = await api().put(url, body);
  return data
}

