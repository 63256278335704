import {
    headers,
    getOperator,
    globalConfig,
    getEntity,
    upsertWithDataReturn,
    UON,
    deepClone,
    mountSelectFields,
    unmountSelectFieldsV2,
} from "@/utils";
import {
    Picklist,
    ScaleBuisinessRuleSection,
  } from "@/types";
import { settings } from "@/settings";

import {} from "@/utils";
import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;
import { api } from "@/auth/api";
import { formatDateLocale } from "@/utils/configuration/formatters-config";
import { extract, setOrderedRule, setRule } from "@/commons/businessRules";
import store from "@/store";
import moment from "moment";
import { createProductCategoryClass } from "../order/orderApi";



export const VehiculeCommisionKeys: string[] = [
  "currency",
  "amount",
  "amountMin",
  "amountMax",
  "calculationMethodOption",
  "annualRate",
  "annualRateMin",
  "annualRateMax",
  "basis",
];
export const outputFormVehiculeCommisionConfiguration: any = {
  
  amount: ["amount"]
  
};

export function searchRemuneration(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
      const url = settings.api_query_url;

      const qc_header: any = {
        qc: {
          queryId: "remuneration-product-product-feature"
        },
      };
      qc_header.qc.parameters = {
        ...filter,
      };
      const operators:any = [];

        operators.push(
          getOperator(
            "product_product_feature",
            "_discriminator",
            "EQUAL",
            globalConfig.formatters.getEqualValue('product-CommissionProductPack'),
            "_discriminator"
          )
        );
        if (filter && filter.id) {
            operators.push(
                getOperator(
                    "lower(product_product_feature",
                     "id)",
                     "LIKE",
                      globalConfig.formatters.getEqualValue('%' +filter.id + '%').toLowerCase(),
                     ""))
          }
          if (filter && filter.name) {
            operators.push(
                getOperator(
                    "lower(product_product_feature",
                     "_name)",
                     "LIKE",
                      globalConfig.formatters.getEqualValue('%' +filter.name + '%').toLowerCase(),
                     ""))
          }
          if (filter && filter.reference) {
            operators.push(
                getOperator(
                    "lower(product_product_feature",
                     "_reference)",
                     "LIKE",
                      globalConfig.formatters.getEqualValue('%' +filter.reference + '%').toLowerCase(),
                     ""))
          }
    

      if (operators.length > 0) {
        qc_header.qc.otherFilters = {
          expressions: [
            {
              and: {
                operators,
              },
            },
          ],
        };
        qc_header.qc.otherOrders = null;
        qc_header.qc.offset = 0;
        qc_header.qc.limit = 200;
      }

      const _headers = headers({
        qc: JSON.stringify(qc_header.qc),
      });

      axios
        .get(url, {
          headers: _headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
            Notify.create({
              message: `Query returned an error \n ${err}`,
              color: "negative",
            });
          });
    });
}


export async function saveCommission(packCommission: any, vehicleCommission : any, remunerationFinancingDetail :any ,remunerationFeesDetail: any,remunerationServiceDetail : any,remunerationIntermediaryDetail : any,remunerationAccessoryDetail: any ,remunerationAgentDetail: any ) {

    const packCommissionClone = deepClone(packCommission);
    const vehicleCommissionClone = deepClone(vehicleCommission);
    const FinancingCommissionClone = deepClone(remunerationFinancingDetail);
    const FeesCommissionClone = deepClone(remunerationFeesDetail);
    const ServiceCommissionClone = deepClone(remunerationServiceDetail);
    const IntermediaryCommissionClone = deepClone(remunerationIntermediaryDetail);
    const AccessoryCommissionClone = deepClone(remunerationAccessoryDetail);
    const AgentCommissionClone = deepClone(remunerationAgentDetail);

    const pack = await saveCommissionPack(deepClone(packCommissionClone));

    if (vehicleCommissionClone.name){
        const vehicleCom = await saveCommissionFils(vehicleCommissionClone,ScaleBuisinessRuleSection.COMMISSION_VEHICULE,"assetcommissionproduct"  );
        const packItem = await AssociatedCommissionToCommissionPack(
          pack,
          vehicleCom,
          "Vehicle_car"
        );

    }
    if (FinancingCommissionClone.name){
      const financingCom = await saveCommissionFils(FinancingCommissionClone,ScaleBuisinessRuleSection.COMMISSION_FININCING,"originationcommissionproduct"  );
      const packItem = await AssociatedCommissionToCommissionPack(
        pack,
        financingCom,
        "Financing_car"
      );

    }
    if (FeesCommissionClone.name){
      const feesCom = await saveCommissionFils(FeesCommissionClone,ScaleBuisinessRuleSection.COMMISSION_FEES,"originationcommissionproduct"  );
      const packItem = await AssociatedCommissionToCommissionPack(
        pack,
        feesCom,
        "Frais_car"
      );

    }

    if (ServiceCommissionClone.name){
      const serviceCom = await saveCommissionFils(ServiceCommissionClone,ScaleBuisinessRuleSection.COMMISSION_SERVICE,"assetcommissionproduct"  );
      const packItem = await AssociatedCommissionToCommissionPack(
        pack,
        serviceCom,
        "Services_car"
      );

    }

    if (IntermediaryCommissionClone.name){
      const intermediaryCom = await saveCommissionFils(IntermediaryCommissionClone,ScaleBuisinessRuleSection.COMMISSION_INTERMIDIARY,"originationcommissionproduct"  );
      const packItem = await AssociatedCommissionToCommissionPack(
        pack,
        intermediaryCom,
        "Intermediary_car"
      );

    }
    if (AccessoryCommissionClone.name){
      const AccCom = await saveCommissionFils(AccessoryCommissionClone,ScaleBuisinessRuleSection.COMMISSION_ACCESORY,"assetcommissionproduct"  );
      const packItem = await AssociatedCommissionToCommissionPack(
        pack,
        AccCom,
        "Accessory_car"
      );

    }
    if (AgentCommissionClone.name){
      const AgentCom = await saveCommissionFils(deepClone(AgentCommissionClone),ScaleBuisinessRuleSection.COMMISSION_AGENT,"originationcommissionproduct"  );
      const packItem = await AssociatedCommissionToCommissionPack(
        pack,
        AgentCom,
        "Agent_car"
      );

    }
    



    
    return pack;

}

async function saveCommissionPack(body: any) {

  unmountSelectFieldsV2(body, ["status"]);

  if (body.validity) {
    body.validity.from = body.validity.from
      ? formatDateLocale(body.validity.from, "DD/MM/YYYY", "fr")
      : "";
    body.validity.until = body.validity.until
      ? formatDateLocale(body.validity.until, "DD/MM/YYYY", "fr")
      : "";
  }

 const filter = { id: "ACTIVITY."+body.reference}
 const packFetures = await getCategoryClass(filter);
  if (Array.isArray(packFetures) && packFetures.length === 0) {
    const categoryClassBody = store.getters["remunerationModule/getEmptyProductCategoryclass"]
    categoryClassBody.resourceUid = "ACTIVITY."+body.reference
    const  productCategoryclass = await createProductCategoryClass ("ACTIVITY" , categoryClassBody)

  }
    body.classifications.find((classification :any) => {
      if (classification.categoryClass?.resourceUid === "ACTIVITY.PACK") {
          classification.categoryClass.resourceUid = "ACTIVITY."+body.reference;
      }
  });

    const packCommission = await upsertWithDataReturn(
      UON.SystemUid.odmProduct,
      body,
      "commissionproductpack"
    );
 
  return packCommission;
}


async function saveCommissionFils(body: any, commissionType: string , discriminator: string) {

    unmountSelectFieldsV2(body, ["status"]);
  
  if (body.validity) {
    body.validity.from = body.validity.from
      ? formatDateLocale(body.validity.from, "DD/MM/YYYY", "fr")
      : null;
    body.validity.until = body.validity.until
      ? formatDateLocale(body.validity.until, "DD/MM/YYYY", "fr")
      : null;

      body.validity.from  === "Invalid date" ? null : body.validity.from;

      body.validity.until === "Invalid date" ? null : body.validity.until;
  }

if (body.accessoryProductRule && body.accessoryProductRule?.length>0 && (store.state.queryBuilderModule.queryBuilder[
  commissionType as any
] as any
).briResults.length>0
) {
    
   
          ((store.state.queryBuilderModule.queryBuilder[
            commissionType as any
          ] as any
        ).selectedBuisinessRule.returnMultipleResults = true)
     
    body.accessoryProductRule = {
      ruleEngineId: null,
      rule:(
            store.state.queryBuilderModule.queryBuilder[
              commissionType as any
            ] as any
          )?.selectedBuisinessRule
        
    };
  }
  else{
      body.accessoryProductRule=null
  }


  let  CommissionVehicule
  if (discriminator==="assetcommissionproduct"){
       CommissionVehicule = await upsertWithDataReturn(
        UON.SystemUid.odmProduct,
        body,
        "assetcommissionproduct"
      );
    }
    else {
         CommissionVehicule = await upsertWithDataReturn(
        UON.SystemUid.odmProduct,
        body,
        "originationcommissionproduct"
      );
    }
   
    return CommissionVehicule;
  }




async function AssociatedCommissionToCommissionPack(
  pack: any,
  car: any,
  type: string
) {
  const packFetures = await getExistinglink(pack.resourceUid);
  const carItemExist = packFetures?.filter(
    (fetures: any) => fetures.feature.resourceUid === car.resourceUid
  );
  if (carItemExist.length == 0) {
    await AssociatedItemToPack(
      pack.resourceUid,
      car.resourceUid,
      car.objectType,
      type,
      carItemExist
    );
  }


  return pack;
}

async function getExistingCategoryClass(packid: string) {
  const url = `${settings.api_url}/odm-product/api/1/odm-product//productcategoryclass/${packid}/`;

  const pack = (await api().get(url)).data;

  return pack;
}



async function getExistinglink(packid: string) {
  const url = `${settings.api_url}/odm-product/api/1/odm-product/productpack/${packid}/simplepackagefeature/`;

  const pack = (await api().get(url)).data;

  return pack;
}


async function AssociatedItemToPack(
    packId: string,
    itemId: string,
    featureType: string,
    itemObjectType: string,
    existingLinkItem: any
  ) {
    const item = {
      resourceUid: existingLinkItem.length
        ? existingLinkItem[0].resourceUid
        : null,
      objectType: "odm.product.packagefeature.simplepackagefeature",
      businessData: null,
      daaq: "/",
      feature: {
        objectType: featureType,
        systemUid: "odm-product",
        resourceUid: itemId,
      },
      includeInPackagePrice: false,
      packageFeatureType: {
        objectType: "odm.product.packagefeaturetype",
        systemUid: "odm-product",
        resourceUid: itemObjectType,
      },
      required: true,
    };
  
    let data = "no data to return";
  
    if (!item.resourceUid) {
      const url = `/odm-product/api/1/odm-product/productpack/${packId}/simplepackagefeature/`;
      data = (await api().post(url, item)).data;
    } else {
      const url = `/odm-product/api/1/odm-product/simplepackagefeature/${item.resourceUid}/`;
      data = (await api().put(url, item)).data;
    }
    return data;
  }


  export async function getSelectedPackCommission(resourceUid: string): Promise<any> {
    const url = `/odm-product/api/1/odm-product/commissionproductpack/${resourceUid}/`;
    const data = (await api().get(url)).data;
    return data;
  }

  export async function getAssetCommission(resourceUid: string): Promise<any> {
    const url = `/odm-product/api/1/odm-product/assetcommissionproduct/${resourceUid}/`;
    const data = (await api().get(url)).data;
    return data;
  }

  export async function getOriginationCommission(resourceUid: string): Promise<any> {
    const url = `/odm-product/api/1/odm-product/originationcommissionproduct/${resourceUid}/`;
    const data = (await api().get(url)).data;
    return data;
  }


  function getBuildBody (body: any) {


    mountSelectFields(body, ["status"]);

        if (!body.status) {
            body.status = {
              type: {
                value: "",
              },
            };
        }

      body.validity = {
                        from:
                        body.validity && body.validity.from
                            //? moment( body.validity.from,'YYYY-MM-DD').format("DD/MM/YYYY")
                            ? body.validity.from
                            : "",
                        until:
                        body.validity && body.validity.until
                            //? moment( body.validity.until,'YYYY-MM-DD').format("DD/MM/YYYY")
                            ? body.validity.until
                            : "",
       };


 return body


}

  export function buildPackCommission(body: any) {

   const packCommision= getBuildBody(body)

    return packCommision
 
  }
  export async function buildCommission(body: any, type: string, discriminator: string, commissionType:string) {
     if (body.features){
        let productfeature 
        const filteredFeatures = body.features.find((feature:any) => 
          feature.packageFeatureType &&   feature.packageFeatureType.resourceUid === type
            );
         if (filteredFeatures && discriminator=== "product-AssetCommissionProduct" ){
            productfeature = await getAssetCommission(filteredFeatures.feature.resourceUid)

            productfeature = getBuildBody(productfeature)

            if (productfeature.accessoryProductRule?.rule) {
                    (
                      store.state.queryBuilderModule.queryBuilder[
                        commissionType as any
                      ] as any
                    ).selectedBuisinessRule = productfeature.accessoryProductRule.rule;
                  
                  const extracRule = extract(
                    productfeature.accessoryProductRule.rule,
                    buisinessRuleResults
                  );
                  store.dispatch("queryBuilderModule/setbrResluts", {
                    extracRule: extracRule,
                    currentSection: commissionType,
                  });
                  productfeature.accessoryProductRule = extracRule || {};
                  
                }



         }
         
         else if  (filteredFeatures && discriminator=== "product-OriginationCommissionProduct" ){
            productfeature = await getOriginationCommission(filteredFeatures.feature.resourceUid)

            productfeature = getBuildBody(productfeature)

            if (productfeature.accessoryProductRule?.rule) {

               
              (
                store.state.queryBuilderModule.queryBuilder[
                  commissionType as any
                ] as any
              ).selectedBuisinessRule = productfeature.accessoryProductRule.rule;
            
            const extracRule = extract(
              productfeature.accessoryProductRule.rule,
              buisinessRuleResults
            );
            store.dispatch("queryBuilderModule/setbrResluts", {
              extracRule: extracRule,
              currentSection: commissionType,
            });
            productfeature.accessoryProductRule = extracRule || {};
            
          }

            
         }

            return productfeature

        }


  }

  const buisinessRuleResults = {
    amount : "",
    amountMax: "",
    amountMin: "",
    annualRate: "",
    annualRateMax: "",
    annualRateMin: "",
    basis: "",
    calculationMethodOption: "",
    currency: ""
  };


  export function getCategoryClass(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
      const url = settings.api_query_url;

      const qc_header: any = {
        qc: {
          queryId: "product-product-category-class"
        },
      };
      qc_header.qc.parameters = {
        ...filter,
      };
      const operators:any = [];
        if (filter && filter.id) {
            operators.push(
              getOperator(
                "product_product_category_class",
                "id",
                "EQUAL",
                globalConfig.formatters.getEqualValue(filter.id),
                "id"
              )
            )
          }
    

      if (operators.length > 0) {
        qc_header.qc.otherFilters = {
          expressions: [
            {
              and: {
                operators,
              },
            },
          ],
        };
        qc_header.qc.otherOrders = null;
        qc_header.qc.offset = 0;
        qc_header.qc.limit = 200;
      }

      const _headers = headers({
        qc: JSON.stringify(qc_header.qc),
      });

      axios
        .get(url, {
          headers: _headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
            Notify.create({
              message: `Query returned an error \n ${err}`,
              color: "negative",
            });
          });
    });
}