import {
    headers,
    operator,
    setOtherFilters,
    getOperator, 
    globalConfig
} from "@/utils";
import {
    Picklist,
    QcHeader
  } from "@/types";
import { settings } from "@/settings";
import { api, apiAnonymousToken } from "@/auth/api";

import {} from "@/utils";
import i18n from "@/i18n";
const odm_party = `odm-party`
const baseUrl = `${settings.api_url}/${odm_party}/api/1/${odm_party}`


import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;
const api_query_url = settings.api_query_url;

export const getNetworkNodeListByNetwork = async (filter: any, offset? : any , limit?: any) => {
    const operators = [];
  
    const qc_header: QcHeader = {
      qc: {
        queryId: "search-party-network-node",
        offset: offset || 0,
        limit: limit || 100,
        parameters: {
          ...filter,
        },
      },
    };
     
    
    const PARTY_NETWROK_NODE = "party_network_node"
  
  
    if (filter && filter.network) {
      operators.push(
        operator({
          val: filter.network,
          table: PARTY_NETWROK_NODE,
          dbField: "network_id",
        }).EQUAL
      );
    }
  
    setOtherFilters(operators, qc_header);
  
    const res = await api().get(settings.api_query_url, {
      headers: headers({
        qc: JSON.stringify(qc_header.qc),
      }),
    });
    
    const response = {
        systemUid: "odm-party",
        items: res.data.map((resItem: any) => {
          return {
            value: resItem.networknodeid,
            label: i18n.global.t("netwrok.filter.networkNode.label."+resItem.networknodeid)
          };
        }),
      };
      return response
    
  }

  export function searchPartyNetworkNodeList(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
      const url = settings.api_query_url;
  
      const qc_header: any = {
        qc: {
          queryId: "search-party-network-node",
        },
      };
      qc_header.qc.parameters = {
        ...filter,
      };
      const operators:any = [];
      
      if (filter && filter.network !== undefined) {
        operators.push(
          getOperator(
            "party_network_node",
            "network_id",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.network),
            "network_id"
          )
        );
      }

      if (filter && filter.networkNode !== undefined) {
        operators.push(
          getOperator(
            "party_network_node",
            "id",
            "LIKE",
            globalConfig.formatters.getLikeValue(filter.networkNode),
            "id"
          )
        );
      }
      
      if (operators.length > 0) {
        qc_header.qc.otherFilters = {
          expressions: [
            {
              and: {
                operators,
              },
            },
          ],
        };
        qc_header.qc.otherOrders = null;
        qc_header.qc.offset = 0;
        qc_header.qc.limit = 100;
      }
  
      const _headers = headers({
        qc: JSON.stringify(qc_header.qc),
      });
  
      axios
        .get(url, {
          headers: _headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
            Notify.create({
              message: `Query returned an error \n ${err}`,
              color: "negative",
            });
          });
    });
}

export async function createNetwork(payload: any) {
  const url = `${baseUrl}/network/BODEMER_RESEAUX/networknode/`;
  const _payload = {
    node: [],
    partyRole: [],
    objectType: "odm.party.networknode",
    systemUid: "odm-party",
    resourceUid: payload,
  };

  try {
    const res = await apiAnonymousToken().post(url, _payload);

    if (res.status === 200) {
      Notify.create({
        message: i18n.global.t('networks.dialog.notify.addSuccess'),
        color: "positive",
      });
    }
    return res.data;
  } catch (error: any) {
    Notify.create({
      message: i18n.global.t('networks.dialog.notify.addError') + `${error.message}`,
      color: "negative",
    });
    throw error;
  }
}
