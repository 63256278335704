import i18n from "@/i18n";
import store from "@/store";
import {getAdditionalInformation, getReceivedFileInfo} from "@/store/services/picklist";
import { getDocumentValueConfig, getGatheringProcessFromOrder } from "@/store/services/task/actions/order/completePreconditionsService";
import { checkFormatDate, formatDateLocale } from "@/utils/configuration/formatters-config";
import {FileContent, taskCOMPLETEPRECONDITIONSMapping} from "@/types";
import {UON, deepClone, getEntity} from "@/utils";
import moment from "moment";
import Notify from 'quasar/src/plugins/Notify.js';;
import { getOrderEditMode } from "@/store/services/order/orderService";
import { getProcess, getRequiredDocument, getRequiredDocumentValidatedByBR } from "@/store/services/task/actions/uploadDocumentService";
import { moveDocument, removeReceivedFile } from "@/store/services/order/orderApi";
import { downloadFile } from "@/store/services/offerService";
import { deleteReceivedFile } from "./gathering-process";
import { concatFile } from "@/store/services/configuration/documentsService";
import { PDFDocument } from "pdf-lib";
import { jsPDF } from "jspdf";

export const processDocumentsOrder = async (payload: {
    gatheringProcess: any,
    requiredDocuments: any,
    supportingDocuments: any[],
    receivedFiles: any[],
    module:any
}) => {

    const {
        gatheringProcess,
        requiredDocuments,
        supportingDocuments,
        receivedFiles,
        module
    } = payload;

    const deleteManualDocs: any[] = [];
    let nbrTargetEntityNull = 0;
    

    const nbrDeleteManualDocs = deleteManualDocs.length ? deleteManualDocs.length : 0

    let listOfCheckpoints: any[] = []
    let nbrOfCheckPoint = 0;
    let nbrOfMandatoryDocuments = 0;
    let nbrOfMandatoryDocumentsValidated = 0;
    let nbrOfCheckPointValidated = 0;
    let nbrOfCheckPointNotValidated = 0;
    for (const requiredDocument of requiredDocuments){
        if(gatheringProcess.supportingDocuments.length===1){
            requiredDocument.supportingDocumentUid =gatheringProcess.supportingDocuments[0].resourceUid
        }else{
            const supportingDocument = gatheringProcess.supportingDocuments.filter((s: any) => 
            s.documentFamily.resourceUid === requiredDocument.supportingType[0].supportingDocument.resourceUid)[0];
            if(!requiredDocument.supportingDocumentUid && supportingDocument){
             requiredDocument.supportingDocumentUid =supportingDocument.resourceUid
            }
            
        }
       
    }
 

    for (const items of gatheringProcess.gatheringProcessItem[0].items) {
        const ListOfDocuments = []
        
        
        if (items.objectType.includes('fieldvalue') ) {
            nbrOfMandatoryDocuments++
            if (items.businessData) {
                items.status.resourceUid = 'COMPLETED'
                items.businessData = {}
                if(module==='taskModule') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                }
                nbrOfMandatoryDocumentsValidated++
            } else {
                items.status.resourceUid = 'PENDING'
                items.businessData = null
                if(module==='taskModule'){
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }
            }
            listOfCheckpoints.push(items)
        }

        if( items.objectType.includes('businessrulevalue')){
            nbrOfMandatoryDocuments++
            if (items.status.resourceUid === 'COMPLETED') {
                if(module==='taskModule') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                }
                nbrOfMandatoryDocumentsValidated++
            } else {
                if(module==='taskModule') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }
            }
          
            listOfCheckpoints.push(items)
        }
        if (items.objectType.includes('documentvalue')) {
            const supDocumentsFiltered = supportingDocuments.filter((s: any) => s.resourceUid === items.supportingDocument.resourceUid)[0];
            if (supDocumentsFiltered) {
                for (const document of requiredDocuments) {
                    let documentUpload = {};
                    let creationDate = "";
                    let status = "";
                    let comment = "";
                    let receivedFilesUid = "";
                    const doc = gatheringProcess.gatheringProcessItem[0].receivedFiles.filter(
                        (s: any) => s.content[0]?.documentType?.resourceUid === document.resourceUid
                    );
                    if ( doc.length > 0 ) {
                        documentUpload = {
                            format: "",
                            originalFileName: doc[doc.length - 1].content[0].label,
                            resourceUid: doc[doc.length - 1].fileReference.resourceUid,
                            objectType: doc[doc.length - 1].fileReference.objectType,
                            systemUid: doc[doc.length - 1].fileReference.systemUid,
                        };
                        creationDate = doc[doc.length - 1].receptionDate;
                        status = doc[doc.length - 1].content[0].status.resourceUid;
                        comment = doc[doc.length - 1].content[0].label;
                        receivedFilesUid = doc[doc.length - 1].resourceUid;

                    }
  
                    if(document.supportingDocumentUid===items.supportingDocument.resourceUid){
                        const filter = {
                            id: receivedFilesUid
                        }
                        const receivedFileInfo = await getReceivedFileInfo(filter)
                        let modified = ''
                        if (receivedFileInfo.length > 0) {
                            modified =receivedFileInfo[0].first_name + ' ' + receivedFileInfo[0].last_name
                        }
                        ListOfDocuments.push({
                            documentUpload: documentUpload,
                            creationDate: creationDate,
                            status: status,
                            comment: comment,
                            typeJustify: document.supportingType[0].supportingDocument.resourceUid,
                            mandatory: document.supportingType[0].mandatory,
                            documentType: document.resourceUid,
                            processResourceUid: gatheringProcess.resourceUid,
                            receivedFilesUid: receivedFilesUid,
                            whoModified: modified ? modified : ''
                        })
                    }
                    
                    
                    

                }
                nbrTargetEntityNull = nbrTargetEntityNull + 1
                items.documents = ListOfDocuments
                listOfCheckpoints.push(items)

            }
            for(const doc of items.documents){
                if (doc.mandatory) {
                    nbrOfMandatoryDocuments++
                    if (doc.status === 'QUALIFIED') {
                        nbrOfMandatoryDocumentsValidated++
                    }
                }
                if(doc.documentUpload && doc.documentUpload.originalFileName ){
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                }else{
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }
            }

        }
        const filter = {
            idCheck: items.resourceUid
        }
        const moreInfo = await getAdditionalInformation(filter)
        if (moreInfo.length > 0) {
            items.whoModified = moreInfo[0].first_name + ' ' + moreInfo[0].last_name
        }

    }

    // if (nbrOfCheckPointValidated === nbrOfCheckPoint) {
    //     store.dispatch('orderModule/setPreConditionValid', true)
    // }
    const documentValueItems = listOfCheckpoints.filter(item => item.objectType.includes('documentvalue'));
    const otherItems = listOfCheckpoints.filter(item => !item.objectType.includes('documentvalue'));
    listOfCheckpoints = [...documentValueItems, ...otherItems];
    const gatheringProcessOrder={
        resourceUid: gatheringProcess.resourceUid,
        nbrReceivedItems: nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint,
        nbrValidatedItems: nbrOfCheckPointValidated + '/' + nbrOfCheckPoint,
        nbrOfMandatoryDocumentsValidated: nbrOfMandatoryDocumentsValidated,
        nbrOfMandatoryDocuments: nbrOfMandatoryDocuments,
        requiredDocuments: requiredDocuments.length - nbrTargetEntityNull - nbrDeleteManualDocs,
        status: nbrOfCheckPointValidated === nbrOfCheckPoint ? "COMPLETED" : "PROCESSING",
        assessmentContext: gatheringProcess.contextType.rawValue,
        checkpoints: listOfCheckpoints
    }
    if(module==='taskModule'){
        const listOfGatheringProcessOrder = store.state.taskModule.gatheringProcessOrder || [];
        if(listOfGatheringProcessOrder.length > 0){
            const exists = listOfGatheringProcessOrder.some((item:any) => item.assessmentContext === gatheringProcessOrder.assessmentContext);
            if (!exists) {
                listOfGatheringProcessOrder.push(gatheringProcessOrder);
            }
            else {
                const index = listOfGatheringProcessOrder.findIndex((item: any) => item.assessmentContext === gatheringProcessOrder.assessmentContext);
                if (index !== -1) {
                    const existingItem = listOfGatheringProcessOrder[index];
                    if (existingItem.nbrOfMandatoryDocumentsValidated !== gatheringProcessOrder.nbrOfMandatoryDocumentsValidated) {
                        // Remplacer l'élément existant par gatheringProcessOrder
                        listOfGatheringProcessOrder[index] = gatheringProcessOrder;
                    }
                }
            }
        }else{
            listOfGatheringProcessOrder.push(gatheringProcessOrder);
        }
        const totalValidated = listOfGatheringProcessOrder.reduce((sum:any, item:any) => sum + item.nbrOfMandatoryDocumentsValidated, 0);
        const totalMandatory = listOfGatheringProcessOrder.reduce((sum:any, item:any) => sum + item.nbrOfMandatoryDocuments, 0);
        if (totalValidated === totalMandatory) {
            store.dispatch('orderModule/setPreConditionValid', true);
        } else {
            store.dispatch('orderModule/setPreConditionValid', false);
        }
        await store.dispatch("taskModule/gatheringProcessOrder", {
            request: {
                gatheringProcess: listOfGatheringProcessOrder,
            },
        }).catch(e => console.error(e));
    }
    if(module==='orderModule'){
        const listOfGatheringProcessOrder = store.state.taskModule.gatheringProcessOrder || [];
        if(listOfGatheringProcessOrder.length > 0){
            const exists = listOfGatheringProcessOrder.some((item:any) => item.assessmentContext === gatheringProcessOrder.assessmentContext);
            if (!exists) {
                listOfGatheringProcessOrder.push(gatheringProcessOrder);
            }
        }else{
            listOfGatheringProcessOrder.push(gatheringProcessOrder);
        }
        await store.dispatch("orderModule/gatheringProcessOrder", {
            request: {
                gatheringProcess: listOfGatheringProcessOrder,
            },
        }).catch(e => console.error(e));
    }
    if(module==='summaryPanelModule'){
        const listOfGatheringProcessOrderSummary= store.state.summaryPanelModule.gatheringProcessOrder || [];
        listOfGatheringProcessOrderSummary.push(gatheringProcessOrder)
        await store.dispatch("summaryPanelModule/gatheringProcessOrder", {
            request: {
                gatheringProcess: listOfGatheringProcessOrderSummary,
            },
        }).catch(e => console.error(e));
    }

    store.dispatch('orderModule/setPreConditionValid', false);
}

export const processDocumentsOffer =async (payload: {
    gatheringProcess: any 
    requiredDocuments: any,
    module:any

}) => {

    const {
        gatheringProcess,
        requiredDocuments,
        module
    } = payload;

    const deleteManualDocs: any[] = [];
    let nbrTargetEntityNull = 0;
    const nbrDeleteManualDocs = deleteManualDocs.length ? deleteManualDocs.length : 0

    const listOfCheckpoints: any[] = []
    let nbrOfCheckPoint = 0;
    let nbrOfCheckPointValidated = 0;
    let nbrOfCheckPointNotValidated = 0;
    for (const requiredDocument of requiredDocuments){
        if(gatheringProcess.supportingDocuments.length===1){
            requiredDocument.supportingDocumentUid =gatheringProcess.supportingDocuments[0].resourceUid
        }else{
            const supportingDocument = gatheringProcess.supportingDocuments.filter((s: any) => 
            s.documentFamily.resourceUid === requiredDocument.supportingType[0].supportingDocument.resourceUid)[0];
            if(!requiredDocument.supportingDocumentUid && supportingDocument){
             requiredDocument.supportingDocumentUid =supportingDocument.resourceUid
            }
            
        }
       
    }

    if (gatheringProcess.gatheringProcessItem[0] && gatheringProcess.gatheringProcessItem[0].items) {

        for (const items of gatheringProcess.gatheringProcessItem[0].items) {

            const ListOfDocuments = []
        
            if (items.objectType.includes('documentvalue')) {
                const supDocumentsFiltered = gatheringProcess.supportingDocuments.filter((s: any) => s.resourceUid === items.supportingDocument.resourceUid)[0];
                if (supDocumentsFiltered) {
                    for (const document of requiredDocuments) {
                        let documentUpload = {};
                        let creationDate = "";
                        let status = "";
                        let comment = "";
                        const doc = gatheringProcess.gatheringProcessItem[0].receivedFiles.filter(
                            (s: any) => s.content[0]?.documentType?.resourceUid === document.resourceUid
                        );
                        if (doc.length > 0 && items.status && items.status.resourceUid === 'COMPLETED') {
                            documentUpload = {
                                format: "",
                                originalFileName: doc[doc.length - 1].content[0].label,
                                resourceUid: doc[doc.length - 1].fileReference.resourceUid,
                                objectType: doc[doc.length - 1].fileReference.objectType,
                                systemUid: doc[doc.length - 1].fileReference.systemUid,
                            };
                            creationDate = doc[doc.length - 1].receptionDate;
                            status = doc[doc.length - 1].content[0].status.resourceUid;
                            comment = doc[doc.length - 1].content[0].label;
    
    
                        }
      
                        if(document.supportingDocumentUid===items.supportingDocument.resourceUid){
                            ListOfDocuments.push({
                                documentUpload: documentUpload,
                                creationDate: creationDate,
                                status: status,
                                comment: comment,
                                typeJustify: document.supportingType[0].supportingDocument.resourceUid,
                                documentType: document.resourceUid,
                                processResourceUid: gatheringProcess.resourceUid,
                            })
                        }
                        
                         
    
                    }
                    nbrTargetEntityNull = nbrTargetEntityNull + 1
                    items.documents = ListOfDocuments
                    listOfCheckpoints.push(items)

 
                }
            
    
            }

            
            if (items.objectType.includes('fieldvalue')) {
                if (items.businessData) {
                    items.status.resourceUid = 'COMPLETED'
                    items.businessData = {}
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    items.status.resourceUid = 'PENDING'
                    items.businessData = null
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }
            listOfCheckpoints.push(items)
            }

            

            const filter = {
                idCheck: items.resourceUid
            }
            const moreInfo = await getAdditionalInformation(filter)
            if (moreInfo.length > 0) {
                items.whoModified = moreInfo[0].first_name + ' ' + moreInfo[0].last_name
            }
        }
    }

}
export async function UploadDocument(files: any, resourceUid: any, childId: any,index:any) {
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        if (process.resourceUid === resourceUid) {
            const documentItem = process.checkpoints[childId]
            if (documentItem) {
                if(documentItem.documents[index].receivedFilesUid){
                    const body={file:files[0],
                        doc:documentItem.documents[index]
                    }
                    concatFile(body)
                }else{
                documentItem.documents[index].documentUpload = files[0];
                if (documentItem.documents && documentItem.documents[index].documentUpload) {
                    getBase64(documentItem.documents[index].documentUpload).then((base64) => {
                        const fileContent: FileContent = {
                            originalFileName: documentItem.documents[index].documentUpload.name,
                            format: 'application/pdf',//documentItem.documents[index].documentUpload.type,
                            content: base64,
                            relations: {
                                items: [
                                    {
                                        qualifier: {
                                            rawValue: documentItem.documents[index]?.documentType,
                                        },
                                        objectUON: {
                                            objectType: "odm.filemngt.filecontent",
                                            systemUid: UON.SystemUid.odmFileManagement,
                                        },
                                    },
                                ],
                            },
                        };
                        

                        store.dispatch("taskModule/uploadDocumentModule/uploadFile", {
                            request: fileContent,
                            documentType: documentItem.documents[index]?.documentType,
                            processid: process.resourceUid,
                            callback: (response: FileContent) =>
                                handleDocumentResponse(response, resourceUid, childId,index),
                        });
                    });
                } else {
                    Notify.create({
                        color: "negative",
                        message: "Erreur!",
                    });
                }
                }

            }

        }
    }
}
export async function UploadDocumentScanned(files: any, resourceUid: any, childId: any,index:any) {
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        if (process.resourceUid === resourceUid) {
            const documentItem = process.checkpoints[childId]
            if (documentItem) {
                if(documentItem.documents[index].receivedFilesUid){
                    const body={file:files[0].rawData,
                        doc:documentItem.documents[index]
                    }
                    concatFile(body)
                }else{
                    documentItem.documents[index].documentUpload = files[0].rawData;
                    if (documentItem.documents && documentItem.documents[index].documentUpload) {
                        store.dispatch("taskModule/uploadDocumentModule/uploadFile", {
                            request: files[0].rawData,
                            documentType: documentItem.documents[index]?.documentType,
                            processid: process.resourceUid,
                            callback: (response: FileContent) =>
                                handleDocumentResponse(response, resourceUid, childId,index),
                        });
                    } else {
                        Notify.create({
                            color: "negative",
                            message: "Erreur!",
                        });
                    }
                }
                
            }

        }
    }
}

export async function UploadDocumentOffer(files: any, resourceUid: any, childId: any,index:any) {
    for (const process of store.state.taskModule.gatheringProcess) {
        if (process.resourceUid === resourceUid) {
            const documentItem = process.gatheringProcessItem[0].items[childId]
            if (documentItem) {
                documentItem.documents[index].documentUpload = files[0];
                if (documentItem.documents && documentItem.documents[index].documentUpload) {
                    getBase64(documentItem.documents[index].documentUpload).then((base64) => {
                        const fileContent: FileContent = {
                            originalFileName: documentItem.documents[index].documentUpload.name,
                            format: documentItem.documents[index].documentUpload.type,
                            content: base64,
                            relations: {
                                items: [
                                    {
                                        qualifier: {
                                            rawValue: documentItem.documents[index]?.documentType,
                                        },
                                        objectUON: {
                                            objectType: "odm.filemngt.filecontent",
                                            systemUid: UON.SystemUid.odmFileManagement,
                                        },
                                    },
                                ],
                            },
                        };
                        

                        store.dispatch("taskModule/uploadDocumentModule/uploadFile", {
                            request: fileContent,
                            documentType: documentItem.documents[index]?.documentType,
                            processid: process.resourceUid,
                            callback: (response: FileContent) =>
                                handleDocumentOfferResponse(response, resourceUid, childId,index),
                        });
                    });


                } else {
                    Notify.create({
                        color: "negative",
                        message: "Erreur!",
                    });
                }
            }

        }
    }
}
export async function handleDocumentResponse(response: FileContent, resourceUid: any, childId: number,index: number) {
    let nbrOfMandatoryDocuments = 0;
    let nbrOfMandatoryDocumentsValidated = 0;
    const module = store.state.orderModule?.order?.reference ? 'orderModule' : 'taskModule';
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            process.checkpoints[childId].documents[index].documentUpload = response || {};
            process.checkpoints[childId].documents[index].creationDate = moment(new Date()).format("DD/MM/YYYY");
            process.checkpoints[childId].documents[index].status = 'QUALIFIED';
            process.checkpoints[childId].documents[index].comment = response.originalFileName;
            //process.checkpoints[childId].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
            process.checkpoints[childId].status.resourceUid = 'COMPLETED'
            process.checkpoints[childId].documents[index].receivedFilesUid = response.receivedFilesUid
            process.checkpoints[childId].documents[index].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
            const documentValue = deepClone(process.checkpoints[childId])
            delete documentValue.documents
            delete documentValue.whoModified
            delete documentValue.receivedFilesUid
            const IsValid=process.checkpoints[childId].documents.filter((s: any) => 
            s.status !== 'QUALIFIED');
            if(IsValid.length===0){
                documentValue.status = {
                    resourceUid: documentValue.status.resourceUid,
                    objectType: documentValue.status.objectType,
                    systemUid: documentValue.status.systemUid
                };
                documentValue.checkPointsConfig = {
                    resourceUid: documentValue.checkPointsConfig.resourceUid,
                    objectType: documentValue.checkPointsConfig.objectType,
                    systemUid: documentValue.checkPointsConfig.systemUid
                };
                documentValue.supportingDocument = {
                    resourceUid: documentValue.supportingDocument.resourceUid,
                    objectType: documentValue.supportingDocument.objectType,
                    systemUid: documentValue.supportingDocument.systemUid
                };
                store.dispatch("taskModule/updateDocumentValue", {
                    request: documentValue,
                });
            }
            
        }
        for (const items of process.checkpoints) {
            if( items.objectType.includes('documentvalue')){
                for(const doc of items.documents){
                    if (doc.mandatory) {
                        nbrOfMandatoryDocuments++
                        if (doc.status === 'QUALIFIED') {
                            nbrOfMandatoryDocumentsValidated++
                        }
                    }
                    if(doc.documentUpload && doc.documentUpload.originalFileName ){
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }else{
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }else{
                nbrOfMandatoryDocuments++
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    if(module==='taskModule') {
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }
                    nbrOfMandatoryDocumentsValidated++
                } else {
                    if(module==='taskModule') {
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint

        if (nbrOfCheckPointValidated === nbrOfCheckPoint) {
            // store.dispatch('orderModule/setPreConditionValid', true)
            process.status = "COMPLETED"
        }
        else {
            process.status = "PROCESSING"
        }


    }
    if (nbrOfMandatoryDocumentsValidated === nbrOfMandatoryDocuments) {
        store.dispatch('orderModule/setPreConditionValid', true)
    } else {
        store.dispatch('orderModule/setPreConditionValid', false);
    }
}

export async function handleDocumentOfferResponse(response: FileContent, resourceUid: any, childId: number,index: number) {
    for (const process of store.state.taskModule.gatheringProcess) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            process.gatheringProcessItem[0].items[childId].documents[index].documentUpload = response || {};
            process.gatheringProcessItem[0].items[childId].documents[index].creationDate = moment(new Date()).format("DD/MM/YYYY  HH:mm:ss");
            process.gatheringProcessItem[0].items[childId].documents[index].status = 'QUALIFIED';
            process.gatheringProcessItem[0].items[childId].documents[index].comment = response.originalFileName;
            process.gatheringProcessItem[0].items[childId].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
            process.gatheringProcessItem[0].items[childId].status.resourceUid = 'COMPLETED'
            const documentValue = deepClone(process.gatheringProcessItem[0].items[childId])
            delete documentValue.documents
            delete documentValue.whoModified
            const IsValid=process.gatheringProcessItem[0].items[childId].documents.filter((s: any) => 
            s.status !== 'QUALIFIED');
            if(IsValid.length===0){
                store.dispatch("taskModule/updateDocumentValue", {
                    request: documentValue,
                });
            }
            
        }
        for (const items of process.gatheringProcessItem[0].items) {
            if( items.objectType.includes('documentvalue')){
                for(const doc of items.documents){
                    if(doc.documentUpload && doc.documentUpload.originalFileName ){
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }else{
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }else{
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint

        if (nbrOfCheckPointValidated === nbrOfCheckPoint) {
            store.dispatch('orderModule/setPreConditionValid', true)
        }


    }
}

export function getBase64New(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const resp = reader.result as string;
            resolve(resp.split("base64,")[1]);
        };
        reader.onerror = (error) => reject(error);
    });
}
export function getBase64(file: any) {
    return new Promise(async (resolve, reject) => {
        try {
            if(file.type && (file.type === "image/png" || file.type === "image/jpeg")){
                const base64Image = await getBase64New(file);
                const pdf = new jsPDF();
                const img = new Image();
                img.src = `data:${file.type};base64,${base64Image}`; // Assurez-vous que le type d'image est correct
                img.onload = () => {
                    pdf.addImage(img, file.type === 'image/png' ? 'PNG' : 'JPEG', 10, 10, 190, 0); // Ajustez les dimensions selon vos besoins
                    const pdfBase64 = pdf.output('datauristring');
                    resolve(pdfBase64.split("base64,")[1]); // Retourne uniquement la partie base64
                };
    
                img.onerror = (error) => {
                    reject(new Error('Erreur lors du chargement de l\'image : ' + error));
                };
            }else{
                resolve(await getBase64New(file));
            }
            
        } catch (error) {
            reject(error);
        }
    });
}

export function removeItem(resourceUid: any, i: number,index: number) {
    store.dispatch('orderModule/setPreConditionValid', false)
    let nbrOfMandatoryDocuments = 0;
    let nbrOfMandatoryDocumentsValidated = 0;
    const module = store.state.orderModule?.order?.reference ? 'orderModule' : 'taskModule';
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            process.checkpoints[i].documents[index].documentUpload = {}
            process.checkpoints[i].documents[index].status = "";
            process.checkpoints[i].documents[index].receivedFilesUid= "";
            process.checkpoints[i].documents[index].creationDate= "";
            process.checkpoints[i].documents[index].comment= "";
            
            if (process.checkpoints[i].status) {
                process.checkpoints[i].status.resourceUid = 'PENDING'
                process.checkpoints[i].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
                const documentValue = deepClone(process.checkpoints[i])
                delete documentValue.documents
                delete documentValue.whoModified
                documentValue.status = {
                    resourceUid: documentValue.status.resourceUid,
                    objectType: documentValue.status.objectType,
                    systemUid: documentValue.status.systemUid
                };
                documentValue.checkPointsConfig = {
                    resourceUid: documentValue.checkPointsConfig.resourceUid,
                    objectType: documentValue.checkPointsConfig.objectType,
                    systemUid: documentValue.checkPointsConfig.systemUid
                };
                documentValue.supportingDocument = {
                    resourceUid: documentValue.supportingDocument.resourceUid,
                    objectType: documentValue.supportingDocument.objectType,
                    systemUid: documentValue.supportingDocument.systemUid
                };
                store.dispatch("taskModule/updateDocumentValue", {
                    request: documentValue,
                });
            }
        }
        for (const items of process.checkpoints) {
            if( items.objectType.includes('documentvalue')){
                for(const doc of items.documents){
                    if (doc.mandatory) {
                        nbrOfMandatoryDocuments++
                        if (doc.status === 'QUALIFIED') {
                            nbrOfMandatoryDocumentsValidated++
                        }
                    }
                    if(doc.documentUpload && doc.documentUpload.originalFileName ){
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }else{
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }else{
                nbrOfMandatoryDocuments++
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    if(module==='taskModule') {
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }
                    nbrOfMandatoryDocumentsValidated++
                } else {
                    if(module==='taskModule') {
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }

        }
        // if (nbrOfMandatoryDocumentsValidated === nbrOfMandatoryDocuments) {
        //     store.dispatch('orderModule/setPreConditionValid', true)
        // }
        
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint
        process.status = "PROCESSING"
    }
    if (nbrOfMandatoryDocumentsValidated === nbrOfMandatoryDocuments) {
        store.dispatch('orderModule/setPreConditionValid', true)
    }
}

export function removeOfferItem(resourceUid: any, i: number,index: number) {
    //store.dispatch('orderModule/setPreConditionValid', false)
    for (const process of store.state.taskModule.gatheringProcess) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            process.gatheringProcessItem[0].items[i].documents[index].documentUpload = {}
            process.gatheringProcessItem[0].items[i].documents[index].status = "";
            if (process.gatheringProcessItem[0].items[i].status) {
                process.gatheringProcessItem[0].items[i].status.resourceUid = 'PENDING'
                process.gatheringProcessItem[0].items[i].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
                const documentValue = deepClone(process.gatheringProcessItem[0].items[i])
                delete documentValue.documents
                delete documentValue.whoModified

                store.dispatch("taskModule/updateDocumentValue", {
                    request: documentValue,
                });
            }
        }
        for (const items of process.gatheringProcessItem[0].items) {
            if( items.objectType.includes('documentvalue')){
                for(const doc of items.documents){
                    if(doc.documentUpload && doc.documentUpload.originalFileName ){
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }else{
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }else{
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint


    }
}

export async function validateField(resourceUid: any, index: number) {
    let nbrOfMandatoryDocuments = 0;
    let nbrOfMandatoryDocumentsValidated = 0;
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            if( process.checkpoints[index].fieldValue !== undefined &&
                process.checkpoints[index].fieldValue  !== "" &&
                process.checkpoints[index].fieldType.resourceUid === "DATE" &&
                !isValidDate(process.checkpoints[index].fieldValue)){
                Notify.create({
                    message: i18n.global.t("main.date.invalidDate"),
                    color: "negative",
                });
                process.checkpoints[index].fieldValue ='';
                break;
            }
            if (process.checkpoints[index].status) {
                process.checkpoints[index].status.resourceUid = 'COMPLETED'
                process.checkpoints[index].completionDate = moment().format("DD/MM/YYYY");
                process.checkpoints[index].businessData = {}
                const fieldValue = deepClone(process.checkpoints[index])
                delete fieldValue.whoModified
                fieldValue.completionDate = moment().format("YYYY-MM-DD");
                
                if(fieldValue.objectType.includes('fieldvalue') &&fieldValue.checkPointsConfig&&fieldValue.checkPointsConfig.resourceUid ){
                    const data = await getEntity("odm-supportingdocument","fieldvalueconfig",fieldValue.checkPointsConfig.resourceUid);
                    if(data&&data.fieldObjectType){
                       if(data.fieldObjectType.endsWith('ordercar')
                        &&store.state.taskModule.order
                        &&store.state.taskModule.order.orderItems &&store.state.taskModule.order.orderItems.length>0
                        &&store.state.taskModule.order.orderItems[0].orderAssets &&store.state.taskModule.order.orderItems[0].orderAssets.length>0
                        &&store.state.taskModule.order.orderItems[0].orderAssets[0].resourceUid){ 
                        fieldValue.targetEntityUid=store.state.taskModule.order.orderItems[0].orderAssets[0].resourceUid
                       }
                       if(data.fieldObjectType.endsWith('orderitem')
                        &&store.state.taskModule.order
                        &&store.state.taskModule.order.orderItems &&store.state.taskModule.order.orderItems.length>0
                        &&store.state.taskModule.order.orderItems[0].resourceUid ){ 
                        fieldValue.targetEntityUid=store.state.taskModule.order.orderItems[0].resourceUid
                       }
                       if(data.fieldObjectType.endsWith('orderdeleverydetails')
                        &&store.state.taskModule.order
                        &&store.state.taskModule.order.orderItems &&store.state.taskModule.order.orderItems.length>0
                        &&store.state.taskModule.order.orderItems[0].orderAssets &&store.state.taskModule.order.orderItems[0].orderAssets.length>0
                        &&store.state.taskModule.order.orderItems[0].orderAssets[0].assetDelivery
                        &&store.state.taskModule.order.orderItems[0].orderAssets[0].assetDelivery.resourceUid ){ 
                        fieldValue.targetEntityUid=store.state.taskModule.order.orderItems[0].orderAssets[0].assetDelivery.resourceUid 
                       }    

                    }
                }
                fieldValue.status = {
                    resourceUid: fieldValue.status.resourceUid,
                    objectType: fieldValue.status.objectType,
                    systemUid: fieldValue.status.systemUid
                };
                fieldValue.checkPointsConfig = {
                    resourceUid: fieldValue.checkPointsConfig.resourceUid,
                    objectType: fieldValue.checkPointsConfig.objectType,
                    systemUid: fieldValue.checkPointsConfig.systemUid
                };
                fieldValue.fieldType = {
                    resourceUid: fieldValue.fieldType.resourceUid,
                    objectType: fieldValue.fieldType.objectType,
                    systemUid: fieldValue.fieldType.systemUid
                };
                store.dispatch("taskModule/updateFieldValue", {
                    request: fieldValue,
                });

                if(fieldValue.fieldType&&fieldValue.fieldType.resourceUid && fieldValue.fieldType.resourceUid === "DATE" ){
                    fieldValue.fieldValue=formatDateLocale(fieldValue.fieldValue, 'DD/MM/YYYY', 'fr')
                    if(fieldValue.checkPointsConfig&&fieldValue.checkPointsConfig.resourceUid&&
                        fieldValue.checkPointsConfig.resourceUid==='CHKPT_Date_Liv_Phys'){
                            fieldValue.fieldValue=fieldValue.fieldValue+' 00:00'  
                        }
                }
                store.dispatch("taskModule/saveCheckPointsValue", {
                    request: fieldValue,
                });
            }
            process.checkpoints[index].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
        }
        for (const items of process.checkpoints) {
            if( items.objectType.includes('documentvalue')){
                for(const doc of items.documents){
                    if (doc.mandatory) {
                        nbrOfMandatoryDocuments++
                        if (doc.status === 'QUALIFIED') {
                            nbrOfMandatoryDocumentsValidated++
                        }
                    }
                    if(doc.documentUpload && doc.documentUpload.originalFileName ){
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }else{
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }else{
                nbrOfMandatoryDocuments++
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                    nbrOfMandatoryDocumentsValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint
        // if (nbrOfCheckPointValidated === nbrOfCheckPoint) {
        //     store.dispatch('orderModule/setPreConditionValid', true)
        // }   
    }
    if (nbrOfMandatoryDocumentsValidated === nbrOfMandatoryDocuments) {
        store.dispatch('orderModule/setPreConditionValid', true)
    } else {
        store.dispatch('orderModule/setPreConditionValid', false);
    }
}

 function isValidDate(fieldValue: any): boolean {
    if (!checkFormatDate(fieldValue)) {
      return false;
    }
    const parts = fieldValue.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; 
    const year = parseInt(parts[2], 10);
    const currentYear =  new Date().getFullYear();
    if (month < 0 || month > 11 || year < currentYear - 10 || year > currentYear + 10) {
      return false;
    }
    const date = new Date(year, month, day);
    return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
  }

export function removeFieldValue(resourceUid: any, index: number) {
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        let nbrOfMandatoryDocuments = 0;
        let nbrOfMandatoryDocumentsValidated = 0;    
        if (process.resourceUid === resourceUid) {
            if (process.checkpoints[index].status) {
                process.checkpoints[index].status.resourceUid = 'PENDING'
                process.checkpoints[index].completionDate = null;
                process.checkpoints[index].businessData = null
                const fieldValue = deepClone(process.checkpoints[index])
                delete fieldValue.whoModified
                fieldValue.status = {
                    resourceUid: fieldValue.status.resourceUid,
                    objectType: fieldValue.status.objectType,
                    systemUid: fieldValue.status.systemUid
                };
                fieldValue.checkPointsConfig = {
                    resourceUid: fieldValue.checkPointsConfig.resourceUid,
                    objectType: fieldValue.checkPointsConfig.objectType,
                    systemUid: fieldValue.checkPointsConfig.systemUid
                };
                fieldValue.fieldType = {
                    resourceUid: fieldValue.fieldType.resourceUid,
                    objectType: fieldValue.fieldType.objectType,
                    systemUid: fieldValue.fieldType.systemUid
                };
                store.dispatch("taskModule/updateFieldValue", {
                    request: fieldValue,
                });
            }
            process.checkpoints[index].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
        }
        for (const items of process.checkpoints) {
            if( items.objectType.includes('documentvalue')){
                for(const doc of items.documents){
                    if (doc.mandatory) {
                        nbrOfMandatoryDocuments++
                        if (doc.status === 'QUALIFIED') {
                            nbrOfMandatoryDocumentsValidated++
                        }
                    }
                    if(doc.documentUpload && doc.documentUpload.originalFileName ){
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                    }else{
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
                }

            }else{
                nbrOfMandatoryDocuments++
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                    nbrOfMandatoryDocumentsValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint
    }

    store.dispatch('orderModule/setPreConditionValid', false)
}

function formatDate(date: any) {
    const [day, month, year] = date.split('/').map(Number);
    return new Date(year, month - 1, day).toISOString().split('T')[0];
}

export const processDocumentsOrderTab = async (payload: {
    gatheringProcess: any,
    requiredDocuments: any,
    supportingDocuments: any[],
    receivedFiles: any[],
    module:any
}) => {

    const {
        gatheringProcess,
        requiredDocuments,
        supportingDocuments,
        receivedFiles,
        module
    } = payload;

    const deleteManualDocs: any[] = [];
    const nbrDeleteManualDocs = deleteManualDocs.length ? deleteManualDocs.length : 0
    const listOfCheckpoints: any[] = []

    for (const requiredDocument of requiredDocuments){
        if(gatheringProcess.supportingDocuments.length===1){
            requiredDocument.supportingDocumentUid =gatheringProcess.supportingDocuments[0].resourceUid
        }else{
            const supportingDocument = gatheringProcess.supportingDocuments.filter((s: any) => 
            s.documentFamily.resourceUid === requiredDocument.supportingType[0].supportingDocument.resourceUid)[0];
            if(!requiredDocument.supportingDocumentUid && supportingDocument){
             requiredDocument.supportingDocumentUid =supportingDocument.resourceUid
            }
        }
        let documentUpload = {};
        let creationDate = "";
        let status = "";
        let comment = "";
        let receivedFilesUid = "";
        const doc = gatheringProcess.gatheringProcessItem[0].receivedFiles.filter(
            (s: any) => s.content[0]?.documentType?.resourceUid === requiredDocument.resourceUid
        );
        if (doc.length > 0 ) {
            documentUpload = {
                format: "",
                originalFileName: doc[doc.length - 1].content[0].label,
                resourceUid: doc[doc.length - 1].fileReference.resourceUid,
                objectType: doc[doc.length - 1].fileReference.objectType,
                systemUid: doc[doc.length - 1].fileReference.systemUid,
            };
            creationDate = doc[doc.length - 1].receptionDate;
            status = doc[doc.length - 1].content[0].status.resourceUid;
            comment = doc[doc.length - 1].content[0].label;
            receivedFilesUid=doc[doc.length - 1].resourceUid
            


        }
        for (const items of gatheringProcess.gatheringProcessItem[0].items) {
            if(items.objectType && items.objectType.includes('documentvalue')){

            const supDocumentsFiltered = supportingDocuments.filter((s: any) => s.resourceUid === items.supportingDocument.resourceUid)[0];
                if (supDocumentsFiltered) {
                    if(requiredDocument.supportingDocumentUid===items.supportingDocument.resourceUid){

                        const receivedFileFilter = {
                            id: receivedFilesUid
                        }
                        const receivedFileInfo = await getReceivedFileInfo(receivedFileFilter)
                        let whoModified = ''
                        let whenModified = ''
                        if (receivedFileInfo.length > 0) {
                            whoModified =receivedFileInfo[0].first_name + ' ' + receivedFileInfo[0].last_name
                            whenModified =receivedFileInfo[0]._when_modified
                        }
                        const documentObject ={
                            documentUpload: documentUpload,
                            creationDate: creationDate,
                            status: items.status && items.status.resourceUid === 'COMPLETED' ?status:'PENDING',
                            comment: comment,
                            typeJustify: requiredDocument.supportingType[0].supportingDocument.resourceUid,
                            documentType: requiredDocument.resourceUid,
                            processResourceUid: gatheringProcess.resourceUid,
                            receivedFilesUid: receivedFilesUid,
                            whoModified: whoModified ? whoModified : '',
                            whenModified: whenModified ? whenModified : ''
                        }
                        items.document=documentObject
                        const filter = {
                            idCheck: items.resourceUid
                        }
                        const moreInfo = await getAdditionalInformation(filter)
                            if (moreInfo.length > 0) {
                                items.whoModified = moreInfo[0].first_name + ' ' + moreInfo[0].last_name
                                items.creationDate =moreInfo[0]._when_created
                            }
                            items.processId=gatheringProcess.resourceUid
                            items.processItemsId=gatheringProcess.gatheringProcessItem[0].resourceUid
                            items.receivedFilesUid=receivedFilesUid

                        const checkPoint = deepClone(items)
                        listOfCheckpoints.push(checkPoint)
                    }
                }
            }
        }  
    }
    for (const items of gatheringProcess.gatheringProcessItem[0].items) {
        if((items.objectType && items.objectType.includes('fieldvalue') )|| (items.objectType && items.objectType.includes('businessrulevalue'))){
            const filter = {
                idCheck: items.resourceUid
            }
            const moreInfo = await getAdditionalInformation(filter)
            if (moreInfo.length > 0) {
                items.whoModified = moreInfo[0].first_name + ' ' + moreInfo[0].last_name
                items.creationDate =moreInfo[0]._when_created
            }
            listOfCheckpoints.push(items)
        }

    } 

    

    const gatheringProcessOrder={
        resourceUid: gatheringProcess.resourceUid,
        status: gatheringProcess.status.resourceUid,
        assessmentContext: gatheringProcess.contextType.rawValue,
        checkpoints: listOfCheckpoints,

    }
        const listOfGatheringProcessOrder= store.state.orderModule.gatheringProcessOrder || [];
        listOfGatheringProcessOrder.push(gatheringProcessOrder)
        await store.dispatch("orderModule/gatheringProcessOrder", {
            request: {
                gatheringProcess: listOfGatheringProcessOrder,
            },
        }).catch(e => console.error(e));
    


}



export function validateBusinessField(resourceUid: any, index: number) {
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            if (process.checkpoints[index].status) {
                process.checkpoints[index].status.resourceUid = 'COMPLETED'
                process.checkpoints[index].completionDate = formatDate(moment(new Date()).format("DD/MM/YYYY"));
                const fieldValue = deepClone(process.checkpoints[index])
                delete fieldValue.whoModified
                fieldValue.status = {
                    resourceUid: fieldValue.status.resourceUid,
                    objectType: fieldValue.status.objectType,
                    systemUid: fieldValue.status.systemUid
                };
                fieldValue.checkPointsConfig = {
                    resourceUid: fieldValue.checkPointsConfig.resourceUid,
                    objectType: fieldValue.checkPointsConfig.objectType,
                    systemUid: fieldValue.checkPointsConfig.systemUid
                };
                store.dispatch("taskModule/updateCheckPointsStatus", {
                    request: fieldValue,
                });
            }
            process.checkpoints[index].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
        }

        for (const items of process.checkpoints) {
            if( items.objectType.includes('businessrulevalue')){
                if (items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }
            }else{
                if (items.status && items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }

            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint
        if (nbrOfCheckPointValidated === nbrOfCheckPoint) {
            store.dispatch('orderModule/setPreConditionValid', true)
        }
    }
}

export function updateBusinessField(resourceUid: any, index: number, status: string) {
    let nbrOfMandatoryDocuments = 0;
    let nbrOfMandatoryDocumentsValidated = 0; 
    for (const process of store.state.taskModule.gatheringProcessOrder) {
        let nbrOfCheckPoint = 0;
        let nbrOfCheckPointValidated = 0;
        let nbrOfCheckPointNotValidated = 0;
        if (process.resourceUid === resourceUid) {
            if (process.checkpoints[index].status) {
                process.checkpoints[index].status.resourceUid = status

                const today = new Date();
                const formattedDate = today.toISOString().slice(0, 10);

                process.checkpoints[index].completionDate = formattedDate;
                const fieldValue = deepClone(process.checkpoints[index])
                delete fieldValue.whoModified
                fieldValue.status = {
                    resourceUid: fieldValue.status.resourceUid,
                    objectType: fieldValue.status.objectType,
                    systemUid: fieldValue.status.systemUid
                };
                fieldValue.checkPointsConfig = {
                    resourceUid: fieldValue.checkPointsConfig.resourceUid,
                    objectType: fieldValue.checkPointsConfig.objectType,
                    systemUid: fieldValue.checkPointsConfig.systemUid
                };
                store.dispatch("taskModule/updateCheckPointsStatus", {
                    request: fieldValue,
                });
            }
            process.checkpoints[index].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
        }

        for (const items of process.checkpoints) {
            if( items.objectType.includes('businessrulevalue')){
                nbrOfMandatoryDocuments++
                if (items.status.resourceUid === 'COMPLETED') {
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                    nbrOfMandatoryDocumentsValidated++
                } else {
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }
            }else{
                if( items.objectType.includes('documentvalue')){
                    for(const doc of items.documents){
                        if (doc.mandatory) {
                            nbrOfMandatoryDocuments++
                            if (doc.status === 'QUALIFIED') {
                                nbrOfMandatoryDocumentsValidated++
                            }
                        }
                        if(doc.documentUpload && doc.documentUpload.originalFileName ){
                            nbrOfCheckPoint++
                            nbrOfCheckPointValidated++
                        }else{
                            nbrOfCheckPoint++
                            nbrOfCheckPointNotValidated++
                        }
                    }
    
                }else{
                    nbrOfMandatoryDocuments++
                    if (items.status && items.status.resourceUid === 'COMPLETED') {
                        nbrOfCheckPoint++
                        nbrOfCheckPointValidated++
                        nbrOfMandatoryDocumentsValidated++
                    } else {
                        nbrOfCheckPoint++
                        nbrOfCheckPointNotValidated++
                    }
    
                }
            }

        }
        process.nbrReceivedItems = nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint
        process.nbrValidatedItems = nbrOfCheckPointValidated + '/' + nbrOfCheckPoint
        // if (nbrOfCheckPointValidated === nbrOfCheckPoint) {
        //     store.dispatch('orderModule/setPreConditionValid', true)
        // }
    }
    if (nbrOfMandatoryDocumentsValidated === nbrOfMandatoryDocuments) {
        store.dispatch('orderModule/setPreConditionValid', true)
    } else {
        store.dispatch('orderModule/setPreConditionValid', false);
    }
}
 export async function processOrderMass(listOrder :any){
    const list :any =[]
    if(listOrder.length&&listOrder.length>0){
        for(const orderid of listOrder){
            if(orderid.variables&&orderid.variables.orderId &&orderid.variables.orderId.value){
                const order = await getGatheringProcessFromOrder(orderid.variables.orderId.value)
                if(order && order.length && order.length>0){
                    for(const orderGatheringProcess of order){
                        if(orderGatheringProcess.gatheringProcess &&orderGatheringProcess.gatheringProcess.resourceUid){
                           const requiredDocuments= await getRequiredDocumentValidatedByBR(orderGatheringProcess.gatheringProcess.resourceUid,orderid.variables.orderId.value)
                           const process =await getProcess(orderGatheringProcess.gatheringProcess.resourceUid)
                           const listOfOrderMass = await processDocumentsOrderMass({
                            gatheringProcess: process,
                            requiredDocuments: requiredDocuments,
                            supportingDocuments: process.supportingDocuments,
                            receivedFiles: process.gatheringProcessItem[0].receivedFiles,
                            orderReference:orderid.variables?.orderReference?.value
                            
                          })
                         
                          if(list.length > 0){
                            const taskDefinition = orderid.taskDefinition as keyof typeof taskCOMPLETEPRECONDITIONSMapping;
                            const allowedContexts = taskCOMPLETEPRECONDITIONSMapping[taskDefinition] || [];
                              const exists = list?.some((item:any) => item.assessmentContext === listOfOrderMass.assessmentContext && item.orderReference === listOfOrderMass.orderReference) || !allowedContexts.includes(listOfOrderMass.assessmentContext);
                              if (!exists) {
                                list.push(listOfOrderMass);
                              }
                          }else{
                            const taskDefinition = orderid.taskDefinition as keyof typeof taskCOMPLETEPRECONDITIONSMapping;
                            const allowedContexts = taskCOMPLETEPRECONDITIONSMapping[taskDefinition] || [];
                              const exists = allowedContexts.includes(listOfOrderMass.assessmentContext);
                              if (exists) {
                                list.push(listOfOrderMass);
                              }
                          }
                          
                        }

                    }

                }
                
            }
        }
    }

    return list

}
export const processDocumentsOrderMass = async (payload: {
    gatheringProcess: any,
    requiredDocuments: any,
    supportingDocuments: any[],
    receivedFiles: any[],
    orderReference:any
}) => {

    const {
        gatheringProcess,
        requiredDocuments,
        supportingDocuments,
        receivedFiles,
        orderReference
    } = payload;

    const deleteManualDocs: any[] = [];
    const nbrDeleteManualDocs = deleteManualDocs.length ? deleteManualDocs.length : 0
    const listOfCheckpoints: any[] = []
    let nbrOfCheckPoint = 0;
    let nbrOfCheckPointValidated = 0;
    let nbrOfCheckPointNotValidated = 0;

    for (const requiredDocument of requiredDocuments){
            if(gatheringProcess.supportingDocuments.length===1){
                requiredDocument.supportingDocumentUid =gatheringProcess.supportingDocuments[0].resourceUid
            }else{
                const supportingDocument = gatheringProcess.supportingDocuments.filter((s: any) => 
                s.documentFamily.resourceUid === requiredDocument.supportingType[0].supportingDocument.resourceUid)[0];
                if(!requiredDocument.supportingDocumentUid && supportingDocument){
                requiredDocument.supportingDocumentUid =supportingDocument.resourceUid
                }
            }
        }
        for (const items of gatheringProcess.gatheringProcessItem[0].items) {
            const ListOfDocuments = []
            if(items.objectType.includes('documentvalue')){
                const supDocumentsFiltered = supportingDocuments.filter((s: any) => s.resourceUid === items.supportingDocument.resourceUid)[0];
            if (supDocumentsFiltered) {
                for (const document of requiredDocuments) {
                    let documentUpload = {};
                    let creationDate = "";
                    let status = "";
                    let comment = "";
                    let receivedFilesUid = "";
                    const doc = gatheringProcess.gatheringProcessItem[0].receivedFiles.filter(
                        (s: any) => s.content[0]?.documentType?.resourceUid === document.resourceUid
                    );
                    if ( doc.length > 0 ) {
                        documentUpload = {
                            format: "",
                            originalFileName: doc[doc.length - 1].content[0].label,
                            resourceUid: doc[doc.length - 1].fileReference.resourceUid,
                            objectType: doc[doc.length - 1].fileReference.objectType,
                            systemUid: doc[doc.length - 1].fileReference.systemUid,
                        };
                        creationDate = doc[doc.length - 1].receptionDate;
                        status = doc[doc.length - 1].content[0].status.resourceUid;
                        comment = doc[doc.length - 1].content[0].label;
                        receivedFilesUid = doc[doc.length - 1].resourceUid;


                    }
  
                    if(document.supportingDocumentUid===items.supportingDocument.resourceUid){
                        ListOfDocuments.push({
                            documentUpload: documentUpload,
                            creationDate: creationDate,
                            status: status,
                            comment: comment,
                            typeJustify: document.supportingType[0].supportingDocument.resourceUid,
                            documentType: document.resourceUid,
                            processResourceUid: gatheringProcess.resourceUid,
                            receivedFilesUid: receivedFilesUid,
                        })
                    }
                    
                    
                    

                }
                items.documents = ListOfDocuments
                listOfCheckpoints.push(items)

            }
            for(const doc of items.documents){
                if(doc.documentUpload && doc.documentUpload.originalFileName ){
                    nbrOfCheckPoint++
                    nbrOfCheckPointValidated++
                }else{
                    nbrOfCheckPoint++
                    nbrOfCheckPointNotValidated++
                }
            }
        }  
    }

    const gatheringProcessOrder={
        resourceUid: gatheringProcess.resourceUid,
        nbrReceivedItems: nbrOfCheckPointNotValidated + '/' + nbrOfCheckPoint,
        nbrValidatedItems: nbrOfCheckPointValidated + '/' + nbrOfCheckPoint,
        status: gatheringProcess.status.resourceUid,
        assessmentContext: gatheringProcess.contextType.rawValue,
        orderReference:orderReference,
        checkpoints: listOfCheckpoints
    }
    
    return gatheringProcessOrder
    


}
export async function UploadDocumentMass(files: any, resourceUid: any, childId: any,index:any) {
    for (const process of store.state.taskModule.UploadDocumentsMass) {
        if (process.resourceUid === resourceUid) {
            const documentItem = process.checkpoints[childId]
            if (documentItem) {
                documentItem.documents[index].documentUpload = files[0];
                if (documentItem.documents && documentItem.documents[index].documentUpload) {
                    getBase64(documentItem.documents[index].documentUpload).then((base64) => {
                        const fileContent: FileContent = {
                            originalFileName: documentItem.documents[index].documentUpload.name,
                            format: 'application/pdf', // documentItem.documents[index].documentUpload.type,
                            content: base64,
                            relations: {
                                items: [
                                    {
                                        qualifier: {
                                            rawValue: documentItem.documents[index]?.documentType,
                                        },
                                        objectUON: {
                                            objectType: "odm.filemngt.filecontent",
                                            systemUid: UON.SystemUid.odmFileManagement,
                                        },
                                    },
                                ],
                            },
                        };
                        

                        store.dispatch("taskModule/uploadDocumentModule/uploadFile", {
                            request: fileContent,
                            documentType: documentItem.documents[index]?.documentType,
                            processid: process.resourceUid,
                            callback: (response: FileContent) =>
                                handleDocumentMassResponse(response, resourceUid, childId,index),
                        });
                    });


                } else {
                    Notify.create({
                        color: "negative",
                        message: "Erreur!",
                    });
                }
            }

        }
    }
}
export async function handleDocumentMassResponse(response: FileContent, resourceUid: any, childId: number,index: number) {
    for (const process of store.state.taskModule.UploadDocumentsMass) {
        if (process.resourceUid === resourceUid) {
            process.checkpoints[childId].documents[index].documentUpload = response || {};
            process.checkpoints[childId].documents[index].creationDate = moment(new Date()).format("DD/MM/YYYY");
            process.checkpoints[childId].documents[index].status = 'QUALIFIED';
            process.checkpoints[childId].documents[index].comment = response.originalFileName;
            process.checkpoints[childId].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
            process.checkpoints[childId].status.resourceUid = 'COMPLETED'
            const documentValue = deepClone(process.checkpoints[childId])
            delete documentValue.documents
            delete documentValue.whoModified
            delete documentValue.creationDate
            const IsValid=process.checkpoints[childId].documents.filter((s: any) => 
            s.status !== 'QUALIFIED');
            if(IsValid.length===0){
                store.dispatch("taskModule/updateDocumentValue", {
                    request: documentValue,
                });
            }
            
        }
    }

}

export function removeItemMass(resourceUid: any, i: number,index: number) {
    store.dispatch('orderModule/setPreConditionValid', false)
    for (const process of store.state.taskModule.UploadDocumentsMass) {
        if (process.resourceUid === resourceUid) {
            process.checkpoints[i].documents[index].documentUpload = {}
            process.checkpoints[i].documents[index].status = "";
            if (process.checkpoints[i].status) {
                process.checkpoints[i].status.resourceUid = 'PENDING'
                process.checkpoints[i].whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
                const documentValue = deepClone(process.checkpoints[i])
                delete documentValue.documents
                delete documentValue.whoModified
                delete documentValue.creationDate

                store.dispatch("taskModule/updateDocumentValue", {
                    request: documentValue,
                });
            }
        }
        process.status = "PROCESSING"
    }
}

export async function moveDocuments(payload :any){
    const {
        processId,
        processItemsId,
        receivedFilesUid,
        checkPointDoc,
        orders
    } = payload;
    
    if(processId && processItemsId &&receivedFilesUid && orders && orders.length && orders.length>0){
        for(const order of orders){

            if(order.process_id && order.process_item_id){
                const body={
                    newProcessId:order.process_id,
                    newDocumentId:order.process_item_id
                    }
                await moveDocument(processId,processItemsId,receivedFilesUid,body)

            }

        }
        // const listDelete=[]
        // for (const process of store.state.orderModule.gatheringProcessOrder){
        //     if(process.resourceUid===processId){
        //         const fileToDelete = process.receivedFiles.filter((s: any) => 
        //             s.resourceUid === receivedFilesUid)[0];
        //         for (const receivedFile of process.receivedFiles){
        //             if(receivedFile.content && receivedFile.content.length && receivedFile.content.length >0){
        //                 if(receivedFile.content[0].documentType?.resourceUid && fileToDelete.content[0].documentType?.resourceUid){
        //                     listDelete.push(receivedFile.resourceUid)
        //                 }
        //             }
        //         }
        //     }
        // }
        // listDelete.push(receivedFilesUid)
        // for(const received of listDelete){
        //     removeReceivedFile(processId,received)
        // }

        removeReceivedFile(processId,receivedFilesUid)
        
            if (checkPointDoc.status) {
                checkPointDoc.status.resourceUid = 'PENDING'
                checkPointDoc.whoModified = `${store.state.authModule?.user.firstName} ${store.state.authModule?.user.lastName}`
                const documentValue = deepClone(checkPointDoc)
                delete documentValue.document
                delete documentValue.documents
                delete documentValue.whoModified
                delete documentValue.creationDate
                delete documentValue.processId
                delete documentValue.processItemsId
                delete documentValue.receivedFilesUid
    

                store.dispatch("taskModule/updateDocumentValue", {
                        request: documentValue,
                    });
            }
        

        //remove for the original place

    }
    
}
export async function changeDocument(documentFrom: any, documentTo: any) {
    for (const process of store.state.orderModule.gatheringProcessOrder) {
        for(const checkpoint of process.checkpoints){
            if(checkpoint.receivedFilesUid === documentFrom.receivedFilesUid){
                ///upload the document in the new documentTo
                if( documentFrom.document&&
                    documentFrom.document.documentUpload&&
                    documentFrom.document.documentUpload.resourceUid){
                    const doc=await downloadFile(documentFrom.document.documentUpload.resourceUid)
                    const fileContent: FileContent = {
                        originalFileName: doc.originalFileName,
                        format: doc.format,
                        content: doc.content,
                        relations: {
                            items: [
                                {
                                    qualifier: {
                                        rawValue: documentTo.config?.documentType,
                                    },
                                    objectUON: {
                                        objectType: "odm.filemngt.filecontent",
                                        systemUid: UON.SystemUid.odmFileManagement,
                                    },
                                },
                            ],
                        },
                    };
                    store.dispatch("taskModule/uploadDocumentModule/uploadFile", {
                        request: fileContent,
                        documentType: documentTo.config?.documentType,
                        processid: documentTo.config.processResourceUid,
                        callback: (response: FileContent) =>
                            console.log(response)
                    });
                }
                //remove for the original place (documentFrom)
                await deleteReceivedFile(documentFrom.processId,documentFrom.receivedFilesUid)
                
            }
        }
    }
   
}