import { Picklist} from "@/types";

import {getOperator, globalConfig, headers} from "@/utils";
import {settings} from "@/settings";
import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;
import moment from "moment";
import { api, apiAnonymousToken } from "@/auth/api";
const api_query_url = settings.api_query_url;
const odmUser = `odm-user`
const baseUrl = `${settings.api_url}/${odmUser}/api/1/${odmUser}`


export function searchAuthority(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
      const url = settings.api_query_url;
  
      const qc_header: any = {
        qc: {
          queryId: "authority-filter-search",
        },
      };
      qc_header.qc.parameters = {
        ...filter,
      };
      const operators:any = [];
     
      
      if (filter && filter.authority) {
        operators.push(
            getOperator(
              "lower(user_authority",
              "id)",
              "LIKE",
              globalConfig.formatters
                .getEqualValue("%" + filter.authority + "%")
                .toLowerCase(),
              "id"
            )
          );
      }

      if (operators.length > 0) {
        qc_header.qc.otherFilters = {
          expressions: [
            {
              and: {
                operators,
              },
            },
          ],
        };
        qc_header.qc.otherOrders = null;
        qc_header.qc.offset = 0;
        qc_header.qc.limit = 100;
      }
  
      const _headers = headers({
        qc: JSON.stringify(qc_header.qc),
      });
  
      axios
        .get(url, {
          headers: _headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
            Notify.create({
              message: `Query returned an error \n ${err}`,
              color: "negative",
            });
          });
    });
}

export async function  createauthority(authority: any) {  
    return apiAnonymousToken().post(`${baseUrl}/authority/`, authority)
  }
  export async function  deleteAuthority(resourceUid:any) {  
    return api().delete(`${baseUrl}/authority/`+resourceUid+'/')
  }