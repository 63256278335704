import {settings} from "@/settings";
import {api} from "@/auth/api";
import {
    EmailMessage,
    NoticeContent,
    NoticeMessage,
    NoticeTemplate,
    NoticeTemplateSelectionContext, PackNoticeTemplateInfo,
} from "@/types";
import {useApi} from "@/requests/useApi";
import {headers, UON} from "@/utils";

const systemUid = `odm-contactmechanism`
const baseUrl = `${settings.api_url}/${systemUid}/api/1/${systemUid}`
const baseUrlGenerated = `${settings.api_url}/${systemUid}/api/1/event/${systemUid}`

export async function getAvailableNoticeTemplateList(request: NoticeTemplateSelectionContext): Promise<NoticeTemplate[]> {
    return (await api().post(`${baseUrl}/noticetemplate/`, request)).data
}

export async function getNoticeTemplatePackItems(request: NoticeTemplateSelectionContext, packNoticeTemplateUid: string): Promise<PackNoticeTemplateInfo[]> {
    return (await api().post(`${baseUrl}/noticetemplate/${packNoticeTemplateUid}/packItems/`, request)).data
}

export async function getDocumentGenerated(request: NoticeMessage): Promise<any> {
    return (await api().post(`${baseUrlGenerated}/notice/view/`, request)).data
}
export async function getDocumentDownload(request: NoticeContent): Promise<any> {
    return (await api().post(`${baseUrlGenerated}/notice/download/`, request)).data
}
export async function sendMail(request: EmailMessage): Promise<any> {
    return (await api().post(`${baseUrlGenerated}/email/`, request)).data
}
export async function sendMailICS(request: EmailMessage): Promise<any> {
    return (await api().post(`${baseUrlGenerated}/emailICS/`, request)).data
}

export async function getNoticeTemplateBody(templateResourceUid?: string): Promise<any> {
    const modelBaseUrl = `${settings.api_url}/${systemUid}/api/1/model/${systemUid}`
    return (await api().get(`${modelBaseUrl}/emailtemplate/${templateResourceUid || 'SendNoticeTemplate'}/`)).data
}

/**
 * get historic messages based on the offer reference
 * @param payload
 */
export const getHistoricMessagesApi = async (payload: { type: string, entityId: string }) => {
    return await api().get(settings.api_query_url, {
        headers: headers({
            qc: JSON.stringify({
                "queryId": "contactmechanism-offer-messages",
                "offset": 0,
                "limit": 300,
                "parameters": { "reference": payload?.entityId }
            })
        })
    });
}
