import { api } from "@/auth/api";
import { supersetConfiguration } from "@/utils/configuration/superset-config"
import axios, { AxiosResponse } from "axios";
import { setCookie } from "typescript-cookie";


/**
 * Retrieves the access token from the API.
 * @returns {Promise<string>} A promise that resolves to the access token.
 */
export const getAccesToken = async (config?: any) => {
    let result: any = null;
    const supersetConfig = config ? config : {};
    try {
        // Make a POST request to the login path with admin configuration
        //result = await axios.post(`${supersetConfiguration.base_url}${supersetConfiguration.loginPath}`, supersetConfiguration.adminConfig)
        result = await axios.post(`${supersetConfig?.base_url}${supersetConfiguration.loginPath}`, supersetConfig?.adminConfig)
    }
    catch (error) {

        return
    }
    return result.data.access_token
}


/**
 * Retrieves the CSRF token from the API.
 * @param {string} access_token - The access token required for authentication.
 * @returns {Promise<any>} A promise that resolves to the CSRF token response.
 */
export const get_csrf_token = async (access_token: any, config?: any) => {
    let result: any = null;
    const supersetConfig = config ? config : {};
    try {
        // Make a GET request to the CSRF path with authorization headers
        //result = await axios.get(`${supersetConfiguration.base_url}${supersetConfiguration.csrfPath}`, {
        result = await axios.get(`${supersetConfig?.base_url}${supersetConfiguration.csrfPath}`, {
        headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + access_token
            }, withCredentials: true
        })
    }
    catch (error) {

        return
    }

    // setCookie('session', result.data.access_token)
    return result.data.result
}


/**
 * Fetches the guest token from the API.
 * @returns {Promise<any>} A promise that resolves to the guest token response.
 */
export async function fetchGuestToken(config?: any): Promise<string> {
    try {
        const supersetConfig = config ? config : {};
        const access_token = await getAccesToken(supersetConfig);
        const csrf_token = await get_csrf_token(access_token,supersetConfig);
        // Make a POST request to the guest token path with guest configuration and authentication headers
        //const guestToken = await axios.post(`${supersetConfiguration.base_url}${supersetConfiguration.guest_token}`, supersetConfiguration.guestConfig, {
        const guestToken = await axios.post(`${supersetConfig?.base_url}${supersetConfiguration.guest_token}`, supersetConfig?.guestConfig, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + access_token,
                "X-CSRFToken": csrf_token
            }
        })
        return guestToken.data.token
    }
    catch (error) {

        throw new Error('Error when generating the token')
    }
}
