import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30364bd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-warning default-border-radius mb-2 p-2 text-white" }
const _hoisted_2 = { class: "bg-secondary default-border-radius p-2 tw-items-dashed-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelAndValue = _resolveComponent("LabelAndValue")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderItems, (orderItem, index) => {
    return (_openBlock(), _createElementBlock("div", { key: index }, [
      _createVNode(_component_q_expansion_item, {
        "switch-toggle-side": "",
        "default-opened": "",
        label: _ctx.orderLabel(orderItem)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_LabelAndValue, {
              "i18n-key": 'summaryPanel.asset.total',
              value: `${_ctx.formatWithMask(_ctx.getTotalAllAssets(orderItem.orderAssets), 2)}  €`,
              oneLine: true,
              "label-style": 'text-white',
              "value-style": _ctx.valueStyleTotal,
              "div-value-style": 'd-flex justify-between'
            }, null, 8, ["i18n-key", "value", "value-style"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderItem.orderAssets?.filter(item => item.assetTradeIn === null), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: ""
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_LabelAndValue, {
                  "i18n-key": `Asset ${(index + 1)}`,
                  value: `${_ctx.formatWithMask(_ctx.totalPriceWithTax(orderItem.orderAssets, index), 2)}  €`,
                  oneLine: true,
                  "label-style": 'text-primary',
                  "value-style": 'text-primary',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemBar",
                  "i18n-key": 'good.goodDetails.goodGeneralInfo.generalInformations',
                  value: '',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'order.item.generalInfo.from',
                  value: _ctx.getselectedAssetFrom(item),
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'summaryPanel.asset.quantite',
                  value: item.quantity,
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.vehicle.brand',
                  value: _ctx.getBrand(item.brand),
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.vehicle.model',
                  value: _ctx.getModel(item),
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'summaryPanel.asset.description',
                  value: item.description,
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'summaryPanel.asset.asset',
                  value: `${_ctx.formatWithMask(item.totalAmountWTax?.amount || 0, 2)}  €`,
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.matriculation',
                  value: item.matriculation ? item.matriculation : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.matriculationDate',
                  value: item.matriculationDate ? item.matriculationDate : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.constructionDate',
                  value: item.constructionDate ? item.constructionDate : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemBar",
                  "i18n-key": 'demand.asset.characteristics',
                  value: '',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.doorsNumber',
                  value: item.nbOfDoor ? item.nbOfDoor : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.numberOfSeats',
                  value: item.nbOfSeat ? item.nbOfSeat : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'good.goodDetails.goodFeatures.Motorization.NumberOfCylinders',
                  value: item.nbOfCylinder ? item.nbOfCylinder : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.gearType',
                  value: item.gearBox ? item.gearBox : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.horsePower',
                  value: item.horsePower ? item.horsePower : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.taxHorsePower',
                  value: item.taxHorsePower ? item.taxHorsePower : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.bodywork',
                  value: _ctx.getbodywork(item),
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.chassisReference',
                  value: item.chassisReference ? item.chassisReference : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.nbOfGear',
                  value: item.nbOfGear ? item.nbOfGear : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.currentMileage',
                  value: item.currentMileage ? item.currentMileage : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.length',
                  value: item.vehicleLength ? item.vehicleLength : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.color',
                  value: _ctx.getColor(item),
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemBar",
                  "i18n-key": 'demand.asset.deliveryDetails',
                  value: '',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.deliveryDate',
                  value: item.assetDelivery && item.assetDelivery.previsionDeliveryDate ? item.assetDelivery.previsionDeliveryDate : '-',
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.supplier',
                  value: _ctx.getSupplierName(item),
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemsBar",
                  "i18n-key": 'demand.asset.address',
                  value: _ctx.getSupplierAddress(item),
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                _createVNode(_component_LabelAndValue, {
                  class: "itemBar",
                  "i18n-key": 'summaryPanel.asset.totalFrais',
                  value: `${_ctx.formatWithMask(_ctx.proposalAssetItemsTotalWithTax(orderItem.orderAssets, index), 2)}  €`,
                  oneLine: true,
                  "label-style": 'text-dark',
                  "div-value-style": 'd-flex justify-between'
                }, null, 8, ["i18n-key", "value"]),
                (_ctx.proposalAssetOptionItems.length)
                  ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                      key: 0,
                      class: "itemsBar",
                      "i18n-key": 'summaryPanel.asset.options',
                      value: `${_ctx.formatWithMask(_ctx.proposalAssetOptionItemsTotalWithTax(orderItem.orderAssets, index), 2)}  €`,
                      oneLine: true,
                      "label-style": 'text-dark',
                      "div-value-style": 'd-flex justify-between'
                    }, null, 8, ["i18n-key", "value"]))
                  : _createCommentVNode("", true),
                (!_ctx.proposalAssetOptionItems.length)
                  ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                      key: 1,
                      class: "itemsBar",
                      "i18n-key": 'summaryPanel.asset.options',
                      value: `${_ctx.formatWithMask(0, 2)}  €`,
                      oneLine: true,
                      "label-style": 'text-dark',
                      "div-value-style": 'd-flex justify-between'
                    }, null, 8, ["i18n-key", "value"]))
                  : _createCommentVNode("", true),
                (_ctx.proposalAssetAccessoryItems.length)
                  ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                      key: 2,
                      class: "itemsBar",
                      "i18n-key": 'summaryPanel.asset.accessories',
                      value: `${_ctx.formatWithMask(_ctx.proposalAssetAccessoryItemsTotalWithTax(orderItem.orderAssets, index), 2)}  €`,
                      oneLine: true,
                      "label-style": 'text-dark',
                      "div-value-style": 'd-flex justify-between'
                    }, null, 8, ["i18n-key", "value"]))
                  : _createCommentVNode("", true),
                (!_ctx.proposalAssetAccessoryItems.length)
                  ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                      key: 3,
                      class: "itemsBar",
                      "i18n-key": 'summaryPanel.asset.accessories',
                      value: `${_ctx.formatWithMask(0, 2)}  €`,
                      oneLine: true,
                      "label-style": 'text-dark',
                      "div-value-style": 'd-flex justify-between'
                    }, null, 8, ["i18n-key", "value"]))
                  : _createCommentVNode("", true),
                (_ctx.proposalAssetOtherItems.length)
                  ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                      key: 4,
                      class: "itemsBar",
                      "i18n-key": 'summaryPanel.asset.fairsDivers',
                      value: `${_ctx.formatWithMask(_ctx.proposalAssetOtherItemsTotalWithTax(orderItem.orderAssets, index), 2)}  €`,
                      oneLine: true,
                      "label-style": 'text-dark',
                      "div-value-style": 'd-flex justify-between'
                    }, null, 8, ["i18n-key", "value"]))
                  : _createCommentVNode("", true),
                (!_ctx.proposalAssetOtherItems.length)
                  ? (_openBlock(), _createBlock(_component_LabelAndValue, {
                      key: 5,
                      class: "itemsBar",
                      "i18n-key": 'summaryPanel.asset.fairsDivers',
                      value: `${_ctx.formatWithMask(0, 2)}  €`,
                      oneLine: true,
                      "label-style": 'text-dark',
                      "div-value-style": 'd-flex justify-between'
                    }, null, 8, ["i18n-key", "value"]))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ]),
        _: 2
      }, 1032, ["label"])
    ]))
  }), 128))
}