import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_message_com = _resolveComponent("message-com")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_btn, {
      class: "btn btn-warning",
      icon: "sms",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleChatForm()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_tooltip, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("order.tooltip.messageCom")), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_message_com, { ref: "messageCom" }, null, 512)
  ], 64))
}