import { getAssociatedParty, searchAsset } from "@/store/services/offerService";
import { formatWithMask } from "@/utils/configuration/formatters-config";
import {
    AssetState,
    AssetTypeEnum,
    ProposalAssetCar,
    ProposalAssetItem,
    ProposalAssetItemType,
    ProposalAssetTruck

} from "@/types";
import { deepClone } from "@/utils";
import offerAssetConfig from "@/utils/configuration/offerAssetConfig.json"



export function calculateProposalAssetCarAmounts(state: AssetState) {
    const proposalAssets: any = state.proposalAssets

    const proposalAssetTotalWithTax = proposalAssets
        .reduce((a: number, b: ProposalAssetCar) => {
            let reduceChild = 0
            if (b.proposalAssetItems) {
                reduceChild = b.proposalAssetItems
                    .filter((item: ProposalAssetItem) => item.config.selected)
                    .reduce((a: number, b: ProposalAssetItem) => {
                        return a + (b.totalAmountWTax?.amount || 0)
                    }, 0)
            }
            return a + ((b.totalAmountWTax?.amount || 0) + reduceChild)
        }, 0)

    const proposalAssetItemsTotalWoTax = proposalAssets
        .reduce((a: number, b: ProposalAssetCar) => {
            let reduceChild = 0
            if (b.proposalAssetItems) {
                reduceChild = b.proposalAssetItems
                    .filter((item: ProposalAssetItem) => item.config.selected)
                    .reduce((a: number, b: ProposalAssetItem) => {
                        return a + (b.totalAmountWoTax?.amount || 0)
                    }, 0)
            }
            return a + ((b.totalAmountWoTax?.amount || 0) + reduceChild)
        }, 0)

    return {
        proposalAssetTotalWithTax, proposalAssetItemsTotalWoTax
    }
}

export function calculateProposalAsset(proposalAssetCar: ProposalAssetCar) {
    const product = deepClone(proposalAssetCar.config.proposalAssetCalculated.product)
    const quantity = proposalAssetCar.quantity || 1
    const tax = product.tax || 0
    const unitaryPrice = product.unitaryPrice || 0
    const discountPercentage = product.discount || 0
    const discountAmountWoTax = product.discountAmount || 0
    const appliedAmountWoTax = unitaryPrice - discountAmountWoTax
    const taxAmount = (appliedAmountWoTax * tax / 100.0)
    const appliedAmountWTax = appliedAmountWoTax + taxAmount

    proposalAssetCar.taxAmount = { amount: taxAmount, currency: 'EUR' }
    proposalAssetCar.taxValue = tax
    proposalAssetCar.amountWTax = { amount: appliedAmountWTax, currency: 'EUR' }
    proposalAssetCar.amountWoTax = { amount: unitaryPrice, currency: 'EUR' }

    proposalAssetCar.discountPercentage = discountPercentage
    proposalAssetCar.discountAmount = { amount: discountAmountWoTax, currency: 'EUR' }

    proposalAssetCar.totalAmountWoTax = { amount: (appliedAmountWoTax * quantity), currency: 'EUR' }
    proposalAssetCar.totalAmountWTax = { amount: (appliedAmountWTax * quantity), currency: 'EUR' }

    if (proposalAssetCar.orderAssetComponents) {
        proposalAssetCar.orderAssetComponents.forEach((item: ProposalAssetItem) => calculateProposalAssetItem(proposalAssetCar, item))
    }
}

export function calculateProposalAssetItem(proposalAssetCar: ProposalAssetCar, proposalAssetItem: any) {
    const quantity = proposalAssetCar.quantity || 1
   // const taxValue = proposalAssetItem.taxValue &&  proposalAssetItem.taxValue !== 0 ? proposalAssetItem.taxValue :  proposalAssetItem.totalTaxAmount && proposalAssetItem.totalTaxAmount?.amount !==0  ?  proposalAssetItem.totalTaxAmount?.amount : (proposalAssetItem.taxAmount?.amount && proposalAssetItem.amountWoTax?.amount  && proposalAssetItem.taxAmount?.amount!==0 ? (proposalAssetItem.taxAmount?.amount/proposalAssetItem.amountWoTax?.amount)*100 : 0)
    const discountPercentage = proposalAssetItem.discountPercentage || 0
    const amountWoTax = proposalAssetItem.amountWoTax?.amount || 0
    const discountAmountWoTax = Number(amountWoTax * discountPercentage / 100.0).toFixed(2)
    const appliedAmountWoTax = amountWoTax - Number(discountAmountWoTax)
    const taxValue = proposalAssetItem.taxValue &&  proposalAssetItem.taxValue !== 0 ? proposalAssetItem.taxValue :  proposalAssetItem.totalTaxAmount && proposalAssetItem.totalTaxAmount?.amount !==0  ?  proposalAssetItem.totalTaxAmount?.amount : (proposalAssetItem.taxAmount?.amount && proposalAssetItem.amountWoTax?.amount  && proposalAssetItem.taxAmount?.amount!==0 &&  proposalAssetItem.taxAmount?.amount!==appliedAmountWoTax ? (proposalAssetItem.taxAmount?.amount/appliedAmountWoTax)*100 : 0)
    const taxAmount = (appliedAmountWoTax * taxValue / 100.0)
    const appliedAmountWTax = proposalAssetItem.tvaValue
    ? (appliedAmountWoTax * (1 + proposalAssetItem.tvaValue)).toFixed(2)
    : appliedAmountWoTax + taxAmount;

    proposalAssetItem.taxAmount = { amount: taxAmount, currency: 'EUR' }
    proposalAssetItem.amountWTax = { amount: appliedAmountWTax, currency: 'EUR' }

    proposalAssetItem.discountPercentage = discountPercentage
    proposalAssetItem.discountAmount = { amount: Number(discountAmountWoTax), currency: 'EUR' }

    proposalAssetItem.totalAmountWoTax = { amount: (appliedAmountWoTax * quantity), currency: 'EUR' }
    proposalAssetItem.totalAmountWTax = { amount: (Number(appliedAmountWTax) * quantity), currency: 'EUR' }

}
export  function fetchOfferAssetConfig() {
            return offerAssetConfig
}

export function getAssetTypePrefix(asset: string){
// Extract text before "Product" using regex
const cleanedText: string = asset.replace(/(.*?)Product.*/, '$1');
return cleanedText
}

export function buildFormBind(value: any, proposalAssetCar: any, partyName: any){
    const formBind: any = deepClone (value);
    Object.keys(formBind).forEach((key) => {
        switch (key) {
            case 'supplier':
                formBind[key] = {
                    "party": proposalAssetCar.party,
                    "name": partyName
                };
                break;
            case 'version':
                formBind[key] = getFormBind(proposalAssetCar, 'version', (val: any) => val.includes('range.') ? val.split('range.')[1] : val.substring(val.lastIndexOf(".") + 1));
                break;
            default:
                formBind[key] = getFormBind(proposalAssetCar, key);
        }
    });
    return formBind;
}


export async function completeProposalAssetCarInfo(proposalAssetCar: ProposalAssetCar) {
    const config = fetchOfferAssetConfig();    
    const enumList = AssetTypeEnum;
    let assetType : any
    const resourceUid = proposalAssetCar?.assetProduct?.resourceUid || ''
    const objectType = proposalAssetCar?.objectType || ''
    switch (true) {
            case objectType.includes(enumList.VEHICULE ) && !objectType.includes(enumList.BOAT ):
                assetType = config.vehicle
            break;
            case objectType.includes(enumList.EQUIPMENT):
                assetType = config.equipment
            break;
            case objectType.includes(enumList.BOAT ):
                assetType = config.boat
            break;
        default:
            console.log("No match found");
            break;
    }

    let assetPackItems: any = null
    let productAssetModel = {
        id: '',
        label: '',
        config: '',
    }
   /* if (resourceUid) {
        const category = getAssetTypePrefix(resourceUid)
        //const category = resourceUid.toLowerCase().includes('pack') ? 'CARPACK' : 'CAR'; //TODO review this category
        let proposalAssetCalculated = await searchAsset(resourceUid, category)
        if (category === 'CARPACK') {
            assetPackItems = buildAssetPackItems(proposalAssetCalculated)
            proposalAssetCalculated = proposalAssetCalculated.filter((product: any) => product.objectType && product.objectType === 'odm.product.assetproductpackitem.carproductpackitem')[0]
            proposalAssetCalculated.resourceUid = proposalAssetCalculated.productUid
        }
        productAssetModel = {
            id: proposalAssetCalculated.resourceUid,
            label: proposalAssetCalculated.name,
            config: proposalAssetCalculated
        }
    }*/
    let partyName = ''
    if (proposalAssetCar.party && proposalAssetCar.party.resourceUid) {
        partyName = (await getAssociatedParty(proposalAssetCar.party.resourceUid)).third.organization.name
    }
    const discount= proposalAssetCar.discountPercentage || 0
    const amountWTax = proposalAssetCar?.amountWTax?.amount || 0;
    const amountWoTax = proposalAssetCar.amountWoTax?.amount ? proposalAssetCar.amountWoTax?.amount*(1-discount/100) : 0;
    const taxValue = proposalAssetCar.tvaValue ? proposalAssetCar.tvaValue * 100 : 0;
    proposalAssetCar.config = {
        readOnly: !!resourceUid,
        proposalAssetCalculated: {
            product: {
                name: proposalAssetCar.description,
                unitaryPrice: proposalAssetCar.amountWoTax?.amount || 0,
                discount: proposalAssetCar.discountPercentage || 0,
                tax: taxValue || 0,
                priceWithDiscount: proposalAssetCar.amountWTax?.amount || 0,
                priceWithTax: proposalAssetCar.amountWTax?.amount || 0,
                purchaseAmountWoTax: proposalAssetCar.purchaseAmountWoTax?.amount || 0,
                purchaseAmountWTax: proposalAssetCar.purchaseAmountWTax?.amount || 0,
                assetMargin: proposalAssetCar.assetMargin?.amount || 0,
                discountAmount: proposalAssetCar.discountAmount?.amount || 0
            }
        },
        productAssetModel,
       
        /*formBind: {
            supplier: {
                "party": proposalAssetCar.party,
                "name": partyName
            },
            brand: getFormBind(proposalAssetCar, 'brand'),
            version: getFormBind(proposalAssetCar, 'version',
                (value: any) => value.includes('range.') ? value.split('range.')[1] : value.substring(value.lastIndexOf(".") + 1)),
            range: getFormBind(proposalAssetCar, 'range'),
            carBodyStyle: getFormBind(proposalAssetCar, 'carBodyStyle'),
            engineType: getFormBind(proposalAssetCar, 'engineType'),
        }*/
        formBind: buildFormBind(assetType.classifications.formBind, proposalAssetCar, partyName)
        ,
        assetType: proposalAssetCar.objectType.includes(AssetTypeEnum.VEHICULE) && !proposalAssetCar.objectType.includes(AssetTypeEnum.BOAT) ? AssetTypeEnum.VEHICULE : proposalAssetCar.objectType.includes(AssetTypeEnum.EQUIPMENT) ? AssetTypeEnum.EQUIPMENT : AssetTypeEnum.BOAT
    }
    if (assetPackItems && proposalAssetCar.proposalAssetItems) {
        proposalAssetCar.proposalAssetItems.forEach(item => getPackItemIfExists(assetPackItems, item,
            (resultValue: any) => item.config = resultValue.config,
            () => {
                item.config = {
                    readOnly: true,
                    required: false,
                    selected: true,
                    selectModel: {
                        label: item.label,
                        id: item.label
                    },
                    unitaryAmountModel: item.amountWoTax?.amount || 0,
                    amountWithTaxModel: item.amountWTax?.amount || 0,
                    taxValueModel: {
                        label: `${formatWithMask(item.taxValue || 0, 2)}`,
                        id: item.taxValue || 0
                    },
                    purchaseAmountWTaxModel: item.purchaseAmountWTax?.amount || 0,
                    purchaseAmountWoTaxModel: item.purchaseAmountWoTax?.amount || 0,
                    assetMargin: proposalAssetCar.assetMargin?.amount || 0,
                    discountAmount: proposalAssetCar.discountAmount?.amount || 0,
                    reference: ''
                }
            }))
    } else if (proposalAssetCar.proposalAssetItems) {
        proposalAssetCar.proposalAssetItems.forEach(item => item.config = {
            readOnly: true,
            required: false,
            selected: true,
            selectModel: {
                label: item.label,
                id: item.label
            },
            unitaryAmountModel: item.amountWoTax?.amount || 0,
            amountWithTaxModel: item.amountWTax?.amount || 0,
            taxValueModel: {
                label: `${formatWithMask(  item.totalTaxAmount && item.totalTaxAmount?.amount !==0  ?  item.totalTaxAmount?.amount : (item.taxAmount?.amount && item.amountWoTax?.amount  && item.taxAmount?.amount!==0 ? (item.taxAmount?.amount/item.amountWoTax?.amount)*100 : 0)  , 2)}`,
                id:  item.totalTaxAmount && item.totalTaxAmount?.amount !==0  ?  item.totalTaxAmount?.amount : (item.taxAmount?.amount && item.amountWoTax?.amount  && item.taxAmount?.amount!==0 ? (item.taxAmount?.amount/item.amountWoTax?.amount)*100 : 0)
            },
            purchaseAmountWTaxModel: item.purchaseAmountWTax?.amount || 0,
            purchaseAmountWoTaxModel: item.purchaseAmountWoTax?.amount || 0,
            assetMargin: proposalAssetCar.assetMargin?.amount || 0,
            discountAmount: proposalAssetCar.discountAmount?.amount || 0,
            reference: ''
        })
    }
}
function getPackItemIfExists(assetPackItems: any, item: any, hasFunc: any, notHasFunc: any) {
    let collection = []
    switch (item.type) {
        case 'option':
            collection = assetPackItems.options
            break
        case 'accessory':
            collection = assetPackItems.accessories
            break
        case 'other':
            collection = assetPackItems.others
            break
    }
    const value = collection.filter((v: any) => item.label = v.label)[0]
    if (value) {
        hasFunc(value)
    } else {
        notHasFunc()
    }
}

function getFormBind(proposalAssetCar: any, attr: string, labelFunc?: any) {
    if (proposalAssetCar[attr]) {
        const value = proposalAssetCar[attr]
        return {
            id: value,
            label: labelFunc ? labelFunc(value) : value.substring(value.lastIndexOf(".") + 1),
            config: {}
        }
    } else {
        return {
            id: '',
            label: '',
            config: {}
        }
    }
}


export async function completeProposalAssetTruckInfo(proposalAssetTruck: ProposalAssetTruck) {
    return Promise.reject('not implemented')
}

export async function getAssetProduct(resourceUid: string, proposalAsset: any, categoryParam?: string, refreshAssetItems?: boolean): Promise<any> {
    const category = categoryParam?.toLowerCase().includes('pack') ? 'CARPACK' : 'CAR'; //TODO review this category
    const assetProduct = await searchAsset(resourceUid, category)
    if (category == 'CAR') {
        proposalAsset.config = buildAssetProductCarConfig(assetProduct, category, proposalAsset)
        buildAssetProductCar(resourceUid, assetProduct, category, proposalAsset)
    } else if (category == 'CARPACK') {
        const carProduct = assetProduct.filter((product: any) => product.objectType && product.objectType === 'odm.product.assetproductpackitem.carproductpackitem')[0]
        if (carProduct) {
            proposalAsset.config = buildAssetProductCarConfig(carProduct, category, proposalAsset, assetProduct)
            buildAssetProductCar(resourceUid, carProduct, category, proposalAsset)
        }
    }
    if (refreshAssetItems) {
        proposalAsset.proposalAssetItems = [
            ...proposalAsset.config.options,
            ...proposalAsset.config.accessories,
        ]
    } else {
        const otherItems = proposalAsset?.proposalAssetItems?.filter((item: any) => item.type === "OTHER_CAR")
        proposalAsset.proposalAssetItems = [
            ...mergeProposalAssetItemList(proposalAsset.proposalAssetItems, proposalAsset.config.options, "OPTION_CAR"),
            ...mergeProposalAssetItemList(proposalAsset.proposalAssetItems, proposalAsset.config.accessories, "ACCESSORY_CAR"),
            ...otherItems,
        ]
    }
    return proposalAsset;
}

function mergeProposalAssetItemList(proposalAssetItems: any, proposalAssetItemsNewList: any, type: string) {
    const typeItems = proposalAssetItems.filter((item: any) => type === item.type )
    const missingItems = proposalAssetItemsNewList.filter((item: any) => typeItems.findIndex((i: any) => item.label === i.label) === -1)
    return [...typeItems, ...missingItems]
}

function buildProposalItem(productItem: any, type: ProposalAssetItemType): ProposalAssetItem {
    const quantity = 1
    const unitaryAmount = productItem.feature.filter((feature: any) => feature.objectType && feature.objectType === 'odm.product.featurepricingparameter')[0]?.standardValue
    const tax = productItem.taxe?.fixedValue
    const taxCode = productItem.taxe?.code
    const amountWithTax = unitaryAmount + (unitaryAmount * tax) / 100
    const totalAmountWithTax = amountWithTax * quantity
    const totalAmountWOTax = unitaryAmount * quantity
    return {
        config: {
            required: productItem.required,
            selected: productItem.required,
            selectModel: {
                label: productItem.name,
                id: productItem.productUid
            },
            unitaryAmountModel: unitaryAmount,
            amountWithTaxModel: amountWithTax,
            taxValueModel: {
                label: `${formatWithMask(tax, 2)}`,
                id: tax
            },
            purchaseAmountWTaxModel: productItem.purchaseAmountWTax?.amount || 0,
            purchaseAmountWoTaxModel: productItem.purchaseAmountWoTax?.amount || 0,
            optionMargin: unitaryAmount - (productItem.purchaseAmountWoTax?.amount || 0) || 0,
            discountAmountModel: 0,
            reference: productItem.productUid
        },
        objectType: "odm.offer.proposalassetitem",
        type,
        quantity,
        label: productItem.name,
        discountPercentage: 0,
        taxCode: {
            resourceUid: taxCode,
            systemUid: "odm-offer",
            objectType: "odm.offer.offertax"
        },
        taxValue: tax,
        amountWTax: { amount: amountWithTax, currency: 'EUR' },
        discountAmount: { amount: 0, currency: 'EUR' },
        taxAmount: { amount: tax, currency: 'EUR' },
        amountWoTax: { amount: unitaryAmount, currency: 'EUR' },
        totalAmountWoTax: { amount: totalAmountWOTax, currency: 'EUR' },
        totalTaxAmount: { amount: tax, currency: 'EUR' },
        totalAmountWTax: { amount: totalAmountWithTax, currency: 'EUR' },
    }
}

function buildItemOption(item: any) {
    return {
        id: item.name,
        productUid: item.productUid,
        unitaryPrice: item.feature.filter((feature: any) => feature.objectType && feature.objectType === 'odm.product.featurepricingparameter')[0]?.standardValue,
        discount: 0,
        priceWithDiscount: 0,
        tax: item.taxe.fixedValue,
        priceWithTax: 0,
        required: item.required,
        selected: item.required,
        includeInPackagePrice: item.includeInPackagePrice,
    }
}

export function buildAssetProductCarConfig(assetProduct: any, category: string, proposalAsset: any, proposalAssetPackItems?: any) {
    const productTemp: any = {
        name: assetProduct.name,
        unitaryPrice: assetProduct.feature && assetProduct.feature.length>0 ? assetProduct.feature.filter((feature: any) => feature.objectType && feature.objectType === 'odm.product.featurepricingparameter')[0].standardValue:0,
        discount: proposalAsset.discountPercentage || 0,
        tax: assetProduct.taxe && assetProduct.taxe.fixedValue ?assetProduct.taxe.fixedValue:0,
        purchaseAmountWoTax: proposalAsset.config.proposalAssetCalculated.product.purchaseAmountWoTax || 0,
        purchaseAmountWTax: proposalAsset.config.proposalAssetCalculated.product.purchaseAmountWTax || 0,
        assetMargin: proposalAsset.config.proposalAssetCalculated.product.assetMargin || 0,
        discountAmount: proposalAsset.config.proposalAssetCalculated.product.discountAmount || 0,
    }
    calculatePriceWithDiscount(productTemp)
    calculatePriceWithTax(productTemp)
    const assetProductConfig = {
        proposalAssetCalculated: {
            product: productTemp
        },
        productAssetModel: {
            id: assetProduct.productUid,
            label: assetProduct.name,
            config: assetProduct
        },
        assetType: proposalAsset.config.assetType,
        formBind: {
            supplier: {
                "party": {},
                "name": ""
            },
            brand: buildAssetProductItem(assetProduct, 'asset.good.vehicle.brand', (value: any) => value.categoryClass.resourceUid.substring(value.categoryClass.resourceUid.lastIndexOf(".") + 1)),
            version: buildAssetProductItem(assetProduct, 'asset.good.vehicle.brand.range.version', (value: any) => value.categoryClass.resourceUid.substring(value.categoryClass.resourceUid.indexOf('range.') + 6, value.categoryClass.resourceUid.length)),
            range: buildAssetProductItem(assetProduct, 'asset.good.vehicle.brand.range', (value: any) => value.categoryClass.resourceUid.substring(value.categoryClass.resourceUid.lastIndexOf(".") + 1)),
            carBodyStyle: buildAssetProductItemFromCategory(assetProduct, 'asset.good.vehicle.car.bodyStyle'),
            engineType: buildAssetProductItem(assetProduct, 'asset.good.vehicle.energytype', (value: any) => value.categoryClass.resourceUid.substring(value.categoryClass.resourceUid.lastIndexOf(".") + 1)),
        },
        options: [],
        accessories: [],
        others: []
    }
    if (category === 'CARPACK' && proposalAssetPackItems) {
        const assetPackItems = buildAssetPackItems(proposalAssetPackItems)
        if (assetPackItems.options) assetProductConfig.options = assetPackItems.options
        if (assetPackItems.accessories) assetProductConfig.accessories = assetPackItems.accessories
        if (assetPackItems.others) assetProductConfig.others = assetPackItems.others
    }
    return assetProductConfig

}

function buildAssetPackItems(assetProductPack: any) {
    const result: any = {
        options: [],
        accessories: [],
        others: []
    }
    result.options = assetProductPack
        .filter((product: any) => product.objectType && product.objectType === 'odm.product.assetproductpackitem.optionassetproductpackitem')
        .map((item: any) => buildProposalItem(item, ProposalAssetItemType.option))

    result.accessories = assetProductPack
        .filter((product: any) => product.objectType && product.objectType === 'odm.product.assetproductpackitem.accessoryassetproductpackitem')
        .map((item: any) => buildProposalItem(item, ProposalAssetItemType.accessory))

    result.others = assetProductPack
        .filter((product: any) => product.objectType && product.objectType === 'odm.product.assetproductpackitem.otherassetproductpackitem')
        .map((item: any) => buildProposalItem(item, ProposalAssetItemType.other))

    return result
}

function buildFormBindValues(proposalAsset: ProposalAssetCar) {
    const formBind = proposalAsset.config.formBind;
    proposalAsset.brand =   formBind?.brand?.id || proposalAsset.brand
    proposalAsset.version =  formBind?.version?.id || proposalAsset.version 
    proposalAsset.range = formBind?.range?.id || proposalAsset.range 
    proposalAsset.engineType = formBind?.engineType?.id || proposalAsset.engineType 
    proposalAsset.carBodyStyle =   formBind?.carBodyStyle?.id || proposalAsset.carBodyStyle 
}

function buildAssetProductCar(assetProductResourceUid: string, assetProduct: any, category: string, proposalAsset: ProposalAssetCar) {
    proposalAsset.assetProduct = {
        objectType: assetProduct.objectType,
        resourceUid: assetProduct.productUid || assetProductResourceUid,
        systemUid: "odm-product"
    }
    proposalAsset.description = assetProduct.name
    proposalAsset.fuelConsumptionUrban = assetProduct.fuelConsumptionUrban
    proposalAsset.co2Emissions = assetProduct.co2Emissions
    proposalAsset.horsePower = assetProduct.horsePower
    proposalAsset.quantity = proposalAsset.quantity || 1
    proposalAsset.packAssetProduct = {
        objectType: assetProduct.objectType,
        resourceUid: assetProductResourceUid,
        systemUid: "odm-product"
    }
    buildFormBindValues(proposalAsset)
    const productTemp: any = {
        name: assetProduct.name,
        unitaryPrice: assetProduct.feature.filter((feature: any) => feature.objectType && feature.objectType === 'odm.product.featurepricingparameter')[0]?.standardValue,
        discount: 0,
        tax: assetProduct.taxe && assetProduct.taxe.fixedValue ?assetProduct.taxe.fixedValue:0,
        purchaseAmountWoTax: 0,
        purchaseAmountWTax: 0,
        assetMargin: 0,
        discountAmount: 0
    }
    calculatePriceWithDiscount(productTemp)
    calculatePriceWithTax(productTemp)
    proposalAsset.totalAmountWTax = {
        amount: productTemp.priceWithTax * proposalAsset.quantity,
        currency: 'EUR'
    }
    proposalAsset.totalAmountWoTax = {
        amount: productTemp.unitaryPrice * proposalAsset.quantity,
        currency: 'EUR'
    }
    proposalAsset.amountWTax = {
        amount: productTemp.priceWithTax,
        currency: 'EUR'
    }
    proposalAsset.amountWoTax = {
        amount: productTemp.unitaryPrice,
        currency: 'EUR'
    }
    proposalAsset.purchaseAmountWoTax = {
        amount: productTemp.purchaseAmountWoTax,
        currency: 'EUR'
    }
    proposalAsset.purchaseAmountWTax = {
        amount: productTemp.purchaseAmountWTax,
        currency: 'EUR'
    }
    return assetProduct;
}

function buildAssetProductCarCode(assetProduct: any, category: string) {
    return {
        id: assetProduct.resourceUid,
        label: assetProduct.name,
        config: {
            resourceUid: assetProduct.resourceUid,
            objectType: "odm.product.productsummary",
            systemUid: "odm-product",
            businessData: null,
            daaq: null,
            productUid: {
                objectType: "odm.product.productfeature.product.assetproduct.goodproduct.vehicleproduct.carproduct",
                systemUid: "odm-product",
                resourceUid: assetProduct.resourceUid
            },
            productName: assetProduct.name,
            category: assetProduct.category.id,
            categoryClass: category
        }
    }

}

export function buildAssetProductItem(assetProduct: any, key: any, typeFunction: any) {
    const value = assetProduct.classifications.filter((classif: any) => classif.category.resourceUid && classif.category.resourceUid === key)
    if (value.length > 0 && value[0].categoryClass.resourceUid) {
        const finishingText = typeFunction(value[0]);
        return {
            id: value[0].categoryClass.resourceUid,
            label: finishingText[0] + finishingText.substring(1).toLowerCase()
        }
    }
    return null
}

function buildAssetProductItemFromCategory(assetProduct: any, key: string) {
    if(assetProduct.classifications && assetProduct.classifications.length >0){
        const listOfclassifications= assetProduct.classifications.filter((classif: any) => classif.category.resourceUid && classif.category.resourceUid === key)
        if(listOfclassifications.length>0){
            return {
                id:listOfclassifications[0].categoryClass.resourceUid ,
                label:listOfclassifications[0].categoryClass.resourceUid.substring(assetProduct.classifications.filter((classif: any) => classif.category.resourceUid && classif.category.resourceUid === key)[0].categoryClass.resourceUid.lastIndexOf(".") + 1)
            }
        }else{
            return {id:'',label:''}
        }
        
    }else{
        return {id:'',label:''}
    }

}

export function calculatePriceWithDiscount(item: any) {
    if (item.unitaryPrice > 0) {
        item.priceWithDiscount = item.unitaryPrice - (item.discount / 100.0) * item.unitaryPrice;
    }
}

export function calculatePriceWithTax(item: any) {
    if (item.tax >= 0) {
        if (item.priceWithDiscount > 0) {
            item.priceWithTax = item.priceWithDiscount * (1 + (item.tax / 100.0));
        } else {
            item.priceWithTax = item.unitaryPrice * (1 + (item.tax / 100.0));
        }
    }
}

