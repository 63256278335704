import {api, apiAnonymousToken} from "@/auth/api";
import i18n from "@/i18n";
import {settings} from "@/settings";
import { Paths, UON } from "@/utils";
import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;

/**
 * useApi to execute request operations
 * @description
 * When the default path is set to false, a not default path needs to be set as the below example.
 * There isn't the necessity to pass /api/1/ to the path
 * @example
 * standard path: odm-contactmechanism
 * special path: event/odm-contactmechanism
 * @param system_uid
 * @param path
 * @param version
 * @param standardPath
 */
export const useApi = (system_uid?: string, path?: string, standardPath = true, version = 1) => {
    let URI = `/${system_uid}/api/${version}/${system_uid}`;
    if(system_uid===UON.SystemUid.odmOrchestration){
        URI = `/${system_uid}/business-api/${version}`
    }
    const URI_NOT_DEFAULT = `/${system_uid}/api/${version}`;
    const URIOrch  =`/business-api/${version}`;
    let response: any = null
    let error: any = null

    const get = async (resourceUid?: string) => {
        try {
            response = resourceUid ?
                (await api().get(`${standardPath ? URI : URI_NOT_DEFAULT}/${path}/${resourceUid}/`)).data :
                (await api().get(`${standardPath ? URI : URI_NOT_DEFAULT}/${path}/`)).data;

            console.info(`Entity recovered from resource ${path} and resourceUid: ${response.resourceUid ? response.resourceUid : 'Empty!'}`);
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }

   /* const getOrchestration = async (resourceUid?: string) => {
        try {
            response = resourceUid ?
                (await apiOrch().get(`${standardPath ? URIOrch : URI_NOT_DEFAULT}/${path}/${resourceUid}/`)).data :
                (await apiOrch().get(`${standardPath ? URIOrch : URI_NOT_DEFAULT}/${path}/`)).data;

            console.info(`Entity recovered from resource ${path} and resourceUid: ${response.resourceUid ? response.resourceUid : 'Empty!'}`);
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }*/
   /* const upsertOrch = async (payload: any, daaqResourceUid?: string) => {
        try {
            if (payload.resourceUid) {
                response = (await apiOrch().put(`${standardPath ? URIOrch : URI_NOT_DEFAULT}/${path}/${payload.resourceUid}/`, payload)).data
                console.info(`PUT done with resource ${path} and resourceUid: ${payload.resourceUid}`)
            }
            else {
                response = (await apiOrch(daaqResourceUid).post(`${standardPath ? URIOrch : URI_NOT_DEFAULT}/${path}/`, payload)).data
                console.info(`POST done with resource ${path}`)
            }
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }*/

    const getWithEndPath = async (endPath: string, resourceUid?: string) => {
        try {
            response = resourceUid ?
                (await api().get(`${URI}/${path}/${resourceUid}/${endPath}/`)).data :
                (await api().get(`${URI}/${path}/`)).data;

            console.info(`Entity recovered from resource ${path} and resourceUid: ${response.resourceUid ? response.resourceUid : 'Empty!'}`);
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }

    const upsert = async (payload: any, daaqResourceUid?: string) => {
        try {
            if (payload.resourceUid) {
                response = (await api().put(`${standardPath ? URI : URI_NOT_DEFAULT}/${path}/${payload.resourceUid}/`, payload)).data
                console.info(`PUT done with resource ${path} and resourceUid: ${payload.resourceUid}`)
            }
            else {
                response = (await api(daaqResourceUid).post(`${standardPath ? URI : URI_NOT_DEFAULT}/${path}/`, payload)).data
                console.info(`POST done with resource ${path}`)
            }
        }
        catch (e) {
            error = e
            if (path === Paths.order.create){
                let msg = i18n.global.t("order.error");
                if (error?.response?.data?.includes('duplicate key value violates unique constraint')) {
                  msg = i18n.global.t("order.errorExternalReference");
                }
            
                Notify.create({
                  message: msg,
                  color: 'negative',
                });
            }
            else {
                console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
            }
        }
        return { response, error }
    }

    const anonymous = async (payload: any) => {
        try {
            const axiosResponse = await axios.post(settings.auth_anonymous_api_url);
            response = (await api('', axiosResponse.data.token).post(`${URI}/${path}/`, payload)).data
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }

    const remove = async (resourceUid: string, daaqResourceUid?: string) => {
        try {
            response = (await api(daaqResourceUid).delete(`${URI}/${path}/${resourceUid}/`)).data;

            console.info(`Entity deleted from resource ${path} with resourceUid: ${response.resourceUid}`);
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }

    return { get, getWithEndPath, upsert, remove, anonymous }
}
export const useApiAnonymous = (system_uid?: string, path?: string, standardPath = true, version = 1) => {
    let URI = `/${system_uid}/api/${version}/${system_uid}`;
    if(system_uid===UON.SystemUid.odmOrchestration){
        URI = `/${system_uid}/business-api/${version}`
    }
    const URI_NOT_DEFAULT = `/${system_uid}/api/${version}`;
    const URIOrch  =`/business-api/${version}`;
    let response: any = null
    let error: any = null

    const get = async (resourceUid?: string) => {
        try {
            response = resourceUid ?
                (await apiAnonymousToken().get(`${standardPath ? URI : URI_NOT_DEFAULT}/${path}/${resourceUid}/`)).data :
                (await apiAnonymousToken().get(`${standardPath ? URI : URI_NOT_DEFAULT}/${path}/`)).data;

            console.info(`Entity recovered from resource ${path} and resourceUid: ${response.resourceUid ? response.resourceUid : 'Empty!'}`);
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }
    const getWithEndPath = async (endPath: string, resourceUid?: string) => {
        try {
            response = resourceUid ?
                (await apiAnonymousToken().get(`${URI}/${path}/${resourceUid}/${endPath}/`)).data :
                (await apiAnonymousToken().get(`${URI}/${path}/`)).data;

            console.info(`Entity recovered from resource ${path} and resourceUid: ${response.resourceUid ? response.resourceUid : 'Empty!'}`);
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }

    const upsert = async (payload: any, daaqResourceUid?: string) => {
        try {
            if (payload.resourceUid) {
                response = (await apiAnonymousToken().put(`${standardPath ? URI : URI_NOT_DEFAULT}/${path}/${payload.resourceUid}/`, payload)).data
                console.info(`PUT done with resource ${path} and resourceUid: ${payload.resourceUid}`)
            }
            else {
                response = (await apiAnonymousToken(daaqResourceUid).post(`${standardPath ? URI : URI_NOT_DEFAULT}/${path}/`, payload)).data
                console.info(`POST done with resource ${path}`)
            }
        }
        catch (e) {
            error = e
            if (path === Paths.order.create){
                let msg = i18n.global.t("order.error");
                if (error?.response?.data?.includes('duplicate key value violates unique constraint')) {
                  msg = i18n.global.t("order.errorExternalReference");
                }
            
                Notify.create({
                  message: msg,
                  color: 'negative',
                });
            }
            else {
                console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
            }
        }
        return { response, error }
    }

    const anonymous = async (payload: any) => {
        try {
            const axiosResponse = await axios.post(settings.auth_anonymous_api_url);
            response = (await api('', axiosResponse.data.token).post(`${URI}/${path}/`, payload)).data
        }
        catch (e) {
            error = e
            console.error(`ERROR => ${error?.response?.data?.message?.returnMessage}`)
        }
        return { response, error }
    }


    return { get, getWithEndPath, upsert, anonymous }
}
