import { Module } from "vuex";
import { RootState } from "@/types";
import { downloadFile, saveFile } from "@/store/services/offerService";
import {
    getOfferDocument,
    getProcess,
    getProcessOrch,
    getRequiredDocument, getRequiredDocumentModifyCondition,
    getRequiredDocumentValidatedByBR,
    qualifyFile,
    receiveFile,
    sendToControl
} from "../../../services/task/actions/uploadDocumentService";
import i18n from "@/i18n";
import { deleteProcessFile } from "@/store/services/task/actions/validateDocumentService";
import store from "@/store";
import { PDFDocument } from "pdf-lib";
import { concatFile, replaceFile } from "@/store/services/configuration/documentsService";

const errorTreatment = (options: Options) => {
    const {
        error,
        action = ''
    } = options;
    console.error(`${action} action => ${error.response?.data?.title ?? ''} \n ${error}`)
}

interface Options {
    error: any,
    action: string
}

const uploadDocumentModule: Module<any, RootState> = {
    namespaced: true,
    state: {},
    getters: {

    },
    actions: {

        uploadTempFiles: ({ commit }, paylod) => {
            commit('setFiles', paylod)
        },
        addTempFiles: ({ commit }, paylod) => {
            commit('addFiles', paylod)
        },
        uploadFile: async ({ commit }, payload) => {
            const { request, document, processid, documentType, callback , documentReceivedFileID } = payload
            await saveFile(request).then(response => {
                if (callback) {
                    getProcess(processid).then(async (res: any) => {
                        for (const element of res.gatheringProcessItem){
                            const file = {
                                fileReference: {
                                    objectType: response.objectType,
                                    systemUid: response.systemUid,
                                    resourceUid: response.resourceUid
                                },
                                creationLabel: response.originalFileName,
                                qualification: [
                                    {
                                        documentType: {
                                            resourceUid: documentType
                                        },
                                        label: response.originalFileName
                                    }
                                ],
                                targetEntityId: document?.targetEntity?.resourceUid,
                                systemUid: 'odm-supportingdocument'
                            }

                            await receiveFile(processid, element.resourceUid, file).then(async res => {
                                response.receivedFilesUid = res.resourceUid
                                if(documentReceivedFileID){
                                 deleteProcessFile(processid,documentReceivedFileID )
                                }
                            await qualifyFile(processid, element.resourceUid, res.resourceUid, file).then(qualificationRes => {
                                    if (!store.getters["taskModule/isTaskUploadDocuments"]){
                                    if (document && qualificationRes.status.resourceUid === "QUALIFIED") {
                                        document.status = i18n.global.t(
                                            `task.uploadDocument.headers.supportingDocumentStatus.QUALIFIED`
                                        );
                                    }
                                    }
                                })
                            })
                            callback(response)
                        
                        }
                    });
                }
            })

        },
        downloadFile: ({ commit }, payload) => {
            const { request, row, callback } = payload
            downloadFile(request.resourceUid).then(response => {
                if (callback) {
                    callback(response, row)
                }
            })
                .catch(e => errorTreatment({ error: e, action: 'downloadFile' }))
        },
        replaceFile: ({ commit }, payload) => {
            const { request, callback } = payload
            concatFile(request).then((response: any) => {
                if (callback) {
                    callback(response, request)
                }
            })
                .catch((e: any) => errorTreatment({ error: e, action: 'downloadFile' }))
        },

        mergePDFs: async ({ commit }, payload) => {
            const mergedPdf = await PDFDocument.create();
            for (let i = 0; i < payload.selectedDocuments.length; i++) {
                const document = payload.selectedDocuments[i];
                const resourceUid = document.documentUpload.resourceUid;
                try {
                    // Recover binary data from PDF file
                    const fileArrayBuffer = await downloadFile(resourceUid);
                    if (fileArrayBuffer && fileArrayBuffer.format &&fileArrayBuffer.format.startsWith('image/')) {
                        // Si c'est une image, convertir en PDF
                            const pdfFromImage = await PDFDocument.create();
                            //const page = pdfFromImage.addPage([600, 800]); // Dimensions arbitraires
                            const image = fileArrayBuffer.format === 'image/jpeg' 
                            ? await pdfFromImage.embedJpg(fileArrayBuffer.content)
                            : await pdfFromImage.embedPng(fileArrayBuffer.content);
                            // Obtenir les dimensions de l'image
                            const { width, height } = image.scale(1);

                            // Créer une page PDF avec les dimensions de l'image
                            const page = pdfFromImage.addPage([width, height]);
                            page.drawImage(image, {
                                x: 0,
                                y: 0,
                                width: width,
                                height: height,
                            });
                            const pdfBytes = await pdfFromImage.save();
                            const pdfToMerge = await PDFDocument.load(pdfBytes);

                            const copiedPages = await mergedPdf.copyPages(
                                pdfToMerge,
                                pdfToMerge.getPageIndices()
                            );
                            copiedPages.forEach(page => mergedPdf.addPage(page));
                    }
                    else if (fileArrayBuffer && fileArrayBuffer.format &&fileArrayBuffer.format=== 'application/pdf') {
                    const pdfToMerge = await PDFDocument.load(fileArrayBuffer.content);
                    // Copy pages from the PDF file and add them to the merged document
                    const copiedPages = await mergedPdf.copyPages(
                        pdfToMerge,
                        pdfToMerge.getPageIndices()
                    );
                    copiedPages.forEach(page => mergedPdf.addPage(page));
                }
                } catch (error) {
                    console.error('Error retrieving file:', error);
                }
            }
            // Saving the merged PDF file
            const mergedPdfBytes = await mergedPdf.save();
            // Creating a blob for visualisation
            const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
            if (payload.mode === 'download'){
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'merged.pdf';
                // Adds the link to the page and triggers the download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else if (payload.mode === 'view'){
                const blobUrl = URL.createObjectURL(blob);
                // Open the PDF
                window.open(blobUrl, '_blank');
            }         
        },

        getOfferDocument: ({ commit }, payload) => {
            const { request, callback } = payload
            getOfferDocument(request.variable).then(response => {
                if (callback) {
                    callback(response)
                }
            })
        },
        getProcess: ({ commit }, payload) => {
            const { request, callback } = payload
            getProcessOrch(request.resourceUid,request.orderId,request.requiredDocuments).then(response => {
                if (callback) {
                    callback(response, request.res)
                }
            })
        },
        /*getProcessOrch: ({ commit }, payload) => {
            const { request, callback } = payload
            getProcessOrch(request.resourceUid,request.orderId,request.requiredDocuments).then(response => {
                if (callback) {
                    callback(response, request.res)
                }
            })
        },*/
        getRequiredDocument: ({ commit }, payload) => {
            const { request, callback } = payload
            getRequiredDocument(request.resourceUid).then(response => {
                if (callback) {
                    callback(response)
                }
            })
        },
        getRequiredDocumentValidatedByBR: ({ commit }, payload) => {
            const { request, callback } = payload
            getRequiredDocumentValidatedByBR(request.resourceUid,request.orderId).then(response => {
                if (callback) {
                    callback(response)
                }
            })
        },
        getRequiredDocumentModifyCondition: ({ commit }, payload) => {
            const { request, callback } = payload
            getRequiredDocumentModifyCondition(request.resourceUid).then(response => {
                if (callback) {
                    callback(response)
                }
            })
        },
        gatheringProcess: ({ commit }, payload) => {
            const { request, callback } = payload
            commit('setGatheringProcess', request.gatheringProcess)
        },
        sendToControl: ({ commit }, payload) => {
            const { resourceUid, callback } = payload
            sendToControl(resourceUid).then(response => {
                if (callback) {
                    callback(response)
                }
            })

        },
    },
    mutations: {
        setFiles(state, payload) {
            state.files = payload
        },
        addFiles(state, payload) {
            if(state.files && state.files.length && state.files.length>0){
                state.files.push(payload) 
            }else{
                state.files=[payload]
            }
            
        }
    }
}

export default uploadDocumentModule
