import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "calculation-spinner flex flex-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "text-deep-black-4 font-weight-normal mt-3"
}
const _hoisted_4 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = { class: "font-semi-bold font-size-12" }
const _hoisted_7 = { class: "default-border-radius p-2 bg-white" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "pl-3 flex-fill" }
const _hoisted_10 = {
  key: 1,
  class: "row d-flex align-items-center"
}
const _hoisted_11 = { class: "pl-3 flex-fill" }
const _hoisted_12 = {
  key: 2,
  class: "row d-flex align-items-center"
}
const _hoisted_13 = { class: "pl-3 flex-fill" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_q_spinner_facebook, {
          size: "3em",
          color: "primary"
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.getGatheringProcess && _ctx.getGatheringProcess.length ===0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("order.orderActions.NoActionsFound")), 1)
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getGatheringProcess, (gatheringProcess, index) => {
          return (_openBlock(), _createBlock(_component_q_expansion_item, {
            "switch-toggle-side": "",
            "header-class": " card-header bg-transparent no-border p-4 tw-order-summaryPanel-header-class default-border-radius",
            class: "tw-items-dashed-group tw-items-group-with-bg tw-level-two-group q-expansion-item--expanded",
            onShow: _cache[0] || (_cache[0] = () => {}),
            onHide: _cache[1] || (_cache[1] = () => {}),
            key: index
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.capitalize(
                  _ctx.$t("order.preconditions.label." + gatheringProcess.assessmentContext).toLowerCase()
              )), 1)
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(gatheringProcess.checkpoints, (checkpoint, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "mb-2"
                  }, [
                    (checkpoint.objectType.includes('documentvalue'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(checkpoint.documents, (document, i) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: i,
                              class: "row d-flex align-items-center"
                            }, [
                              _createElementVNode("div", null, [
                                _createElementVNode("span", {
                                  class: _normalizeClass(_ctx.setStatusBadgeClass(document.status||'PENDING'))
                                }, _toDisplayString(_ctx.$t('task.uploadDocument.headers.supportingDocumentStatus.'.concat(document.status||'PENDING'))), 3)
                              ]),
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('task.supportingDocumentLabel.'.concat(document.documentType))), 1)
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (checkpoint.objectType.includes('fieldvalue'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", {
                              class: _normalizeClass(_ctx.setStatusBadgeClass(checkpoint.status.resourceUid))
                            }, _toDisplayString(_ctx.$t('order.preconditions.fieldvalue.status.'.concat(checkpoint.status.resourceUid))), 3)
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("order.preconditions.fieldvalue.label." + checkpoint.i18nLabel)), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (checkpoint.objectType.includes('businessrulevalue'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", {
                              class: _normalizeClass(_ctx.setStatusBadgeClass(checkpoint.status.resourceUid))
                            }, _toDisplayString(_ctx.$t('order.preconditions.fieldvalue.status.'.concat(checkpoint.status.resourceUid))), 3)
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("order.preconditions.fieldvalue.label." + checkpoint.i18nLabel)), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]))
}