import {Module} from "vuex";
import {User} from "@/types";
import {
    getGroupsGeneric,
    getHistoricMessages,
    getSubjectsGeneric,
    getUsersGeneric,
    sendMessageService
} from "@/store/services/communication/messaging";
import store from "@/store";
import { groupList } from "@/store/services/user/userService";

const messagingModule: Module<any, any> = {
    namespaced: true,
    state: {
        message: {},
        subjects: [],
        groups: [],
        users: [],
        destinations: [],
        recipients: [],
        historicMessages: [],
        isSendingMessage: false
    },
    mutations: {
        /**
         * The setSubjects mutation function updates the subjects property in the state with the data contained in the payload argument.
         * @param state
         * @param payload
         */
        setSubjects: function (state, payload) {
            state.subjects = payload
        },
        /**
         * The setUsers mutation function updates the users property in the state with the data contained in the payload argument.
         * @param state
         * @param payload
         */
        setUsers: function (state, payload) {
            state.users = payload
        },

        /**
         * set groups
         * @param state
         * @param payload
         */
        setGroups: function (state, payload) {
            state.groups = payload
        },
        
        /**
         * set recipients
         * @param state
         * @param payload
         */
        setRecipients: function (state, payload) {
            state.recipients = payload
        },

        /**
         * set destinations
         * @param state
         * @param payload
         */
        setDestinations: function (state, payload) {
            state.destinations = payload
        },

        /**
         * set the current message to the state
         * @param state
         * @param payload
         */
        setMessage: function (state, payload) {
            state.message = payload
        },

        /**
         * set the sending status to the state
         * @param state
         * @param isSending
         */
        setIsSendingMessage(state, isSending) {
            state.isSendingMessage = isSending
        },
        /**
         * set the historic messages
         * @param state
         * @param payload
         */
        setHistoricMessages(state, payload) {
            state.historicMessages = payload;
        }
    },
    actions: {
        /**
         * we call this asynchronous action to fetch subjects using getSubjectsGeneric
         * @param commit
         */
        async fetchSubjects({commit}) {
            try {
                const response = await getSubjectsGeneric();
                commit("setSubjects", response.items);
            } catch (error) {
                console.error(error);
            }
        },
        /**
         * we call this asynchronous action to fetch users using getUsersGeneric
         * @param commit
         */
        async fetchUsers({commit}) {
            //const {callback} = payload
            try {
                const response = await getUsersGeneric()
                commit("setUsers", response.items);
            } catch (error) {

                return null;
            }

        },
        async fetchGroups({commit}) {
            try {
                //const response = await getGroupsGeneric();
                const filterGroup =undefined
                const response = await groupList(filterGroup);
                if (response && response.length > 0) {
                    const updatedResponse = response.map((item: any) => ({
                        objectType: "odm.user.securitygroup",
                        systemUid: "odm-user",
                        resourceUid: item.groupid
                    }));
        
                    commit('setGroups', updatedResponse);
                } else {
                    commit('setGroups', []);
                }
                //commit('setGroups', response)
            } catch (error) {

                return null;
            }
        },

        /**
         * commit optionsRecipients into state.recipients
         * @param commit
         * @param payload
         */
        async fetchRecipients({commit}) {
            store.dispatch("messagingModule/fetchUsers")
            store.dispatch("messagingModule/fetchGroups")

            // Merge the options of the list of Users and Groups
            const optionsGroupsList = store.getters["messagingModule/getGroups"]
            /* store.getters["messagingModule/getGroups"].map((group: any) => {
             group.resourceUid = i18n.global.t(`middleOffice.decision.profil.${group.resourceUid}`);
             return group;
         });*/
            // Filter the optionsUsersList to only include users whose email is equal to anonymous users
            const filteredUsersList = store.getters["messagingModule/getUsers"].filter((user: User) => {
                if ( user.firstName !== 'anonymous' || user.lastName !== 'anonymous' ) {
                    return user
                }
            });
            // Merge the filtered optionsUsersList with the optionsGroupsList and return the result
            const payload = [...optionsGroupsList, ...filteredUsersList]
            commit('setRecipients', payload)
        },
        /**
         * sending Message
         * @param commit
         * @param payload
         */
        async messageSend({commit}, payload) {
            try {
                commit('setIsSendingMessage', true)
                //commit("setMessage", payload)
                const res = await sendMessageService(payload)
                commit('setIsSendingMessage', false)
            } catch (error) {
                commit('setIsSendingMessage', false)
                console.error(error);
            }
        },
        /**
         * getting the historic messages
         * @param commit
         * @param payload
         */
        async historicMessages({commit}, payload) {
            try {
                commit("setHistoricMessages", []); // Empty the list before http call to get messages.
                commit("setHistoricMessages", await getHistoricMessages(payload));     
            } catch (e) {
                console.error(e);
            }
        }
    },
    getters: {
        /**
         * this getter retrieves sending status  from state
         * @param state
         */
        getSendingStatus(state) {
            return state.isSendingMessage
        },

        /**
         * this getter retrieves subjects list from state
         * @param state
         */
        getSubjects(state) {
            return state.subjects;
        },
        /**
         * this getter retrieves users list from state
         * @param state
         */
        getUsers(state) {
            return state.users;
        },
        /**
         * this getter retrieves groups list from state
         * @param state
         */
        getGroups(state) {
            return state.groups;
        },

        /**
         * this getter retrieves destinations list from state
         * @param state
         */
        getDestinations: function (state) {
            return state.destinations;
        },
        /**
         * this getter retrieves recipients list from state
         * @param state
         */
        getRecipients(state) {
            return state.recipients;
        },
        /**
         * retrieves the historic messages
         * @param state
         */
        getHistoricMessages(state) {
            return state.historicMessages;
        }
    },
};

export default messagingModule;
