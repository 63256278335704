import {settings} from "@/settings";
import {AuthUser} from "@/types";
import {createUser, getOperator, globalConfig, headers, login, Paths, requestForgotPasswordValidationCode, resetPassword, UON, validateCode} from "@/utils";
import {api, apiAnonymousToken} from "@/auth/api";
import {useApi} from "@/requests/useApi";
import axios from "axios";

const api_query_url = settings.api_query_url;

export function signIn(user: AuthUser): Promise<string> {
    const odmAuthentication = UON.SystemUid.odmAuthentication;
    if ( !user.applicationName ) {
        user.applicationName = 'TWDDistribution'
    }
    return login(user, {
        systemUid: odmAuthentication,
        path: `${odmAuthentication}/token/?applicationName=${user.applicationName}`,
    });
}

export function anonymousToken(){
  return  axios.post(settings.auth_anonymous_api_url);
}

export function signUp(user: AuthUser): Promise<AuthUser> {
    const odm_user = UON.SystemUid.odmUser;
    if ( !user.applicationName ) {
        user.applicationName = 'TWDDistribution'
    }
    return createUser(user, {
        systemUid: odm_user,
        path: `${odm_user}/${Paths.user}/`,
    })
}

export function codeConfirmation(user: AuthUser): Promise<{ result: string }> {
    const odmAuthentication = UON.SystemUid.odmAuthentication;
    if ( !user.applicationName ) {
        user.applicationName = 'TWDDistribution'
    }
    return validateCode({"smsCode": user.smsCode}, {
        systemUid: odmAuthentication,
        path: `anonymous/${odmAuthentication}/validate/smsCode/${user.username}/?applicationName=${user.applicationName}`,
    })
}

export function forgotPassword(user: AuthUser): Promise<void> {
    const odmAuthentication = UON.SystemUid.odmAuthentication;
    if ( !user.applicationName ) {
        user.applicationName = 'TWDDistribution'
    }
    return requestForgotPasswordValidationCode({"login": user.username}, {
        systemUid: odmAuthentication,
        path: `${odmAuthentication}/forgotPassword/?applicationName=${user.applicationName}`,
    })
}

export function forgotPasswordCodeConfirmation(user: AuthUser): Promise<void> {
    const odmAuthentication = UON.SystemUid.odmAuthentication;
    if ( !user.applicationName ) {
        user.applicationName = 'TWDDistribution'
    }
    return resetPassword({"login": user.username, "smsCode": user.smsCode, "password": user.password}, {
        systemUid: odmAuthentication,
        path: `${odmAuthentication}/forgotPasswordValidate/?applicationName=${user.applicationName}`,
    })
}

export function getAuthorities() {
    return api().get(`${settings.api_url}/odm-user/api/1/odm-user/whoami/`);
}

export function getUser(userId: string) {
    return api().get(`${settings.api_url}/odm-user/api/1/odm-user/leaseforgeuser/${userId}/`);
}

export function updateLanguage(payload: any, userId: string) {
    return apiAnonymousToken().put(`${settings.api_url}/odm-user/api/1/odm-user/leaseforgeuser/${userId}/`, payload)
}

export async function updatePassWordService(payload: any) {
    const odmAuthentication = UON.SystemUid.odmAuthentication;
    const res= await  api().put(`${settings.api_url}/${odmAuthentication}/api/1/${odmAuthentication}/change-password/`, payload)
    return res
}
/**
 * get historic messages based on the offer reference
 * @param payload
 */
export const getUserApi = async (payload: string) => {
    const {
        response,
        error
    } = await useApi(UON.SystemUid.odmUser, `leaseforgeuser`).get(payload);
    if ( error ) {
        throw error;
    }

    return response;
}
export function getSupersetConfig(filter: any): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      const qc_header: any = {
        qc: {
          queryId: "configuration-superset",
          offset: 0,
          limit: 100
        },
      };
  
      const operators = []
      if (filter && filter.module){
        operators.push(
            getOperator(
                "lower(configuration_config_param",
                 "module)", 
                 "EQUAL",
                  globalConfig.formatters.getEqualValue(filter.module).toLowerCase(),
                 "module"))
      }  

      if (operators.length > 0) {
        qc_header.qc.otherFilters = {
          expressions: [
            {
              and: {
                operators
              }
            }
          ]
        }
        qc_header.qc.otherOrders = null
        qc_header.qc.offset = 0
        qc_header.qc.limit = 100
      }
  
      const _headers = headers({
        qc: JSON.stringify(qc_header.qc)
      })
      axios
        .get(api_query_url, {
          headers: _headers,
        })
        .then((res) => {
          const settings : any = {
            supersetBasePath: '',
            base_url: '',
            adminConfig: {
              password: '',
              username: '',
              provider: '',
              refresh: '',
            },
            guestConfig: {
              user: {
                username: '',
                first_name: '',
                last_name: '',
              },
              resources: [
                {
                  type: '',
                  id: '',
                },
              ],
              rls: [],
              }

          };
          for (const resItem of res.data){
            switch (resItem?.field) {
              
              case "BASEPATH":
                settings.supersetBasePath = resItem.fiedld_value
                settings.base_url = `${ resItem.fiedld_value}/api/v1/`
                break;
        
              case "ADMINCONFIG_USERNAME":
                settings.adminConfig.username = resItem.fiedld_value
                break;
        
              case "ADMINCONFIG_PASSWORD":
                settings.adminConfig.password = resItem.fiedld_value
                break;
              
              case "ADMINCONFIG_PROVIDER":
                settings.adminConfig.provider = resItem.fiedld_value
                break;
              
              case "ADMINCONFIG_REFRESH":
                settings.adminConfig.refresh = resItem.fiedld_value
                break;
                  
              case "GUESTCONFIG_USER_USERNAME":
                settings.guestConfig.user.username = resItem.fiedld_value
                break;
                
              case "GUESTCONFIG_USER_FIRSTNAME":
                settings.guestConfig.user.first_name = resItem.fiedld_value
                break;
              
              case "GUESTCONFIG_USER_LASTNAME":
                settings.guestConfig.user.last_name = resItem.fiedld_value
                break;
                  
              case "GUESTCONFIG_RESOURCES_TYPE":
                settings.guestConfig.resources[0].type = resItem.fiedld_value
                break;
                  
              case "GUESTCONFIG_RESOURCES_ID":
                settings.guestConfig.resources[0].id = resItem.fiedld_value
                break;
                  
              default:
                break;
            }
          }
          resolve(settings);
        })
        .catch((err) => reject(err));
    });
  }


