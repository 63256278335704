
  import store from "@/store";
  import {Options, Vue} from "vue-class-component";
  import { useRoute } from "vue-router";
  import { globalConfig } from "@/utils";
  import {
  checkUserByRole,
} from "@/utils";
import { MenuRoles, } from "@/types";
import router from "@/router";
import moment from "moment";
  
  
  
  @Options({
  })
  export default class GoToDelivery extends Vue {
    checkUserByRole = checkUserByRole
    MenuRoles = MenuRoles
  
  
    get toggleShow(){
    return store.state.orderModule.showAlert
  }
  get order() {
      if( this.currentRoute === 'TaskDetail'){
          return store.state.taskModule.order
      }
      else{
          return store.state.orderModule.order;
      }
    }
    get recoverState() {
       const orderState = localStorage.getItem(globalConfig.storageKeys.orderTask)
       const orderResourceUid = orderState ? JSON.parse(orderState).resourceUid : ""
      return orderResourceUid;
    }
  
    get currentRoute() {
      return useRoute().name;
    }
    
  created(){
    }

    get externalReference(){
    if (this.order && this.order.orderItems && this.order.orderItems[0]){
      return this.order.orderItems[0].externalReference
    }
    return false
  }

    get connectedUser() {
    const user = store.getters["authModule/getUserConnected"];
    return {
      isAudit: user.authorities?.find(
        (el: { authority: string }) => el.authority === "ROLE_AUDITORS"
      ),
    };
  }

  viewDelivery() {
      store.dispatch("orderModule/setOrderToDelivery", {
      order: store.state.orderModule.order
    })
      const filter = {
              startDate : this.order && this.order.orderItems && this.order.orderItems[0].initialDeliveryDate 
              ? moment(this.order.orderItems[0].initialDeliveryDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD')
              : ""
            }
      store.dispatch("orderModule/setDeliveryFilter",{
          filter
      });
      router.push("/deliverySchedule");
  }


  }
  