
import store from "@/store";
import { isFilterDAAQMatching } from "@/store/services/filter/filterService";
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["query"],
})
export default class FilterList extends Vue {
  get isLoading() {
    return store.getters["filterModule/getIsLoading"];
  }

  get listOfFilters() {
    return store.getters["filterModule/listOfFilters"];
  }

  get currentUserDAAQ() {
    return store.getters["authModule/getDefaultDAAQConfig"];
  }

   get connectedUser() {
    return store.getters["authModule/getUserConnected"];
  }

  /**
   * Searches for order based on the filter selected.
   * @return {void}
   */
  onSelectedFilter(filter: any) {
    store.dispatch("filterModule/setSelectedFilter", filter);
  }

  onDeleteFilter(filter: any) {
    store.dispatch("showConfirmationDeleteDialog", {
      message: `Are you sure you want to delete the filter ${filter.description}?`,
      deleteAction: 'QueryFilter',
      payload: filter,
      confirmation: true
    })
  }

  onEditFilter(filter: any) {
    store.dispatch("filterModule/editSelectedFilter", filter);
  }

  checkFilterActionPermission(isActionAllowed: boolean, filterDaaq: string): boolean {
    const { readDAAQ, defaultCreateDAAQ } = this.currentUserDAAQ;
    return isActionAllowed && isFilterDAAQMatching(filterDaaq, { readDAAQ, defaultCreateDAAQ });
  }
  setColor(color: string) {
        return "background: "+color;
  }
}
