import store from "@/store";
import { RuleSetState, QueryBuilderState, ScaleBuisinessRuleSection } from "@/types";
import { spliceObjectByKey } from "@/utils";

import { Module } from "vuex";
import { getCriteriasOrResponses } from "../services/configuration/ScaleService";
import Vue from 'vue';
const queryBuilderModule: Module<QueryBuilderState, any> = {
    namespaced: true,
    state: {
        selectedModule: "",
        criterias: [],
        responses: [],
        isSaving: false,
        queryBuilder: {},
        isEditingBri: false
    },
    getters: {
        // Add a getter to check if a section is initialized
        isSectionInitialized: (state) => (section: ScaleBuisinessRuleSection) => {
            return !!state.queryBuilder[section];
        }
    },
    actions: {
        // New action to dynamically initialize a specific business rule section
        initializeSingleBusinessRule({ commit }, section: ScaleBuisinessRuleSection) {
            commit('initializeSingleBusinessRule', section);
        },
        deleteSingleBusinessRule({ commit }, section: ScaleBuisinessRuleSection) {
            commit('deleteSingleBusinessRule', section);
        },
        setEditingBri({ commit }, payload) {
            commit('setEditingBri', payload)
        },
        setBriIndex({ commit }, payload) {
            commit('setBriIndex', payload)
        },
        async clearBuisinessRule({ commit }) {
            commit('clearBuisinessRule')
        },
        async setbrResluts({ commit }, payload) {
            commit('setbrResluts', payload)
        },
        async clonebrResluts({ commit }, payload) {
            commit('clonebrResluts', payload)
        },
        async setInputsHeader({ commit }, payload) {
            commit('setInputsHeader', payload)
        },
        async setOutputHeader({ commit }, payload) {
            commit('setOutputHeader', payload)
        },
        async setsavingBri({ commit }, payload) {
            commit('setsavingBri', payload)
        },
        async initBuisinessRuleItem({ commit }) {
            commit('initBuisinessRuleItem')
        },
        async setSelectedBuisinessRuleItem({ commit }, payload) {
            commit('setSelectedBuisinessRuleItem', payload)
        },
        async initBuisinessRule({ commit }, payload) {
            commit('initBuisinessRule', payload)
        },
        async RuleSet({ commit }, payload) {
            commit('setRuleSet', payload)
        },

        async setSelectedBuisinessRule({ commit }, payload) {
            commit('setSelectedBuisinessRule', payload)
        },
        async setBuisinessRuleItem({ commit }, payload) {
            commit('setBuisinessRuleItem', payload)
        },
        async updateBuisinessRuleItem({ commit }, payload) {
            commit('updateBuisinessRuleItem', payload)
        },
        async deleteSelectedBuisinessRuleItem({ commit }, payload) {
            commit('deleteSelectedBuisinessRuleItem', payload)
        },
        async deleteSelectedBuisinessRuleItemResults({ commit }, payload) {
            commit('deleteSelectedBuisinessRuleItemResults', payload)
        },
        async reOrderSelectedBuisinessRuleItem({ commit }, payload) {
            commit('reOrderSelectedBuisinessRuleItem', payload)
        },

        searchCriterias({ commit }) {

            getCriteriasOrResponses("criteria")
                .then(response => {
                    commit("setCriterias", response);
                })
                .catch(err => console.error(err));
        },
        searchResponses({ commit }) {
            getCriteriasOrResponses("response")
                .then(response => {
                    commit("setResponses", response);
                })
                .catch(err => console.error(err));
        },

    },
    mutations: {
        // New mutation to initialize a single business rule section
        initializeSingleBusinessRule(state, section: ScaleBuisinessRuleSection) {
            // Check if the section is already initialized
            if (!state.queryBuilder[section]) {
                state.queryBuilder[section] = {
                    ruleSet: {
                        operatorIdentifier: "",
                        children: []
                    },
                    selectedBuisinessRule: {
                        "label": `Business Rule - ${section}`,
                        "externalId": `businessRuleId-${section}`,
                        "failIfNoResult": false,
                        "childInputHeader": [],
                        "childOutputHeader": [],
                        "childBusinessRuleItem": [],
                        "returnMultipleResults": false
                    },
                    selectedBuisinessRuleItem: null,
                    briResults: [],
                    criterias: []
                }
            }
        },

        deleteSingleBusinessRule(state, section: ScaleBuisinessRuleSection) {
            state.queryBuilder = spliceObjectByKey(state.queryBuilder, section);
        },
        setEditingBri(state, payload) {
            state.isEditingBri = payload.status
        },
        setBriIndex(state, payload) {
            state.briIndex = payload.briIndex
        },
        setInputsHeader(state, payload) {
            const childInputHeader = (state.queryBuilder[payload.currentSection] as any).selectedBuisinessRule!.childInputHeader
            payload.inputHeaders.forEach((inputHeader: any) => {
                const exists = childInputHeader.some((obj: any) => {
                    return obj.label === inputHeader.label;
                })
                if (!exists) {
                    inputHeader.assessmentKey = "inputHeader" + (childInputHeader.length + 1) + "AssessmentKey" + (childInputHeader.length + 1);
                    inputHeader.externalId = "ih-inputHeader" + (childInputHeader.length + 1) + "AssessmentKey" + (childInputHeader.length + 1) + "-businessRuleId";
                    childInputHeader.push(inputHeader)
                }
            });



        },

        setCriterias(state, payload) {
            state.criterias = payload
        },
        setResponses(state, payload) {
            state.responses = payload
        },
        setOutputHeader(state, payload) {
            (state.queryBuilder[payload.currentSection] as any).selectedBuisinessRule!.childOutputHeader = payload.outputHeaders
        },

        setRuleSet(state, payload) {

            (state.queryBuilder[payload.currentSection] as any).ruleSet = payload.query
        },

        setsavingBri(state, payload) {
            state.isSaving = payload
        },
        setSelectedBuisinessRule(state, payload) {
            (state.queryBuilder[payload.currentSection] as any).selectedBuisinessRule = payload.bri
        },
        setSelectedBuisinessRuleItem(state, payload) {
            (state.queryBuilder[payload.currentSection] as any).selectedBuisinessRuleItem = payload.bri
        },
        setbrResluts(state, payload) {
            if ((state.queryBuilder[payload.currentSection] as any)) {
                (state.queryBuilder[payload.currentSection] as any).briResults = payload.extracRule
            }
        },
        clonebrResluts(state, payload) {
            (state.queryBuilder[payload.currentSection] as any).briResults.push(payload.briResult)
        },
        setBuisinessRuleItem(state, payload) {

            (state.queryBuilder[payload.currentSection] as any).selectedBuisinessRule?.childBusinessRuleItem.push(payload.bri)
        },
        updateBuisinessRuleItem(state, payload) {

            const index = (state.queryBuilder[payload.currentSection] as any).selectedBuisinessRule!.childBusinessRuleItem.findIndex((item: any) => item.externalId === payload.bri.externalId);
            if (index !== -1) {
                (state.queryBuilder[payload.currentSection] as any).selectedBuisinessRule!.childBusinessRuleItem.splice(index, 1, payload.bri);
            }
        },

        deleteSelectedBuisinessRuleItem(state, payload) {
            (state.queryBuilder[payload.currentSection] as any).selectedBuisinessRule?.childBusinessRuleItem.splice(payload.briIndex, 1)

        },
        reOrderSelectedBuisinessRuleItem(state, payload) {
            (state.queryBuilder[payload.currentSection] as any).selectedBuisinessRule?.childBusinessRuleItem.forEach((selectedBuisinessRule: any, index: number) => {
                selectedBuisinessRule.externalId = `bri-${(index + 1)}-businessRuleId`
                selectedBuisinessRule.evaluationOrder = index + 1

            });

        },
        deleteSelectedBuisinessRuleItemResults(state, payload) {
            (state.queryBuilder[payload.currentSection] as any).briResults.splice(payload.briIndex, 1)
        },

        clearBuisinessRule(state) {
            state.queryBuilder = {}
        },

        initBuisinessRule(state, payload) {

            for (const key in payload) {
                if (Object.prototype.hasOwnProperty.call(payload, key)) {


                    const value = payload[key];

                    state.queryBuilder[key] = {
                        ruleSet: {
                            operatorIdentifier: "",
                            children: []
                        },
                        selectedBuisinessRule: {
                            "label": "Business Rule 1",
                            "externalId": "businessRuleId",
                            "failIfNoResult": false,
                            "childInputHeader": [],
                            "childOutputHeader": [],
                            "childBusinessRuleItem": [],
                            "returnMultipleResults": false
                        },
                        selectedBuisinessRuleItem: null,
                        briResults: [],

                        criterias: []
                    }

                    // You can perform operations using the key and value here
                }
            }

        }
    }
}
export default queryBuilderModule;
