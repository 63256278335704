import { Module } from "vuex";
import { ContextState } from "@/types";
import { getActionContext, createActionContext, updateActionContext, searchAssessmentContextList } from "@/store/services/configuration/contextService";
import store from "@/store";
import { beautifyJSONString, formatDateLocale } from "@/utils/configuration/formatters-config";
import Notify from 'quasar/src/plugins/Notify.js';;
import router from "@/router";
import i18n from "@/i18n";

const contextModule: Module<ContextState, unknown> = {
  namespaced: true,
  state: {
    actionContext: {
      id: "",
      contextType: "",
      validity_from: "",
      validity_until: "",
      reevaluateRule: null,
      closingRule: null,
      intRule: [{
          itemUID:"",
          advancedConfigurationToggle: true
        }]
    },
    mode: "create",
    filterId: ""
  },
  getters: {
    getDocumentFamilyInitBusinessRule() {
      return {
        "childBusinessRuleItem": [
          {
            "childBusinessRuleAssessment": [
              {
                "inputHeader": {
                  "assessmentKey": null,
                  "attribute": null,
                  "dataType": null,
                  "defaultValue": null,
                  "externalId": "ih-inputHeader1AssessmentKey1-businessRuleId",
                  "label": null
                },
                "rule": "return JSON.parse(param).orderItems.filter(item=>item.orderAssets.filter(asset=>asset.assetTradeIn!=null&&asset.assetTradeIn.resourceUid!=null).length>0).length > 0"
              }
            ],
            "childBusinessRuleResult": [
              {
                "externalId": "bris-displayed-bri-1-businessRuleId",
                "outputHeader": {
                  "businessRuleResultType": null,
                  "defaultValue": null,
                  "externalId": "oh-displayed-businessRuleId",
                  "label": null
                },
                "result": "return true"
              }
            ],
            "evaluationOrder": 1,
            "externalId": "bri-1-businessRuleId"
          }
        ],
        "childInputHeader": [
          {
            "assessmentKey": "inputHeader1AssessmentKey1",
            "attribute": "orderInfo",
            "dataType": "TEXT",
            "defaultValue": "",
            "externalId": "ih-inputHeader1AssessmentKey1-businessRuleId",
            "label": "Input Header 1"
          }
        ],
        "childOutputHeader": [
          {
            "businessRuleResultType": "displayed",
            "defaultValue": "",
            "externalId": "oh-displayed-businessRuleId",
            "label": "Output Header 1"
          }
        ],
        "externalId": "businessRuleId",
        "failIfNoResult": true,
        "label": "Business Rule 1",
        "returnMultipleResults": true
      }
    },
    getBRValueInitBusinessRule() {
      return {
        "label": "Business Rule 1",
        "externalId": "businessRuleId",
        "failIfNoResult": true,
        "childInputHeader": [
          {
            "label": "Input Header 1",
            "dataType": "TEXT",
            "attribute": "orderInfo",
            "externalId": "ih-inputHeader1AssessmentKey1-businessRuleId",
            "defaultValue": "",
            "assessmentKey": "inputHeader1AssessmentKey1"
          }
        ],
        "childOutputHeader": [
          {
            "label": "Output Header 1",
            "externalId": "oh-checked-businessRuleId",
            "defaultValue": "",
            "businessRuleResultType": "checked"
          }
        ],
        "childBusinessRuleItem": [
          {
            "externalId": "bri-1-businessRuleId",
            "evaluationOrder": 1,
            "childBusinessRuleResult": [
              {
                "result": "return 'true'",
                "externalId": "bris-checked-bri-1-businessRuleId",
                "outputHeader": {
                  "label": null,
                  "externalId": "oh-checked-businessRuleId",
                  "defaultValue": null,
                  "businessRuleResultType": null
                }
              }
            ],
            "childBusinessRuleAssessment": [
              {
                "rule": "return (param[0].orderItem[0]).orderAssets[0].assetTradeIn.resourceUid != null",
                "externalId": "bria-inputHeader1AssessmentKey1-bri-1-businessRuleId",
                "inputHeader": {
                  "label": null,
                  "dataType": null,
                  "attribute": null,
                  "externalId": "ih-inputHeader1AssessmentKey1-businessRuleId",
                  "defaultValue": null,
                  "assessmentKey": null
                }
              }
            ]
          }
        ],
        "returnMultipleResults": true
      }
    },
    getActionContextInitBusinessRule() {
      return {
        "label": "Business Rule 1",
        "externalId": "businessRuleId",
        "failIfNoResult": true,
        "childInputHeader": [
          {
            "label": "OrderData",
            "dataType": "TEXT",
            "attribute": "orderInfo",
            "externalId": "ih-inputHeader1AssessmentKey1-businessRuleId",
            "defaultValue": "",
            "assessmentKey": "inputHeader1AssessmentKey1"
          }
        ],
        "childOutputHeader": [
          {
            "label": "Output Header 1",
            "externalId": "oh-itemUID-businessRuleId",
            "defaultValue": "",
            "businessRuleResultType": "itemUID"
          }
        ],
        "childBusinessRuleItem": [
          {
            "externalId": "bri-1-businessRuleId",
            "evaluationOrder": 1,
            "childBusinessRuleResult": [
              {
                "result": "return 'CHKPT_Reglement_Client'",
                "externalId": "bris-itemUID-bri-1-businessRuleId",
                "outputHeader": {
                  "label": null,
                  "externalId": "oh-itemUID-businessRuleId",
                  "defaultValue": null,
                  "businessRuleResultType": null
                }
              }
            ],
            "childBusinessRuleAssessment": [
              {
                "rule": "return true",
                "externalId": "bria-inputHeader1AssessmentKey1-bri-1-businessRuleId",
                "inputHeader": {
                  "label": null,
                  "dataType": null,
                  "attribute": null,
                  "externalId": "ih-inputHeader1AssessmentKey1-businessRuleId",
                  "defaultValue": null,
                  "assessmentKey": null
                }
              }
            ]
          },
          {
            "externalId": "bri-2-businessRuleId",
            "evaluationOrder": 2,
            "childBusinessRuleResult": [
              {
                "result": "return 'CHKPT_Paiement_Aides'",
                "externalId": "bris-itemUID-bri-2-businessRuleId",
                "outputHeader": {
                  "label": null,
                  "externalId": "oh-itemUID-businessRuleId",
                  "defaultValue": null,
                  "businessRuleResultType": null
                }
              }
            ],
            "childBusinessRuleAssessment": [
              {
                "rule": "return true",
                "externalId": "bria-inputHeader1AssessmentKey1-bri-2-businessRuleId",
                "inputHeader": {
                  "label": null,
                  "dataType": null,
                  "attribute": null,
                  "externalId": "ih-inputHeader1AssessmentKey1-businessRuleId",
                  "defaultValue": null,
                  "assessmentKey": null
                }
              }
            ]
          },
          {
            "externalId": "bri-3-businessRuleId",
            "evaluationOrder": 3,
            "childBusinessRuleResult": [
              {
                "result": "return 'CHKPT_Archivage_DV'",
                "externalId": "bris-itemUID-bri-3-businessRuleId",
                "outputHeader": {
                  "label": null,
                  "externalId": "oh-itemUID-businessRuleId",
                  "defaultValue": null,
                  "businessRuleResultType": null
                }
              }
            ],
            "childBusinessRuleAssessment": [
              {
                "rule": "return true",
                "externalId": "bria-inputHeader1AssessmentKey1-bri-3-businessRuleId",
                "inputHeader": {
                  "label": null,
                  "dataType": null,
                  "attribute": null,
                  "externalId": "ih-inputHeader1AssessmentKey1-businessRuleId",
                  "defaultValue": null,
                  "assessmentKey": null
                }
              }
            ]
          }
        ],
        "returnMultipleResults": true
      }
    },
    getInitActionContext() {
      return {
        id: "",
        contextType: "",
        validity_from: "",
        validity_until: "",
        reevaluateRule: {
          itemUID: '',
          advancedConfigurationToggle: true
        },
        closingRule: {
          itemUID: '',
          advancedConfigurationToggle: true
        },
        intRule: {
          itemUID: '',
          advancedConfigurationToggle: true
        },
      }
    },
    getMode(state) {
      return state.mode;
    },

    getFilterId(state) {
      return state.filterId;
    },
  },
  actions: {
    cleanState({ state, commit }) {
      commit('setInitActionContext')
    },

    setMode({ state, commit }, mode) {
      commit('updateMode', mode)
    },

    setFilterId({ state, commit }, filterId) {
      commit('updateFilterId', filterId)
    },

    searchAssessmentContextList({ commit }, payload) {
      const { filter, callback } = payload;
      searchAssessmentContextList(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    },

    getActionContext({ commit }, id) {
      getActionContext(id)
        .then((res) => {
          commit("setActionContext", res);
          router.push("/actionContext/edit");
        })
        .catch((e) => {
          console.error(e)
          Notify.create({
            message: `${e}`,
            color: "negative",
          });
        });
    },

    createActionContext({ commit }, payload) {
      createActionContext(payload)
        .then(() => {
          Notify.create({
            message: i18n.global.t('actionContext.detail.notify.save'),
            color: "positive",
          });
          store.dispatch("contextModule/setFilterId", payload.id);
          router.push("/actionContext/list");
        })
        .catch((e) => {
          store.dispatch("lockState")
          console.error(e)
          Notify.create({
            message: `${e}`,
            color: "negative",
          });
        });
    },
    updateActionContext({ commit }, payload) {
      updateActionContext(payload)
        .then(() => {
          Notify.create({
            message: i18n.global.t('actionContext.detail.notify.update'),
            color: "positive",
          });
          router.push("/actionContext/list");
        })
        .catch((e) => {
          store.dispatch("lockState")
          console.error(e)
          Notify.create({
            message: `${e}`,
            color: "negative",
          });
        });
    },
  },
  mutations: {
    setInitActionContext(state) {
      state.actionContext = store.getters["contextModule/getInitActionContext"]
    },

    updateMode(state, mode) {
      state.mode = mode
    },

    updateFilterId(state, filterId) {
      state.filterId = filterId
    },

    setActionContext(state, res) {
      state.actionContext = res
    
    }
  },
};

export default contextModule;
