import store from "@/store";
import { ScaleBuisinessRuleSection } from "@/types";

export function initializeBusinessRuleSection(section: ScaleBuisinessRuleSection) {
    // Check if the section is already initialized (optional)
    const isInitialized = store.getters['queryBuilderModule/isSectionInitialized'](section);

    if (!isInitialized) {
        // Dispatch the new action to initialize the section
        store.dispatch('queryBuilderModule/initializeSingleBusinessRule', section);
    }
}


export function deleteBusinessRuleSection(section: ScaleBuisinessRuleSection) {
        store.dispatch('queryBuilderModule/deleteSingleBusinessRule', section);
}