import { api, apiAnonymousToken } from "@/auth/api";
import {
  extract,
  rulePayload,
  setOrderedRule,
  setRule,
} from "@/commons/businessRules";
import { getBase64 } from "@/commons/gathering-process-order";
import i18n from "@/i18n";
import { useApi } from "@/requests/useApi";
import { settings } from "@/settings";
import store from "@/store";
import {
  FileContent,
  FilterCatalog,
  GoodDetails,
  Picklist,
  ScaleBuisinessRuleSection,
} from "@/types";
import {
  UON,
  deepClone,
  getEntity,
  getOperator,
  globalConfig,
  headers,
  mountSelectFields,
  unmountSelectFieldsV2,
  upsertWithDataReturn,
  upsertWithDataReturnAnonymous,
} from "@/utils";
import { formatDateLocale } from "@/utils/configuration/formatters-config";
import axios from "axios";
import { downloadFile, saveFile } from "../offerService";
import moment from "moment";
const api_query_url = settings.api_query_url;

const savedCatalogsForCar: Record<string, any> = {};

export const catalogMock = async () => {
  return await [
    {
      reference: "C-145236",
      name: "Catalogue1",
      creationDate: "09/10/2023",
      status: "Valid",
    },
    {
      reference: "C-145232",
      name: "Catalogue2",
      creationDate: "12/09/2023",
      status: "Draft",
    },
    {
      reference: "C-145231",
      name: "Catalogue3",
      creationDate: "23/08/2023",
      status: "Valid",
    },
  ];
};

export function filterCatalog(filter: FilterCatalog): Promise<FilterCatalog[]> {
  return new Promise<FilterCatalog[]>(async (resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: "product-product-catalog-search",
        offset: 0,
        limit: 100,
      },
    };

    qc_header.qc.parameters = {
      ...filter,
    };
    const operators = [];

    if (filter.reference) {
      operators.push(
        getOperator(
          "lower(product_product_catalog",
          "_reference)",
          "LIKE",
          globalConfig.formatters.getLikeValue(filter.reference).toLowerCase(),
          "reference"
        )
      );
    }
    if (filter.name) {
      operators.push(
        getOperator(
          "lower(product_product_catalog",
          "_name)",
          "LIKE",
          globalConfig.formatters.getLikeValue(filter.name).toLowerCase(),
          "name"
        )
      );
    }
    if (filter.validity_from) {
      operators.push(
        getOperator(
          "product_product_catalog",
          "validity_from",
          "EQUAL",
          formatDateLocale(filter.validity_from, "DD/MM/YYYY", "fr").toString,
          "validity_from"
        )
      );
    }
    if (filter.validity_until) {
      operators.push(
        getOperator(
          "product_product_catalog",
          "validity_until",
          "EQUAL",
          formatDateLocale(filter.validity_until, "DD/MM/YYYY", "fr").toString,
          "validity_until"
        )
      );
    }

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
    }
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          catalogs: res.data.map((resItem: any) => {
            return {
              resourceUid: resItem.catalogcode,
              reference: resItem.reference,
              name: resItem.libelle,
              validity: {
                from: resItem.start_date,
                until: resItem.end_date,
              },
            };
          }),
        };
        resolve(response.catalogs);
      })
      .catch((err) => reject(err));
  });
}

export const catalogList = async () => {
  return await [
    {
      reference: "C-145236",
      name: "Catalogue1",
      from: "09/10/2023",
      until: "09/10/2023",
      currency: "",
      price: "24,000,000",
      catalogPrice: "",
    },
    {
      reference: "C-145232",
      name: "Catalogue2",
      from: "12/09/2023",
      until: "12/09/2023",
      currency: "",
      price: "24,000,000",
      catalogPrice: "",
    },
    {
      reference: "C-145231",
      name: "Catalogue3",
      from: "23/08/2023",
      until: "23/08/2023",
      currency: "",
      price: "24,000,000",
      catalogPrice: "",
    },
  ];
};

export function searchAsset(
  filter: any,
  offset?: any,
  limit?: any
): Promise<any[]> {
  return new Promise<any[]>((resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: "asset-search-filter",
        offset: offset || 0,
        limit: limit || 100,
      },
    };

    qc_header.qc.parameters = {
      ...filter,
    };
    const operators = [];

    if (filter.reference) {
      operators.push(
        getOperator(
          "lower(product_product_feature",
          "_reference)",
          "LIKE",
          globalConfig.formatters
            .getEqualValue(filter.reference + "%")
            .toLowerCase(),
          "reference"
        )
      );
    }
    if (filter.name) {
      operators.push(
        getOperator(
          "lower(product_product_feature",
          "_name)",
          "LIKE",
          globalConfig.formatters
            .getEqualValue(filter.name + "%")
            .toLowerCase(),
          "_name"
        )
      );
    }
    if (filter.brand) {
      operators.push(
        getOperator(
          "lower(ppc_brand",
          "category_class_code)",
          "LIKE",
          globalConfig.formatters
            .getEqualValue("%" + filter.brand.label + "%")
            .toLowerCase(),
          "id"
        )
      );
    }
    if (filter.model) {
      operators.push(
        getOperator(
          "lower(ppc_model",
          "category_class_code)",
          "LIKE",
          globalConfig.formatters
            .getEqualValue("%" + filter.model.label + "%")
            .toLowerCase(),
          "id"
        )
      );
    }
    if (filter.status) {
      operators.push(
        getOperator(
          "product_product_feature",
          "status_code",
          "EQUAL",
          globalConfig.formatters.getEqualValue(filter.status),
          "status"
        )
      );
    }
    if (filter.family) {
      operators.push(
        getOperator(
          "lower(ppc_famille",
          "category_class_code)",
          "LIKE",
          globalConfig.formatters
            .getEqualValue("%" + filter.family.id + "%")
            .toLowerCase(),
          "id"
        )
      );
    }
    if (filter.catalog) {
      operators.push(
        getOperator(
          "lower(product_product_catalog",
          "_reference)",
          "LIKE",
          globalConfig.formatters.getEqualValue("%" + filter.catalog),
          "_reference"
        )
      );
    }
    if (filter.supplier && filter.supplier.id) {
       operators.push(getOperator("product_product_catalog", "provider_resourceuid", "LIKE", globalConfig.formatters.getEqualValue(filter.supplier.id.resourceUid), "provider_resourceuid"))
    }
    if (filter.inventory) {
      operators.push(getOperator("product_inventory_item", "asset_product_id", "ISNOTNULL"));
      }

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
    }
    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });
    axios
      .get(url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          items: res.data.map((resItem: any) => {
            return {
              reference: resItem.reference,
              name: resItem.product_name,
              type: "Car",
              marque:
                resItem.brand && resItem.brand.includes("brand")
                  ? resItem.brand.replace(
                      /^asset\.good\.vehicle\.brand\.(.*?)($|\..*$)/,
                      "$1"
                    )
                  : "",
              model:
                resItem.model && resItem.model.includes("range")
                  ? resItem.model.substring(
                      resItem.model.lastIndexOf("range") + 6,
                      resItem.model.length + 1
                    )
                  : "",
              famille:
                resItem.famille && resItem.famille.includes("Family")
                  ? resItem.famille.replace(
                      /^asset\.good\.Good\.Family\.(.*?)($|\..*$)/,
                      "$1"
                    )
                  : "",
              status: resItem.status_code,
              product_code: resItem.product_code,
              catalog: resItem.catalog,
            };
          }),
        };

        resolve(response.items);
      })
      .catch((err) => reject(err));
  });
}

export async function saveGoods(body: GoodDetails) {
 /* if (
    (body.goodAccessory && body.goodAccessory.length > 0) ||
    (body.goodOption && body.goodOption.length > 0) ||
    (body.goodFees && body.goodFees.length > 0)
  ) {*/
    // create new  a productPack
    const bodyClone = deepClone(body);
    const inventory = bodyClone.inventory;

    const pack = await savePack(deepClone(bodyClone));

    const car = await SaveCarProduct(deepClone(bodyClone));
    if (inventory && !(car.inventoryItems && car.inventoryItems.length > 0)) {
      await buildInventory(body, car);
    }
    await associateCatalogsToProduct(car);
    const option = (bodyClone as any)["goodOption"]; // await SavePackItemProduct(deepClone(body), "goodOption")
    const Accessories = (bodyClone as any)["goodAccessory"]; // await SavePackItemProduct(deepClone(body) ,)
    const Other = (bodyClone as any)["goodFees"]; // await SavePackItemProduct(deepClone(body),"goodFees")
    const packItem = await AssociatedGoodToPack(
      pack,
      car,
      option,
      Accessories,
      Other
    );
    return packItem;
 /* } else {
    const bodyClone = deepClone(body);
    const inventory = bodyClone.inventory;
    const car = await SaveCarProduct(bodyClone);
    if (inventory && !(car.inventoryItems && car.inventoryItems.length > 0)) {
      await buildInventory(body, car);
    }

    await associateCatalogsToProduct(car);
    return car;
  }*/
}

export async function associateCatalogsToProduct(car: any) {
  // Get catalogs from  store
  const tempcatalogs = store.state.assetModule.selectedAsset?.selectedCatalogs
    ? store.state.assetModule.selectedAsset?.selectedCatalogs
    : [];
  // Get  catalogs from  Database
  const catalogs = await getCatalogsBYProduct(car.resourceUid);
  // Compare the catalogs if catalog already exists in database we get the resourceUid for the put api else it's new insert
  for (const catalog of tempcatalogs) {
    const existingCatalog = catalogs.find(
      (c: any) => c.resourceUid === catalog.resourceUid
    );
    let referenceid;
    if (existingCatalog) {
      referenceid = findProductReference(
        catalogs,
        catalog.resourceUid,
        car.resourceUid
      ).resourceUid;
    }
    const item = {
      ...(referenceid ? { resourceUid: referenceid } : {}),
      businessData: null,
      daaq: "/",
      product: {
        objectType: car.objectType,
        systemUid: "odm-product",
        resourceUid: car.resourceUid,
      },
      price: {
        amount: catalog.catalogPrice || "34000",
        currency: catalog.currency || "EUR",
      },
    };

    let data = "no data to return";

    if (referenceid) {
      const url = `/odm-product/api/1/odm-product/productreference/${referenceid}/`;
      data = (await api().put(url, item)).data;
    } else {
      const url = `/odm-product/api/1/odm-product/productcatalog/${catalog.resourceUid}/productreference/`;
      data = (await api().post(url, item)).data;
    }
  }
}
/**
 * This method is used to get the association between the catalog and the product
 * This method returns a reference object that contains the association with values like resourceUid, price.
 * @return {reference} An object for reference association informations.
 */
export function findProductReference(
  catalogs: any,
  catalogResourceUid: any,
  carResourceUid: any
) {
  const targetCatalog = catalogs.find(
    (catalog: any) => catalog.resourceUid === catalogResourceUid
  );

  if (targetCatalog && targetCatalog.references) {
    return (
      targetCatalog.references.find(
        (reference: any) =>
          reference.product && reference.product.resourceUid === carResourceUid
      ) || null
    );
  }

  return null;
}

export async function getSelectedGood(resourceUid: string): Promise<any> {
  const url = `/odm-product/api/1/odm-product/assetProductPack/${resourceUid}/packItems/`;
  const data = (await api().post(url, { context: ""})).data;
  return data;
}

export async function getSelectedSimpleGood(
  resourceUid: string
): Promise<GoodDetails> {
  const { response } = await useApi(UON.SystemUid.odmProduct, "carproduct").get(
    resourceUid
  );

  return response;
}

export async function getSelectedSimpleGoodImage(resourceUid: string) {
  const url = `${settings.api_url}/odm-product/api/1/odm-product/goodproduct/${resourceUid}/goodproductimage/`;

  return (await api().get(url)).data;
}
export async function getSelectedAssetDocuments(resourceUid: string) {
  const url = `${settings.api_url}/odm-product/api/1/odm-product/assetproduct/${resourceUid}/assetproductdocument/`;
  return (await api().get(url)).data;
}
export async function getCatalogsBYProduct(good: any) {
  if (good.length > 0) {
    const resourceUid = good[0].productUid;
    const url = `${settings.api_url}/odm-product/api/1/odm-product/catalogs/product/${resourceUid}/`;

    const CatalogsBYProduct = (await api().get(url)).data;
    let price;
    for (const catalog of CatalogsBYProduct) {
      price = findProductReference(
        CatalogsBYProduct,
        catalog.resourceUid,
        resourceUid
      ).price;
      catalog.currency = price.currency;
      catalog.catalogPrice = price.amount;
    }
    return CatalogsBYProduct;
  } else {
    return [];
  }
}

export async function buildSelectedGood(good: any) {
  const goodProduct = good.filter(
    (product: any) =>
      product.objectType &&
      product.objectType ===
        "odm.product.assetproductpackitem.carproductpackitem"
  )[0];
  let SimpleGood = store.getters["assetModule/getGoodsInit"];
  if (goodProduct && goodProduct.itemType === "Vehicle_car") {
    SimpleGood = await getSelectedSimpleGood(goodProduct.productUid);

    const simpleGoodImage = await getSelectedSimpleGoodImage(
      goodProduct.productUid
    );
    if (simpleGoodImage.length > 0) {
      const fielMg = await downloadFile(simpleGoodImage[0].fileId.resourceUid);
      SimpleGood.images = fielMg;
    } else {
      delete SimpleGood.images;
    }
    const assetProductDocument = await getSelectedAssetDocuments(
      goodProduct.productUid
    );
    if (assetProductDocument.length > 0) {
      const docArray = []
      for (const doc of assetProductDocument) {
        const document = await downloadFile(doc.fileReference.resourceUid);
        const documentType = {
          type: doc.documentType.resourceUid ? doc.documentType.resourceUid : "" 
        }
        const filter = {
          productId: goodProduct.productUid,
          documentId:assetProductDocument.resourceUid
      }
      const producDocumentInfo = await getProducDocument(filter)
      const addedBy = producDocumentInfo.length ? producDocumentInfo[0].first_name + ' ' + producDocumentInfo[0].last_name : ""
      const creationDate = producDocumentInfo.length ? moment(producDocumentInfo[0]._when_created, "YYYY-MM-DDTHH:mm:ss.SSSSSS").format('DD/MM/YYYY') : ""
      const returnedDocument = {
        ... document,
        documentType,
        addedBy,
        creationDate
      }
      /*if (producDocumentInfo.length > 0) {
          document.whoCreated = producDocumentInfo[0].first_name + ' ' + producDocumentInfo[0].last_name
      }*/
        docArray.push(returnedDocument);
      }
      SimpleGood.documents = docArray
    } else {
        SimpleGood.documents = [] 

    }

    mountSelectFields(SimpleGood, ["status", "tax"]);

    SimpleGood.type = {
      type: {
        value: "CAR",
      },
    };

    if (!SimpleGood.tax) {
      SimpleGood.tax = {
        type: {
          value: "",
        },
      };
    }
    if (!SimpleGood.status) {
      SimpleGood.status = {
        type: {
          value: "",
        },
      };
    }
    if (SimpleGood.feature && SimpleGood.feature.length > 0) {
      SimpleGood.feature?.forEach((item: any) => {
        item.validity = {
          from:
            item.validity && item.validity.from
              ? formatDateLocale(item.validity?.from, "DD/MM/YYYY", "fr")
              : "",
          until:
            item.validity && item.validity.until
              ? formatDateLocale(item.validity.until, "DD/MM/YYYY", "fr")
              : "",
        };
      });
    }

    if (SimpleGood.classifications && SimpleGood.classifications.length > 0) {
      SimpleGood.brand = buildAssetProductItemFromCategory(
        SimpleGood,
        "asset.good.vehicle.brand"
      );
      SimpleGood.version = buildAssetProductItemFromCategory(
        SimpleGood,
        "asset.good.vehicle.brand.range.version"
      );
      SimpleGood.range = buildAssetProductItemFromCategory(
        SimpleGood,
        "asset.good.vehicle.brand.range"
      );
      SimpleGood.carBodyStyle = buildAssetProductItemFromCategory(
        SimpleGood,
        "asset.good.vehicle.car.bodyStyle"
      );
      SimpleGood.engineType = buildAssetProductItemFromCategory(
        SimpleGood,
        "asset.good.vehicle.energytype"
      );
      SimpleGood.family = buildAssetProductItemFromCategorywithTraduction(
        SimpleGood,
        "asset.good.Good.Family",
        "demand.asset.categoryItems."
      );
      SimpleGood.subFamily = buildAssetProductItemFromCategorywithTraduction(
        SimpleGood,
        "asset.good.Good.Family.SubFamily",
        "demand.asset.subCategoryItems."
      );
      SimpleGood.classifications?.forEach((item: any) => {
        item.validity = {
          from:
            item.validity && item.validity.from
              ? formatDateLocale(item.validity?.from, "DD/MM/YYYY", "fr")
              : "",
          until:
            item.validity && item.validity.until
              ? formatDateLocale(item.validity.until, "DD/MM/YYYY", "fr")
              : "",
        };
      });
    }

    SimpleGood.goodOption = [];
    SimpleGood.goodAccessory = [];
    SimpleGood.goodFees = [];
    if (!SimpleGood.residualValue) {
      const residualValue = {
        advancedConfigurationToggle: false
    }
      SimpleGood.residualValue = residualValue
    }

    if (SimpleGood.residualValue?.rule) {
      const advancedResult = deepClone(
        SimpleGood.residualValue.rule?.returnMultipleResults
      );
      if (SimpleGood.residualValue.rule.returnMultipleResults) {
        (
          store.state.queryBuilderModule.queryBuilder[
            ScaleBuisinessRuleSection.RESIDUALVALUE as any
          ] as any
        ).selectedBuisinessRule = SimpleGood.residualValue.rule;
      }
      const extracRule = extract(
        SimpleGood.residualValue.rule,
        buisinessRuleResults
      );
      store.dispatch("queryBuilderModule/setbrResluts", {
        extracRule: extracRule,
        currentSection: ScaleBuisinessRuleSection.RESIDUALVALUE,
      });
      SimpleGood.residualValue = extracRule[0] || {};
      if (extracRule[0] && advancedResult) {
        SimpleGood.residualValue.advancedConfigurationToggle = true;
      } else {
        SimpleGood.residualValue.advancedConfigurationToggle = false;
      }
    }

    (SimpleGood.FeesItems = {
      validity: {
        from: "",
        until: "",
      },
    }),
      (SimpleGood.optionItems = {
        validity: {
          from: "",
          until: "",
        },
      }),
      (SimpleGood.AccessoryItems = {
        validity: {
          from: "",
          until: "",
        },
      });
    SimpleGood.goodCatalogs = {};

    if (!SimpleGood.validity) {
      intiValidity(SimpleGood);
    }
    if (SimpleGood.inventoryItems && SimpleGood.inventoryItems.length > 0) {
      SimpleGood.inventory = true;
      const inventoryItem = deepClone(SimpleGood.inventoryItems[0]);

      SimpleGood.guarantee = {
        from: inventoryItem.guarantee ? inventoryItem.guarantee.from : "",
        until: inventoryItem.guarantee ? inventoryItem.guarantee.until : "",
      };
      SimpleGood.matriculationDate = inventoryItem.matriculationDate
        ? inventoryItem.matriculationDate
        : "";
      SimpleGood.matriculation = inventoryItem.matriculation;
      SimpleGood.serialNumber = inventoryItem.serialNumber;
      SimpleGood.constructionDate = inventoryItem.constructionDate
        ? inventoryItem.constructionDate
        : "";
    } else {
      (SimpleGood.inventory = false),
        (SimpleGood.guarantee = {
          from: "",
          until: "",
        }),
        (SimpleGood.serialNumber = ""),
        (SimpleGood.constructionDate = "");
      SimpleGood.matriculationDate = "";
      SimpleGood.matriculation = "";
    }
  }
  return SimpleGood;
}

export async function selectGoodImage(productUid: any) {
  let fielMg: any;
  const simpleGoodImage = await getSelectedSimpleGoodImage(productUid);
  if (simpleGoodImage.length > 0) {
    fielMg = await downloadFile(simpleGoodImage[0].fileId.resourceUid);
  }
  return fielMg;
}

export async function buildSelectedOption(good: any) {
  const itemsList = [];
  const optList = good.filter(
    (product: any) =>
      product.objectType &&
      product.objectType ===
        "odm.product.assetproductpackitem.optionassetproductpackitem"
  );
  for (const opt of optList) {
    const buildOpt = await buildGoodProposalItem(opt);
    itemsList.push(buildOpt);
  }
  return itemsList;
}

export async function buildSelectedAccesory(good: any) {
  const itemsList = [];
  const accList = good.filter(
    (product: any) =>
      product.objectType &&
      product.objectType ===
        "odm.product.assetproductpackitem.accessoryassetproductpackitem"
  );
  for (const acc of accList) {
    const buildAcc = await buildGoodProposalItem(acc);
    itemsList.push(buildAcc);
  }
  return itemsList;
}

export async function buildSelectedOther(good: any) {
  const itemsList = [];
  const feesList = good.filter(
    (product: any) =>
      product.objectType &&
      product.objectType ===
        "odm.product.assetproductpackitem.otherassetproductpackitem"
  );
  for (const fees of feesList) {
    const buildFees = await buildGoodProposalItem(fees);
    itemsList.push(buildFees);
  }
  return itemsList;
}

async function buildGoodProposalItem(item: any) {
  const unitaryAmount = item.feature.filter(
    (feature: any) =>
      feature.objectType &&
      feature.objectType === "odm.product.featurepricingparameter"
  )[0]?.standardValue;
  const proposalItem = await getGoodProposalItem(item.productUid);
  return {
    nameItem: {
      id: item.productUid,
      label: item.name,
    },
    required: item.required,
    priceWoTax: unitaryAmount,
    typeTax: {
      label: item.tax,
      value: item.tax,
    },
    resourceUid: item.productUid,
    objectType: "odm.product.productfeature.product.assetproduct.goodproduct",
    classifications: proposalItem.classifications,
  };
}

function buildClassificationFromAssetItem(body: any) {
  const listClassification = [
    body.brand,
    body.typeClass,
    body.version,
    body.range,
    body.carBodyStyle,
    body.engineType,
    body.family,
    body.subFamily,
  ];
  if(!body.classifications){
    body.classifications =[]
  }
if (body.classifications) {
  for (const classif of body.classifications) {
    const existsInList = listClassification.find(
      (item) => item?.config?.name === classif.category.resourceUid
    );
    if (!existsInList) {
      // Ajout des classifications non présentes dans listClassification
      listClassification.push({
        id: classif.categoryClass.resourceUid,
        config: { name: classif.category.resourceUid },
      });
    }
  }
}
  if (body.classifications) {
    for (const item of listClassification) {
      const findClassification = body.classifications.filter(
        (classif: any) => classif.category.resourceUid === item?.config?.name
      );
      if (item && item.id && findClassification.length === 0) {
        body.classifications.push({
          categoryClass: {
            objectType: "odm.product.productcategoryclass",
            systemUid: "odm-product",
            resourceUid: item.id,
          },
          priority: 0,
          category: {
            objectType: "odm.product.productcategory",
            systemUid: "odm-product",
            resourceUid: item.config.name,
          },
          daaq: "/",
          objectType: "odm.product.productclassification",
          systemUid: "odm-product",
          businessData: {},
        });
      }if (item && item.id && item.config&& item.config.name&& findClassification.length > 0) {
        findClassification[0].categoryClass.resourceUid=item.id
        findClassification[0].category.resourceUid=item.config.name
      }
    }
  }
  const listIndexs:any=[]
  if (body.classifications && body.classifications.length > 0) {

    body.classifications.forEach((item: any) => {
      const findClassification = listClassification.filter(
        (classif: any) => item.category.resourceUid === classif?.config?.name
      );
      if(findClassification.length===0){
        listIndexs.push(body.classifications.indexOf(item))
      }
      item.validity = {
        from:
          item.validity && item.validity.from
            ? formatDateLocale(item.validity.from, "YYYY-MM-DD", "fr")
            : "",
        until:
          item.validity && item.validity.until
            ? formatDateLocale(item.validity.until, "YYYY-MM-DD", "fr")
            : "",
      };
    });
  }
  if(listIndexs && listIndexs.length > 0){
    for (const index of listIndexs){
      body.classifications.splice(index, 1);
    }
    
  }

  delete body.brand;
  delete body.family;
  delete body.subFamily;
  delete body.version;
  delete body.range;
  delete body.carBodyStyle;
  delete body.engineType;

  return body;
}

function buildAssetProductItemFromCategory(assetProduct: any, key: string) {
  const categClass = assetProduct.classifications.filter(
    (classif: any) =>
      classif.category.resourceUid && classif.category.resourceUid === key
  );
  return {
    id:
      categClass && categClass.length > 0 && categClass[0].categoryClass
        ? categClass[0].categoryClass.resourceUid
        : "",
    label:
      categClass && categClass.length > 0 && categClass[0].categoryClass
        ? categClass[0].categoryClass.resourceUid.substring(
            assetProduct.classifications
              .filter(
                (classif: any) =>
                  classif.category.resourceUid &&
                  classif.category.resourceUid === key
              )[0]
              .categoryClass.resourceUid.lastIndexOf(".") + 1
          )
        : "",
    config: {
      name: key,
    },
  };
}

function buildAssetProductItemFromCategorywithTraduction(
  assetProduct: any,
  key: string,
  translate: string
) {
  const categClass = assetProduct.classifications.filter(
    (classif: any) =>
      classif.category.resourceUid && classif.category.resourceUid === key
  );
  return {
    id:
      categClass && categClass.length > 0
        ? categClass[0].categoryClass.resourceUid
        : "",
    label:
      categClass && categClass.length > 0
        ? i18n.global.t(
            translate +
              categClass[0].categoryClass.resourceUid.substring(
                assetProduct.classifications
                  .filter(
                    (classif: any) =>
                      classif.category.resourceUid &&
                      classif.category.resourceUid === key
                  )[0]
                  .categoryClass.resourceUid.lastIndexOf(".") + 1
              )
          )
        : "",
    config: {
      name: key,
    },
  };
}

function intiValidity(body: GoodDetails) {
  body.validity = {
    from: "",
    until: "",
  };
}

const buisinessRuleResults = {
  minRv: 0,
  maxRv: 0,
  defaultRv: 0,
};

async function SaveInventory(body: any, parentUid: any) {
  const data = await upsertWithDataReturnAnonymous(
    UON.SystemUid.odmProduct,
    body,
    "/assetproduct/" + parentUid + "/inventoryitem/"
  );
  return data;
}
export async function SaveCarProduct(body: any) {


  delete body.goodAccessory
  delete body.goodCatalogs
  delete body.goodFees
  delete body.goodOption
  delete body.optionItems
  delete body.AccessoryItems
  delete body.FeesItems
  delete body.packid
  delete body.selectedCatalogs
  delete body.selectedGoods

  unmountSelectFieldsV2(body, ["type", "status", "tax"]);

  if (body.validity) {
    body.validity.from = body.validity.from
      ? formatDateLocale(body.validity.from, "DD/MM/YYYY", "fr")
      : "";
    body.validity.until = body.validity.until
      ? formatDateLocale(body.validity.until, "DD/MM/YYYY", "fr")
      : "";
  }
  if (!body?.tax?.resourceUid) {
    delete body.tax;
  }
  if (body.type && body.type?.resourceUid === "CAR") {
    body.objectType =
      "odm.product.productfeature.product.assetproduct.goodproduct.vehicleproduct.carproduct";
    body.typeClass = {
      id: "NATURE.CAR",
      config: {
        name: "NATURE",
      },
    };

    delete body.type;
  }
  body = buildInventoryItemsFromAssetItem(body);

  body = buildClassificationFromAssetItem(body);

  delete body.typeClass;

  if (body.feature && body.feature.length > 0) {
    body.feature.forEach((item: any) => {
      delete item.discount
      item.name = item.name ? item.name : item.resourceUid
      item.label = item.label ? item.label : item.resourceUid
      item.validity.from = item.validity&&item.validity.from ? formatDateLocale(item.validity.from, 'YYYY-MM-DD', 'fr') : ''
      item.validity.until = item.validity&&item.validity.until ? formatDateLocale(item.validity.until, 'YYYY-MM-DD', 'fr') : ''
    })
  }
  if (body.residualValue) {
    const rule = setRule(body.residualValue);
    const orderedRule: { [p: string]: string } = setOrderedRule(
      rule,
      ResidualValueKeys
    );

    body.residualValue.advancedConfigurationToggle
      ? ((
          store.state.queryBuilderModule.queryBuilder[
            ScaleBuisinessRuleSection.RESIDUALVALUE as any
          ] as any
        ).selectedBuisinessRule.returnMultipleResults = true)
      : false;
    body.residualValue = {
      ruleEngineId: null,
      rule: body.residualValue.advancedConfigurationToggle
        ? (
            store.state.queryBuilderModule.queryBuilder[
              ScaleBuisinessRuleSection.RESIDUALVALUE as any
            ] as any
          )?.selectedBuisinessRule
        : rulePayload({
            inputAttribute: "quoteInfo",
            inputDataType: "TEXT",
            orderedRule,
          }),
    };
  }
  const fileImage = store.state.assetModule.selectedAsset?.images;
  const documents = store.state.assetModule.selectedAsset?.documents ? store.state.assetModule.selectedAsset?.documents.filter(doc => Object.keys(doc).length > 0) : [];
  delete body.images;
  delete body.documents;
  const data = await upsertWithDataReturnAnonymous(
    UON.SystemUid.odmProduct,
    body,
    "carproduct"
  );

  if (fileImage) {
    await manageAssetImage(fileImage, data);
  }
  if (documents) {
    for (const document of documents){
      await manageAssetDocument(document, data);
    }
  }

  return data;
}

export async function manageAssetImage(fileImage: any, data: any) {
  getBase64(fileImage).then(async (base64) => {
    const fileContent: FileContent = {
      originalFileName: "carTest",
      format: "image/png",
      content: base64,
      relations: {
        items: [
          {
            qualifier: {
              rawValue: "vehicule",
            },
            objectUON: {
              objectType: "odm.filemngt.filecontent",
              systemUid: UON.SystemUid.odmFileManagement,
            },
          },
        ],
      },
    };
    const image = await saveFile(fileContent);
    const goodProductImage = {
      fileId: {
        objectType: image.objectType,
        systemUid: image.systemUid,
        resourceUid: image.resourceUid,
      },
      objectType: "odm.product.goodproductimage",
    };

    const url = `/odm-product/api/1/odm-product/goodproduct/${data.resourceUid}/goodproductimage/`;
    (await apiAnonymousToken().post(url, goodProductImage)).data;
  });
}
export async function manageAssetDocument(document: any, data: any) {
    let assetproductdocument;
    if (document.resourceUid) {
      assetproductdocument = {
        fileReference: {
          objectType: "odm.filemngt.filedescriptor",
          systemUid: "odm-filemngt",
          resourceUid: document.resourceUid,
        },
        documentType : document.documentType.type
        ?
         {
          resourceUid: document.documentType.type 
        }
        :
        null,
        objectType: "odm.product.assetproductdocument",
      };
    }
    else {
        const base64 = await getBase64(document.file);
        const fileContent: FileContent = {
          originalFileName: document.originalFileName,
          format: document.format,
          content: base64,
          relations: {
            items: [
              {
                qualifier: {
                  rawValue: "vehicule",
                },
                objectUON: {
                  objectType: "odm.filemngt.filecontent",
                  systemUid: UON.SystemUid.odmFileManagement,
                },
              },
            ],
          },
        };
        const doc = await saveFile(fileContent);
         assetproductdocument = {
          fileReference: {
            objectType: doc.objectType,
            systemUid: doc.systemUid,
            resourceUid: doc.resourceUid,
          },
          documentType: document.documentType.type 
          ? {
            resourceUid: document.documentType.type,
          }
          : null,
          objectType: "odm.product.assetproductdocument",
        };
    }
     const url = `/odm-product/api/1/odm-product/assetproduct/${data.resourceUid}/assetproductdocument/`;
        (await apiAnonymousToken().post(url, assetproductdocument)).data; 
}

export async function SavePackItemProduct(body: any, type: string) {
  const pack: any[] = [];

  if ((body as any)[type]) {
    for (const opt of (body as any)[type]) {
      const ref = (Math.random() + 1).toString(36).substring(7);
      const goodOption = {
        resourceUid: opt.resourceUid,
        objectType:
          "odm.product.productfeature.product.assetproduct.goodproduct",
        businessData: null,
        daaq: opt.daaq ? opt.daaq : "/",
        reference: opt.ref ? opt.ref : ref,
        name: opt.nameItem.label ? opt.nameItem.label : opt.nameItem,
        validity: {
          from:
            opt.validity && opt.validity.from
              ? formatDateLocale(opt.validity.from, "YYYY-MM-DD", "fr")
              : "",
          until:
            opt.validity && opt.validity.until
              ? formatDateLocale(opt.validity.until, "YYYY-MM-DD", "fr")
              : "",
        },
        tax: {
          objectType: "odm.product.producttax",
          systemUid: "odm-product",
          resourceUid: opt.typeTax ? opt.typeTax.value : "",
        },
        status: {
          objectType: "odm.product.productstatus",
          systemUid: "odm-product",
          resourceUid: opt.status ? opt.status : "",
        },
        feature: [
          {
            objectType: "odm.product.featurepricingparameter",
            businessData: null,
            daaq: "/",
            defaultValue: opt.required,
            label: "AMOUNT",
            internalCode: "AMOUNT",
            name: "AMOUNT",
            standardValue: opt.priceWoTax,
            validity: {
              from:
                opt.validity && opt.validity.from
                  ? formatDateLocale(opt.validity.from, "YYYY-MM-DD", "fr")
                  : "",
              until:
                opt.validity && opt.validity.until
                  ? formatDateLocale(opt.validity.until, "YYYY-MM-DD", "fr")
                  : "",
            },
          },
        ],
        classifications: opt.classifications,
      };
      const item = await upsertWithDataReturn(
        UON.SystemUid.odmProduct,
        goodOption,
        "goodproduct"
      );
      pack.push(item);
    }
  }
  return pack;
}

async function savePack(body: GoodDetails) {
  let pack: any;
  delete body.inventory;
  delete body.guarantee;
  delete body.serialNumber;
  delete body.constructionDate;
  delete body.matriculationDate;
  delete body.matriculation;
  if (!body.packid) {
    const newPack = {
      objectType: "odm.product.productfeature.product.productpack",
      businessData: null,
      daaq: "/",
      reference: body.reference?.concat("_Pack"),
      name: body.name?.concat("_Pack"),
      classifications: [
        {
          categoryClass: {
            objectType: "odm.product.productcategoryclass",
            systemUid: "odm-product",
            resourceUid: "NATURE.CARPACK",
          },
          priority: 0,
          category: {
            objectType: "odm.product.productcategory",
            systemUid: "odm-product",
            resourceUid: "NATURE",
          },
          daaq: "/",
          objectType: "odm.product.productclassification",
          systemUid: "odm-product",
          businessData: {},
        },
      ],

      validity: {
        from: null,
        until: null,
      },
    };

    pack = await upsertWithDataReturnAnonymous(
      UON.SystemUid.odmProduct,
      newPack,
      "productpack"
    );
  } else {
    pack = await getEntity("odm-product", "productpack", body.packid);
  }
  return pack;
}
export const ResidualValueKeys: string[] = ["minRv", "maxRv", "defaultRv"];
export const outputFormConfiguration: any = {
  min: ["minRv"],
  max: ["maxRv"],
  default: ["defaultRv"],
};

async function AssociatedGoodToPack(
  pack: any,
  car: any,
  options: any,
  Accessories: any,
  Others: any
) {
  const packFetures = await getExistinglink(pack.resourceUid);
  const carItemExist = packFetures?.filter(
    (fetures: any) => fetures.feature.resourceUid === car.resourceUid
  );
  if (carItemExist.length == 0) {
    await AssociatedItemToPack(
      pack.resourceUid,
      car.resourceUid,
      car.objectType,
      "Vehicle_car",
      carItemExist
    );
  }

  for (const opt of options) {
    const optItemExist = packFetures?.filter(
      (features: any) => features.feature.resourceUid === opt.resourceUid
    );
    if (optItemExist.length == 0) {
      await AssociatedItemToPack(
        pack.resourceUid,
        opt.resourceUid,
        opt.objectType,
        "Option_car",
        optItemExist
      );
    }
  }

  for (const acc of Accessories) {
    const accItemExist = packFetures?.filter(
      (fetures: any) => fetures.feature.resourceUid === acc.resourceUid
    );
    await AssociatedItemToPack(
      pack.resourceUid,
      acc.resourceUid,
      acc.objectType,
      "Accessory_car",
      accItemExist
    );
  }
  for (const oth of Others) {
    const othItemExist = packFetures?.filter(
      (fetures: any) => fetures.feature.resourceUid === oth.resourceUid
    );
    await AssociatedItemToPack(
      pack.resourceUid,
      oth.resourceUid,
      oth.objectType,
      "Other_car",
      othItemExist
    );
  }

  for (const fetutres of packFetures) {
    let optToBeDelete = [];
    let accToBeDelete = [];
    let feesToBeDelete = [];
    optToBeDelete = options.filter(
      (opt: any) => fetutres.feature.resourceUid === opt.resourceUid
    );
    accToBeDelete = Accessories.filter(
      (Accessories: any) =>
        fetutres.feature.resourceUid === Accessories.resourceUid
    );
    feesToBeDelete = Others.filter(
      (Others: any) => fetutres.feature.resourceUid === Others.resourceUid
    );
    if (
      optToBeDelete.length == 0 &&
      accToBeDelete.length == 0 &&
      feesToBeDelete.length == 0 &&
      fetutres.packageFeatureType.resourceUid !== "Vehicle_car"
    ) {
      await deleteLink(fetutres);
    }
  }

  return pack;
}

async function deleteLink(optToBeDelete: any) {
  let result;
  const resourceUid = optToBeDelete.resourceUid;
  if (resourceUid) {
    result = await api(optToBeDelete.daaq).delete(
      `${settings.api_url}/odm-product/api/1/odm-product/simplepackagefeature/${resourceUid}/`
    );
  }
  return result;
}

async function AssociatedItemToPack(
  packId: string,
  itemId: string,
  featureType: string,
  itemObjectType: string,
  existingLinkItem: any
) {
  const item = {
    resourceUid: existingLinkItem.length
      ? existingLinkItem[0].resourceUid
      : null,
    objectType: "odm.product.packagefeature.simplepackagefeature",
    businessData: null,
    daaq: "/",
    feature: {
      objectType: featureType,
      systemUid: "odm-product",
      resourceUid: itemId,
    },
    includeInPackagePrice: false,
    packageFeatureType: {
      objectType: "odm.product.packagefeaturetype",
      systemUid: "odm-product",
      resourceUid: itemObjectType,
    },
    required: true,
  };

  let data = "no data to return";

  if (!item.resourceUid) {
    const url = `/odm-product/api/1/odm-product/productpack/${packId}/simplepackagefeature/`;
    data = (await api().post(url, item)).data;
  } else {
    const url = `/odm-product/api/1/odm-product/simplepackagefeature/${item.resourceUid}/`;
    data = (await api().put(url, item)).data;
  }
  return data;
}

async function getExistinglink(packid: string) {
  const url = `${settings.api_url}/odm-product/api/1/odm-product/productpack/${packid}/simplepackagefeature/`;

  const pack = (await api().get(url)).data;

  return pack;
}

export async function saveProposalItem(body: any, type: string) {
  let item: any;
  const categoryClassCode =
    type === "goodOption"
      ? "NATURE.OPTION"
      : type === "goodAccessory"
      ? "NATURE.ACCESSORY"
      : "NATURE.ASSETFEE";
  if (body) {
    const ref = (Math.random() + 1).toString(36).substring(7);
    const goodOption = {
      objectType: "odm.product.productfeature.product.assetproduct.goodproduct",
      businessData: null,
      daaq: "/",
      reference: body.ref ? body.ref : ref,
      name: body.nameItem.id ? body.nameItem.id : body.nameItem,
      validity: {
        from:
          body.validity && body.validity.from
            ? formatDateLocale(body.validity.from, "YYYY-MM-DD", "fr")
            : "",
        until:
          body.validity && body.validity.until
            ? formatDateLocale(body.validity.until, "YYYY-MM-DD", "fr")
            : "",
      },
      tax: {
        objectType: "odm.product.producttax",
        systemUid: "odm-product",
        resourceUid: body.typeTax.value ? body.typeTax.value : "",
      },
      status: {
        objectType: "odm.product.productstatus",
        systemUid: "odm-product",
        resourceUid: body.status ? body.status : "",
      },
      feature: [
        {
          objectType: "odm.product.featurepricingparameter",
          businessData: null,
          daaq: "/",
          defaultValue: body.required,
          label: "AMOUNT",
          internalCode: "AMOUNT",
          name: "AMOUNT",
          standardValue: body.priceWoTax,
          validity: {
            from:
              body.validity && body.validity.from
                ? formatDateLocale(body.validity.from, "YYYY-MM-DD", "fr")
                : "",
            until:
              body.validity && body.validity.until
                ? formatDateLocale(body.validity.until, "YYYY-MM-DD", "fr")
                : "",
          },
        },
      ],
      residualValue: body.advancedMode
        ? {
            ruleEngineId: null,
            rule: body.advancedMode
              ? store.state.queryBuilderModule.queryBuilder.FEES
                  .selectedBuisinessRule
              : null,
          }
        : null,
      classifications: [
        {
          objectType: "odm.product.productclassification",
          businessData: null,
          daaq: "/",
          validity: {
            from: "",
            until: "",
          },
          categoryClass: {
            objectType: "odm.product.productcategoryclass",
            systemUid: "odm-product",
            resourceUid: categoryClassCode,
          },
          priority: null,
          category: {
            objectType: "odm.product.productcategory",
            systemUid: "odm-product",
            resourceUid: "NATURE",
          },
        },
      ],
    };
    item = await upsertWithDataReturn(
      UON.SystemUid.odmProduct,
      goodOption,
      "goodproduct"
    );
  }
  return item;
}

export async function saveCatalog(body: any) {
  let catalog: any;
  const result = filterCatalog({ reference: body.reference });
  if (body) {
    if ((await result).length == 1) {
      throw new Error("La référence existe déjà");
    }
    const catalogData = {
      objectType: "odm.product.productcatalog",
      systemUid: "odm-product",
      businessData: null,
      daaq: "/",
      reference: body.reference ? body.reference : "",
      name: body.description ? body.description : body.name ? body.name : "",
      validity: {
        from:
          body.validity && body.validity.from
            ? formatDateLocale(body.validity.from, "DD/MM/YYYY", "fr")
            : "",
        until:
          body.validity && body.validity.until
            ? formatDateLocale(body.validity.until, "DD/MM/YYYY", "fr")
            : "",
      },
      provider: body.provider ? body.provider : "",
    };
    catalog = await upsertWithDataReturn(
      UON.SystemUid.odmProduct,
      catalogData,
      "productcatalog"
    );
  }

  return catalog;
}

export async function getGoodProposalItem(resourceUid: string) {
  const { response } = await useApi(
    UON.SystemUid.odmProduct,
    "goodproduct"
  ).get(resourceUid);

  return response;
}

export function getAssetProposelItem(filter: any): Promise<Picklist> {
  return new Promise<Picklist>(async (resolve, reject) => {
    const url = settings.api_query_url;

    const qc_header: any = {
      qc: {
        queryId: "asset-product-classification",
      },
    };
    qc_header.qc.parameters = {
      ...filter,
    };
    const operators = [];
    if (filter && filter.category_code != null) {
      operators.push(
        getOperator(
          "product_product_classification",
          "category_code",
          "EQUAL",
          `'${filter.category_code}'`,
          "category_code"
        )
      );
    }
    if (filter && filter.category_class_code != null) {
      operators.push(
        getOperator(
          "product_product_classification",
          "category_class_code",
          "EQUAL",
          `'${filter.category_class_code}'`,
          "category_class_code"
        )
      );
    }

    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators,
            },
          },
        ],
      };
      qc_header.qc.otherOrders = null;
      qc_header.qc.offset = 0;
      qc_header.qc.limit = 50;
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc),
    });

    axios
      .get(url, {
        headers: _headers,
      })
      .then((res) => {
        const response = {
          systemUid: "odm-product",
          items: res.data.map((resItem: any) => {
            return {
              id: resItem.product_id,
              label: resItem.product_name,
            };
          }),
        };
        resolve(response);
      })
      .catch((err) => {
        resolve({
          systemUid: "",
          items: [
            {
              id: "odm-party",
              label: "Crée2",
            },
          ],
        });
      });
  });
}

export const feesProposalItemKeys: string[] = ["minRv", "maxRv", "defaultRv"];

export const outputFeesFormConfiguration: any = {
  priceWoTax: ["minRv", "maxRv", "defaultRv"],
}
export async function buildInventory(body: any,car:any) {
  const bodyInventory:any={
    objectType: "odm.product.inventoryitem.inventoryitemvehicle.inventoryitemcar",
    systemUid: "odm-product",
    daaq: car.daaq,
    serialNumber: body.serialNumber,
    constructionDate: body.constructionDate
      ? formatDateLocale(body.constructionDate, "DD/MM/YYYY", "fr")
      : "",
    guarantee: {
      from:
        body.guarantee && body.guarantee.from
          ? formatDateLocale(body.guarantee.from, "DD/MM/YYYY", "fr")
          : "",
      until:
        body.guarantee && body.guarantee.until
          ? formatDateLocale(body.guarantee.until, "DD/MM/YYYY", "fr")
          : "",
    },
    type: {
      objectType: "odm.product.inventorytype",
      systemUid: "odm-product",
      resourceUid: "NEW",
    },
    status: {
      objectType: "odm.product.inventorystatus",
      systemUid: "odm-product",
      resourceUid: "IN_STOCK",
    },
    matriculationDate: body.matriculationDate
      ? formatDateLocale(body.matriculationDate, "DD/MM/YYYY", "fr")
      : "",
    matriculation: body.matriculation,
    currentMileage: body.millage,
  };

  if (body.feature && body.feature.length > 0) {
    bodyInventory.price = {
      amount: body.feature[0].standardValue || 0,
      currency: body.feature[0].currency || "EUR",
    };
  } else {
    bodyInventory.price = {
      amount: 0,
      currency: "EUR",
    };
  }
  await SaveInventory(bodyInventory, car.resourceUid);
}

function buildInventoryItemsFromAssetItem(body: any) {
  if (body.inventoryItems && body.inventoryItems.length > 0) {
    for (const inventory of body.inventoryItems) {
      inventory.constructionDate = body.constructionDate
        ? formatDateLocale(body.constructionDate, "DD/MM/YYYY", "fr")
        : "";
      inventory.guarantee = {
        from:
          body.guarantee && body.guarantee.from
            ? formatDateLocale(body.guarantee.from, "DD/MM/YYYY", "fr")
            : "",
        until:
          body.guarantee && body.guarantee.until
            ? formatDateLocale(body.guarantee.until, "DD/MM/YYYY", "fr")
            : "",
      };
      inventory.matriculationDate = body.matriculationDate
        ? formatDateLocale(body.matriculationDate, "DD/MM/YYYY", "fr")
        : "";
      inventory.matriculation = body.matriculation;
      inventory.serialNumber = body.serialNumber;
    }
  }
  delete body.inventory;
  delete body.guarantee;
  delete body.serialNumber;
  delete body.constructionDate;
  delete body.matriculationDate;
  delete body.matriculation;
  return body;
}
export function getProducDocument(filter: any): Promise<any> {
  return new Promise<any>(async (resolve, reject) => {
    const qc_header: any = {
      qc: {
        queryId: "product-asset-product-document",
        offset: 0,
        limit: 50,
      },
    };
    const operators = []
    if (filter && filter.productId != null) {
      operators.push(getOperator("product_asset_product_document", "asset_product_id", "EQUAL", globalConfig.formatters.getEqualValue(filter.productId), ""))
    }
    if (filter && filter.documentId != null) {
      operators.push(getOperator("product_asset_product_document", "id", "EQUAL", globalConfig.formatters.getEqualValue(filter.documentId), ""))
    }
    if (operators.length > 0) {
      qc_header.qc.otherFilters = {
        expressions: [
          {
            and: {
              operators
            }
          }
        ]
      }
      qc_header.qc.otherOrders = null
      qc_header.qc.offset = 0
      qc_header.qc.limit = 50
    }

    const _headers = headers({
      qc: JSON.stringify(qc_header.qc)
    })
    axios
      .get(api_query_url, {
        headers: _headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}
