
import {Options, Vue} from "vue-class-component";
import {NoticeTemplate} from "@/types";
import store from "@/store";
import PrintDocumentListPackItem from "@/components/document/list/PrintDocumentListPackItem.vue";

@Options({
  props: ["noticeTemplate", "index", "reference"],
  components: {PrintDocumentListPackItem}
})
export default class PrintDocumentListItem extends Vue {
  reference: any;
  index?: number
  opened = false;
  noticeTemplate?: NoticeTemplate;
  isLoadingView = false;
  isLoadingDownload = false;

  get isPack() {
    return this.noticeTemplate?.isPack;
  }

  onSelectChange() {
    store.dispatch("printDocumentModule/setTemplateSelected", {
      template: this.noticeTemplate,
      selected: this.noticeTemplate?.selected,
    });
    this.updateParentParentBoolean(false);
  }

  onSendEvent() {
    this.updateParentParentBoolean(true);
    store.dispatch("printDocumentModule/sendTemplate", {
      template: this.noticeTemplate,
    });
  }

  get currentRoute() {
    return this.$router.currentRoute.value.name;
  }

  async onViewEvent() {
    this.isLoadingView = true;
    this.updateParentParentBoolean(true);
    await store.dispatch('printDocumentModule/generateDocument', {
      index: this.index,
      currentRoute: this.currentRoute,
      callback: this.setPreviewTemplate
    })
  }

  setPreviewTemplate(){
    store.dispatch("printDocumentModule/setPreviewTemplate", {
      template: this.noticeTemplate,
      callback: this.viewDocumentResponse,
    });
  }
  viewDocumentResponse() {
    this.isLoadingView = false;
    this.isLoadingDownload = false;
  }

  onDownloadEvent() {
    this.isLoadingDownload = true;
    this.updateParentParentBoolean(true);
    store.dispatch('printDocumentModule/generateDocument', {
      index: this.index,
      currentRoute: this.currentRoute,
      callback: this.downloadFile
    })
  }

  downloadFile(){
    store.dispatch("printDocumentModule/downloadFile", {
      request: {
        resourceUid: this.fileId
      },
      callback: this.downloadDocumentResponse,
    });
  }

  downloadDocumentResponse(response: any) {
    if (response) {
      const { content } = response;
      const fileName = this.noticeTemplate?.fileName || response?.originalFileName; 
      const linkSource = `data:${response.format};base64,${content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = `${fileName}_${this.reference}.pdf`;
      downloadLink.click();
      this.viewDocumentResponse()
    }
  }

  openPackDetail() {
    this.opened = !this.opened;
  }

  updateParentParentBoolean(action: boolean) {
    this.$emit("child-boolean-changed", this.noticeTemplate, action);
  }

  get fileId() {
    return store.state.printDocumentModule.fileDetailFileId
  }
}
