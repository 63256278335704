import i18n from "@/i18n";
import store from "@/store";
import { searchRemuneration,saveCommission, getSelectedPackCommission, buildPackCommission, buildCommission } from "@/store/services/configuration/RemunerationService";
import { RemunerationState, RemunerationStatus, ScaleBuisinessRuleSection } from "@/types";
import { deepClone } from "@/utils";
import Notify from 'quasar/src/plugins/Notify.js';;
import { Module } from "vuex";

const remunerationModule: Module<RemunerationState, unknown> = {
    namespaced: true,
    state: {
        selectedPackRemuneration: {
            objectType: "odm.product.productfeature.product.productpack.commissionproductpack",
            businessData: null,
            daaq: "/",
            reference: "",
            name: "",
            status:{
                type:{
                    value:""
                }
            },
            classifications: [
              {
      
                  objectType: "odm.product.productclassification",
                  systemUid: "odm-product",
                  "businessData": null,
                  daaq: "/",
                  validity: null,
                  categoryClass: {
                      objectType: "odm.product.productcategoryclass",
                      systemUid: "odm-product",
                      resourceUid: "ACTIVITY.PACK"
                  },
                  priority: null,
                  category: {
                      objectType: "odm.product.productcategory",
                      systemUid: "odm-product",
                      resourceUid: "ACTIVITY"
                  }
              }
          ],
            validity: {
              from: "",
              until: "",
            },
          },
        
        selectedVehiculeRemuneration: 
            {
                objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.assetcommissionproduct",
                businessData: null,
                daaq: "/",
                reference: "",
                name: "",
                status:{
                    type:{
                        value:""
                    }
                },
                classifications: [
                  {
        
                      objectType: "odm.product.productclassification",
                      systemUid: "odm-product",
                      "businessData": null,
                      daaq: "/",
                      validity: null,
                      categoryClass: {
                          objectType: "odm.product.productcategoryclass",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE.VEHICLE"
                      },
                      priority: null,
                      category: {
                          objectType: "odm.product.productcategory",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE"
                      }
                    }
                ],
                accessoryProductRule: [{
                    amount: '0'  
                }],
          
                validity: {
                  from: "",
                  until: "",
                },
                beneficiaryRole: {
                                objectType: "odm.product.beneficiaryrole",
                                systemUid: "odm-product",
                                resourceUid: "SELLER"
                            },

        },
        selectedFinancingRemuneration: 
            {
                objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.originationcommissionproduct",
                businessData: null,
                daaq: "/",
                reference: "",
                name: "",
                status:{
                    type:{
                        value:""
                    }
                },
                classifications: [
                  {
        
                      objectType: "odm.product.productclassification",
                      systemUid: "odm-product",
                      "businessData": null,
                      daaq: "/",
                      validity: null,
                      categoryClass: {
                          objectType: "odm.product.productcategoryclass",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE.FINANCING"
                      },
                      priority: null,
                      category: {
                          objectType: "odm.product.productcategory",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE"
                      }
                    }
                ],
                accessoryProductRule: [{
                    amount: 0  
                }],
          
                validity: {
                  from: "",
                  until: "",
                },
                beneficiaryRole: {
                                objectType: "odm.product.beneficiaryrole",
                                systemUid: "odm-product",
                                resourceUid: "SELLER"
                            },

        },
        selectedFeesRemuneration: 
            {
                objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.originationcommissionproduct",
                businessData: null,
                daaq: "/",
                reference: "",
                name: "",
                status:{
                    type:{
                        value:""
                    }
                },
                classifications: [
                  {
        
                      objectType: "odm.product.productclassification",
                      systemUid: "odm-product",
                      "businessData": null,
                      daaq: "/",
                      validity: null,
                      categoryClass: {
                          objectType: "odm.product.productcategoryclass",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE.FEES"
                      },
                      priority: null,
                      category: {
                          objectType: "odm.product.productcategory",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE"
                      }
                    }
                ],
                accessoryProductRule: [{
                    amount: 0  
                }],
          
                validity: {
                  from: "",
                  until: "",
                },
                beneficiaryRole: {
                                objectType: "odm.product.beneficiaryrole",
                                systemUid: "odm-product",
                                resourceUid: "SELLER"
                            },

        },
        selectedServiceRemuneration: 
            {
                objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.assetcommissionproduct",
                businessData: null,
                daaq: "/",
                reference: "",
                name: "",
                status:{
                    type:{
                        value:""
                    }
                },
                classifications: [
                  {
        
                      objectType: "odm.product.productclassification",
                      systemUid: "odm-product",
                      "businessData": null,
                      daaq: "/",
                      validity: null,
                      categoryClass: {
                          objectType: "odm.product.productcategoryclass",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE.SERVICES"
                      },
                      priority: null,
                      category: {
                          objectType: "odm.product.productcategory",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE"
                      }
                    }
                ],
                accessoryProductRule: [{
                    amount: 0  
                }],
          
                validity: {
                  from: "",
                  until: "",
                },
                beneficiaryRole: {
                                objectType: "odm.product.beneficiaryrole",
                                systemUid: "odm-product",
                                resourceUid: "SELLER"
                            },

        },
        selectedAccessoryRemuneration: 
            {
                objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.assetcommissionproduct",
                businessData: null,
                daaq: "/",
                reference: "",
                name: "",
                status:{
                    type:{
                        value:""
                    }
                },
                classifications: [
                  {
        
                      objectType: "odm.product.productclassification",
                      systemUid: "odm-product",
                      "businessData": null,
                      daaq: "/",
                      validity: null,
                      categoryClass: {
                          objectType: "odm.product.productcategoryclass",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE.ACCESSORY"
                      },
                      priority: null,
                      category: {
                          objectType: "odm.product.productcategory",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE"
                      }
                    }
                ],
                accessoryProductRule: [{
                    amount: 0  
                }],
          
                validity: {
                  from: "",
                  until: "",
                },
                beneficiaryRole: {
                                objectType: "odm.product.beneficiaryrole",
                                systemUid: "odm-product",
                                resourceUid: "SELLER"
                            },

        },
        selectedIntermediaryRemuneration: 
            {
                objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.originationcommissionproduct",
                businessData: null,
                daaq: "/",
                reference: "",
                name: "",
                status:{
                    type:{
                        value:""
                    }
                },
                classifications: [
                  {
        
                      objectType: "odm.product.productclassification",
                      systemUid: "odm-product",
                      "businessData": null,
                      daaq: "/",
                      validity: null,
                      categoryClass: {
                          objectType: "odm.product.productcategoryclass",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE.INTERMEDIARY"
                      },
                      priority: null,
                      category: {
                          objectType: "odm.product.productcategory",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE"
                      }
                    }
                ],
                accessoryProductRule: [{
                    amount: 0  
                }],
          
                validity: {
                  from: "",
                  until: "",
                },
                beneficiaryRole: {
                                objectType: "odm.product.beneficiaryrole",
                                systemUid: "odm-product",
                                resourceUid: "SELLER"
                            },

        },
        selectedAgentRemuneration: 
            {
                objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.originationcommissionproduct",
                businessData: null,
                daaq: "/",
                reference: "",
                name: "",
                status:{
                    type:{
                        value:""
                    }
                },
                classifications: [
                  {
        
                      objectType: "odm.product.productclassification",
                      systemUid: "odm-product",
                      "businessData": null,
                      daaq: "/",
                      validity: null,
                      categoryClass: {
                          objectType: "odm.product.productcategoryclass",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE.AGENT"
                      },
                      priority: null,
                      category: {
                          objectType: "odm.product.productcategory",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE"
                      }
                    }
                ],
                accessoryProductRule: [{
                    amount: 0  
                }],
          
                validity: {
                  from: "",
                  until: "",
                },
                beneficiaryRole: {
                                objectType: "odm.product.beneficiaryrole",
                                systemUid: "odm-product",
                                resourceUid: "SELLER"
                            },

        },
        isLoading: false,
        mode: RemunerationStatus.CREATE,
        validField: true,
    },
    
    getters: {
        getPackRemuneration(){
            return {
                objectType: "odm.product.productfeature.product.productpack.commissionproductpack",
            businessData: null,
            daaq: "/",
            reference: "",
            name: "",
            status:{
                type:{
                    value:""
                }
            },
            classifications: [
              {
      
                  objectType: "odm.product.productclassification",
                  systemUid: "odm-product",
                  "businessData": null,
                  daaq: "/",
                  validity: null,
                  categoryClass: {
                      objectType: "odm.product.productcategoryclass",
                      systemUid: "odm-product",
                      resourceUid: "ACTIVITY.PACK"
                  },
                  priority: null,
                  category: {
                      objectType: "odm.product.productcategory",
                      systemUid: "odm-product",
                      resourceUid: "ACTIVITY"
                  }
              }
          ],
            validity: {
              from: "",
              until: "",
            }
            }
        },
        getVehiculeRemuneration(){
             return {
         
                objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.assetcommissionproduct",
                businessData: null,
                daaq: "/",
                reference: "",
                name: "",
                status:{
                    type:{
                        value:""
                    }
                },
                classifications: [
                  {
        
                      objectType: "odm.product.productclassification",
                      systemUid: "odm-product",
                      "businessData": null,
                      daaq: "/",
                      validity: null,
                      categoryClass: {
                          objectType: "odm.product.productcategoryclass",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE.VEHICLE"
                      },
                      priority: null,
                      category: {
                          objectType: "odm.product.productcategory",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE"
                      }
                    }
                ],
                accessoryProductRule: [{
                    amount: 0  
                }],
          
                validity: {
                  from: "",
                  until: "",
                },
                beneficiaryRole: {
                                objectType: "odm.product.beneficiaryrole",
                                systemUid: "odm-product",
                                resourceUid: "SELLER"
                            },

        
    }
        },
        getFinancingRemuneration(){
    return {
        
                objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.originationcommissionproduct",
                businessData: null,
                daaq: "/",
                reference: "",
                name: "",
                status:{
                    type:{
                        value:""
                    }
                },
                classifications: [
                  {
        
                      objectType: "odm.product.productclassification",
                      systemUid: "odm-product",
                      "businessData": null,
                      daaq: "/",
                      validity: null,
                      categoryClass: {
                          objectType: "odm.product.productcategoryclass",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE.FINANCING"
                      },
                      priority: null,
                      category: {
                          objectType: "odm.product.productcategory",
                          systemUid: "odm-product",
                          resourceUid: "COMMISSION_TYPE"
                      }
                    }
                ],
                accessoryProductRule: [{
                    amount: 0  
                }],
          
                validity: {
                  from: "",
                  until: "",
                },
                beneficiaryRole: {
                                objectType: "odm.product.beneficiaryrole",
                                systemUid: "odm-product",
                                resourceUid: "SELLER"
                            },

    }
        },
        getFeesRemuneration(){
            return {
                
                        objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.originationcommissionproduct",
                        businessData: null,
                        daaq: "/",
                        reference: "",
                        name: "",
                        status:{
                            type:{
                                value:""
                            }
                        },
                        classifications: [
                        {
                
                            objectType: "odm.product.productclassification",
                            systemUid: "odm-product",
                            "businessData": null,
                            daaq: "/",
                            validity: null,
                            categoryClass: {
                                objectType: "odm.product.productcategoryclass",
                                systemUid: "odm-product",
                                resourceUid: "COMMISSION_TYPE.FEES"
                            },
                            priority: null,
                            category: {
                                objectType: "odm.product.productcategory",
                                systemUid: "odm-product",
                                resourceUid: "COMMISSION_TYPE"
                            }
                            }
                        ],
                        accessoryProductRule: [{
                            amount: 0  
                        }],
                
                        validity: {
                        from: "",
                        until: "",
                        },
                        beneficiaryRole: {
                                        objectType: "odm.product.beneficiaryrole",
                                        systemUid: "odm-product",
                                        resourceUid: "SELLER"
                                    },

                
            }
        },
        getServiceRemuneration(){
            return {
                
                        objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.assetcommissionproduct",
                        businessData: null,
                        daaq: "/",
                        reference: "",
                        name: "",
                        status:{
                            type:{
                                value:""
                            }
                        },
                        classifications: [
                        {
                
                            objectType: "odm.product.productclassification",
                            systemUid: "odm-product",
                            "businessData": null,
                            daaq: "/",
                            validity: null,
                            categoryClass: {
                                objectType: "odm.product.productcategoryclass",
                                systemUid: "odm-product",
                                resourceUid: "COMMISSION_TYPE.SERVICES"
                            },
                            priority: null,
                            category: {
                                objectType: "odm.product.productcategory",
                                systemUid: "odm-product",
                                resourceUid: "COMMISSION_TYPE"
                            }
                            }
                        ],
                        accessoryProductRule: [{
                            amount: 0  
                        }],
                
                        validity: {
                        from: "",
                        until: "",
                        },
                        beneficiaryRole: {
                                        objectType: "odm.product.beneficiaryrole",
                                        systemUid: "odm-product",
                                        resourceUid: "SELLER"
                                    },

                
            }
        },
        getAccessoryRemuneration(){
            return {
                
                        objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.assetcommissionproduct",
                        businessData: null,
                        daaq: "/",
                        reference: "",
                        name: "",
                        status:{
                            type:{
                                value:""
                            }
                        },
                        classifications: [
                        {
                
                            objectType: "odm.product.productclassification",
                            systemUid: "odm-product",
                            "businessData": null,
                            daaq: "/",
                            validity: null,
                            categoryClass: {
                                objectType: "odm.product.productcategoryclass",
                                systemUid: "odm-product",
                                resourceUid: "COMMISSION_TYPE.ACCESSORY"
                            },
                            priority: null,
                            category: {
                                objectType: "odm.product.productcategory",
                                systemUid: "odm-product",
                                resourceUid: "COMMISSION_TYPE"
                            }
                            }
                        ],
                        accessoryProductRule: [{
                            amount: 0  
                        }],
                
                        validity: {
                        from: "",
                        until: "",
                        },
                        beneficiaryRole: {
                                        objectType: "odm.product.beneficiaryrole",
                                        systemUid: "odm-product",
                                        resourceUid: "SELLER"
                                    },

                
            }
        },
        getIntermediaryRemuneration(){
            return {
                
                        objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.originationcommissionproduct",
                        businessData: null,
                        daaq: "/",
                        reference: "",
                        name: "",
                        status:{
                            type:{
                                value:""
                            }
                        },
                        classifications: [
                        {
                
                            objectType: "odm.product.productclassification",
                            systemUid: "odm-product",
                            "businessData": null,
                            daaq: "/",
                            validity: null,
                            categoryClass: {
                                objectType: "odm.product.productcategoryclass",
                                systemUid: "odm-product",
                                resourceUid: "COMMISSION_TYPE.INTERMEDIARY"
                            },
                            priority: null,
                            category: {
                                objectType: "odm.product.productcategory",
                                systemUid: "odm-product",
                                resourceUid: "COMMISSION_TYPE"
                            }
                            }
                        ],
                        accessoryProductRule: [{
                            amount: 0  
                        }],
                
                        validity: {
                        from: "",
                        until: "",
                        },
                        beneficiaryRole: {
                                        objectType: "odm.product.beneficiaryrole",
                                        systemUid: "odm-product",
                                        resourceUid: "SELLER"
                                    },

            }
        },
        getAgentRemuneration(){
            return {
                
                        objectType: "odm.product.productfeature.product.accessoryproduct.commissionproduct.originationcommissionproduct",
                        businessData: null,
                        daaq: "/",
                        reference: "",
                        name: "",
                        status:{
                            type:{
                                value:""
                            }
                        },
                        classifications: [
                        {
                
                            objectType: "odm.product.productclassification",
                            systemUid: "odm-product",
                            "businessData": null,
                            daaq: "/",
                            validity: null,
                            categoryClass: {
                                objectType: "odm.product.productcategoryclass",
                                systemUid: "odm-product",
                                resourceUid: "COMMISSION_TYPE.AGENT"
                            },
                            priority: null,
                            category: {
                                objectType: "odm.product.productcategory",
                                systemUid: "odm-product",
                                resourceUid: "COMMISSION_TYPE"
                            }
                            }
                        ],
                        accessoryProductRule: [{
                            amount: '0'  
                        }],
                
                        validity: {
                        from: "",
                        until: "",
                        },
                        beneficiaryRole: {
                                        objectType: "odm.product.beneficiaryrole",
                                        systemUid: "odm-product",
                                        resourceUid: "SELLER"
                                    },

                    
                }
        },
        getEmptyProductCategoryclass() {
        return {
                resourceUid: "",
                objectType: "odm.product.productcategoryclass",
                systemUid: "odm-product",
            }
        }
},
       
    actions: {
        searchRemuneration({ commit }, payload) {
            const { filter, callback } = payload;
            searchRemuneration(filter)
              .then((res) => {
                callback(res);
              })
              .catch((e) => console.error(e));
          },

        saveCommission({ commit }, payload) {
                  
                    const { remunerationPackDetail,remunerationVehiculeDetail,remunerationFinancingDetail,remunerationFeesDetail,remunerationServiceDetail,remunerationIntermediaryDetail,remunerationAccessoryDetail,remunerationAgentDetail, callback } = payload;
                    const remunerationPackDetails = deepClone(remunerationPackDetail);
                    const remunerationVehiculeDetails = deepClone(remunerationVehiculeDetail);
                    saveCommission(remunerationPackDetails, remunerationVehiculeDetails,remunerationFinancingDetail,remunerationFeesDetail,remunerationServiceDetail,remunerationIntermediaryDetail,remunerationAccessoryDetail,remunerationAgentDetail).then((res: any) => {

                        getSelectedPackCommission(res.
                            resourceUid
                            ).then(async (packCommission: any) => {
                            const packCommision = await buildPackCommission(packCommission)
                            const vehiculeCommision = await buildCommission(packCommission, "Vehicle_car", "product-AssetCommissionProduct" , ScaleBuisinessRuleSection.COMMISSION_VEHICULE)
                            const financingCommision = await buildCommission(packCommission, "Financing_car", "product-OriginationCommissionProduct", ScaleBuisinessRuleSection.COMMISSION_FININCING)
                            const serviceCommision = await buildCommission(packCommission, "Services_car", "product-AssetCommissionProduct", ScaleBuisinessRuleSection.COMMISSION_SERVICE)
                            const accessoryCommission = await buildCommission(packCommission, "Accessory_car", "product-AssetCommissionProduct", ScaleBuisinessRuleSection.COMMISSION_ACCESORY)
                            const FeesCommission = await buildCommission(packCommission, "Frais_car", "product-AssetCommissionProduct", ScaleBuisinessRuleSection.COMMISSION_FEES)
                            const IntermediaryCommission = await buildCommission(packCommission, "Intermediary_car", "product-OriginationCommissionProduct", ScaleBuisinessRuleSection.COMMISSION_ACCESORY)
                            const agentCommision = await buildCommission(packCommission, "Agent_car", "product-OriginationCommissionProduct", ScaleBuisinessRuleSection.COMMISSION_AGENT)
                           
                            //product-OriginationCommissionProduct"
    
                            commit('setPackCommissionSelected', packCommision)
                            commit('setvehiculeCommissionSelected', vehiculeCommision ? vehiculeCommision : store.getters["remunerationModule/getVehiculeRemuneration"] )
                            commit('setfinancingCommissionSelected', financingCommision?financingCommision : store.getters["remunerationModule/getFinancingRemuneration"] )
                            commit('setServiceCommissionSelected', serviceCommision?serviceCommision : store.getters["remunerationModule/getServiceRemuneration"] )
                            commit('setaccessoryCommissionSelected', accessoryCommission?accessoryCommission : store.getters["remunerationModule/getAccessoryRemuneration"] )
                            commit('setFeesCommissionSelected', FeesCommission?FeesCommission : store.getters["remunerationModule/getFeesRemuneration"] )
                            commit('setIntermediaryCommissionSelected', IntermediaryCommission? IntermediaryCommission : store.getters["remunerationModule/getIntermediaryRemuneration"] )
                            commit('setAgentCommissionSelected', agentCommision? agentCommision : store.getters["remunerationModule/getAgentRemuneration"] )
                        Notify.create({
                            message: i18n.global.t('main.dialog.success', { entity: res.name }),
                            color: 'positive'
                        })
                    })
                 
                    }).catch((err: any) => {
                        // Logs the error to the console.
                        console.error(err);
                        Notify.create({
                            message: i18n.global.t('main.dialog.error'),
                            color: 'negative'
                        })
                      
                    })
                },


        executeSelectedRemuneration({ commit }, payload) {
                    const { id, callback } = payload;
                    getSelectedPackCommission(id).then(async (packCommission: any) => {
                        const packCommision = await buildPackCommission(packCommission)

                        const vehiculeCommision = await buildCommission(packCommission, "Vehicle_car", "product-AssetCommissionProduct" , ScaleBuisinessRuleSection.COMMISSION_VEHICULE)
                        const financingCommision = await buildCommission(packCommission, "Financing_car", "product-OriginationCommissionProduct", ScaleBuisinessRuleSection.COMMISSION_FININCING)
                        const serviceCommision = await buildCommission(packCommission, "Services_car", "product-AssetCommissionProduct", ScaleBuisinessRuleSection.COMMISSION_SERVICE)
                        const accessoryCommission = await buildCommission(packCommission, "Accessory_car", "product-AssetCommissionProduct", ScaleBuisinessRuleSection.COMMISSION_ACCESORY)
                        const FeesCommission = await buildCommission(packCommission, "Frais_car", "product-AssetCommissionProduct", ScaleBuisinessRuleSection.COMMISSION_FEES)
                        const IntermediaryCommission = await buildCommission(packCommission, "Intermediary_car", "product-OriginationCommissionProduct", ScaleBuisinessRuleSection.COMMISSION_ACCESORY)
                        const agentCommision = await buildCommission(packCommission, "Agent_car", "product-OriginationCommissionProduct", ScaleBuisinessRuleSection.COMMISSION_AGENT)
                       
                        //product-OriginationCommissionProduct"

                        commit('setPackCommissionSelected', packCommision)
                        commit('setvehiculeCommissionSelected', vehiculeCommision ? vehiculeCommision : store.getters["remunerationModule/getVehiculeRemuneration"] )
                        commit('setfinancingCommissionSelected', financingCommision?financingCommision : store.getters["remunerationModule/getFinancingRemuneration"] )
                        commit('setServiceCommissionSelected', serviceCommision?serviceCommision : store.getters["remunerationModule/getServiceRemuneration"] )
                        commit('setaccessoryCommissionSelected', accessoryCommission?accessoryCommission : store.getters["remunerationModule/getAccessoryRemuneration"] )
                        commit('setFeesCommissionSelected', FeesCommission?FeesCommission : store.getters["remunerationModule/getFeesRemuneration"] )
                        commit('setIntermediaryCommissionSelected', IntermediaryCommission? IntermediaryCommission : store.getters["remunerationModule/getIntermediaryRemuneration"] )
                        commit('setAgentCommissionSelected', agentCommision? agentCommision : store.getters["remunerationModule/getAgentRemuneration"] )
                        if (callback) {
                            callback(packCommission)
                        }
                    }).catch((err:any) => {
                        Notify.create({
                            message: `Error searching Good \n ${err}`,
                            color: 'negative'
                        });
                    })
                },
        initRemuneration({ commit }){
            commit('initRemuneration')
        }

    },
    mutations: {
        setPackCommissionSelected(state, payload) {
            state.selectedPackRemuneration = payload
        },
        setvehiculeCommissionSelected(state, payload) {
            state.selectedVehiculeRemuneration = payload
        },
        setfinancingCommissionSelected(state, payload) {
            state.selectedFinancingRemuneration = payload
        },
        setServiceCommissionSelected(state, payload) {
            state.selectedServiceRemuneration = payload
        },
        setaccessoryCommissionSelected(state, payload) {
            state.selectedAccessoryRemuneration = payload
        },
        setFeesCommissionSelected(state, payload) {
            state.selectedFeesRemuneration = payload
        },
        setIntermediaryCommissionSelected(state, payload) {
            state.selectedIntermediaryRemuneration = payload
        },
        setAgentCommissionSelected(state, payload) {
            state.selectedAgentRemuneration = payload
        },
        initRemuneration(state) {
            state.selectedPackRemuneration = store.getters["remunerationModule/getPackRemuneration"]
            state.selectedVehiculeRemuneration = store.getters["remunerationModule/getVehiculeRemuneration"]
            state.selectedFinancingRemuneration =store.getters["remunerationModule/getFinancingRemuneration"]
            state.selectedFeesRemuneration =  store.getters["remunerationModule/getFeesRemuneration"]
            state.selectedServiceRemuneration = store.getters["remunerationModule/getServiceRemuneration"] 
            state.selectedAccessoryRemuneration = store.getters["remunerationModule/getAccessoryRemuneration"]
            state.selectedIntermediaryRemuneration = store.getters["remunerationModule/getIntermediaryRemuneration"]
            state.selectedAgentRemuneration = store.getters["remunerationModule/getAgentRemuneration"]
        },
        
    }
}
export default remunerationModule;
