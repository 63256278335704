
  import store from "@/store";

  import { Vue } from "vue-class-component";
  
  
  export default class BusinessruleValueForm extends Vue {
  
    

    get briIdex(){
    return store.state.queryBuilderModule.briIndex || 0
  }

  get isEditingBri(){
  return store.state.queryBuilderModule.isEditingBri
  }

  get intBRRule() {
    const businessRuleValue = store.state.ActionModule.selectedbusinessrulevalueconfig.businessRuleValue;
  
  // Vérifier si `businessRuleValue` est null ou non défini
  if (!businessRuleValue || !Array.isArray(businessRuleValue)) {
    store.state.ActionModule.selectedbusinessrulevalueconfig.businessRuleValue = [];
  }

  if (this.isEditingBri) {
    const rule = store.state.ActionModule.selectedbusinessrulevalueconfig.businessRuleValue[this.briIdex];
    
    // Vérifier que `rule` existe avant d’y accéder
    if (rule) {
      rule.checked = this.normalizeBoolean(rule.checked);
      return rule;
    } else {
      return {}; // Retourner un objet vide pour éviter des erreurs
    }
  } else {
    store.state.ActionModule.selectedbusinessrulevalueconfig.businessRuleValue.push({
      checked: true,
      advancedConfigurationToggle: true
    });

    const newRule = store.state.ActionModule.selectedbusinessrulevalueconfig.businessRuleValue[
      store.state.ActionModule.selectedbusinessrulevalueconfig.businessRuleValue.length - 1
    ];

    newRule.checked = this.normalizeBoolean(newRule.checked);

    return newRule;
  }
    }

    normalizeBoolean(value:any ) {
    return value === true || value === "true";
  }

    onChangeBR(event : any ){
      if(store.state.queryBuilderModule.queryBuilder.DOCUMENTBR.briResults.length >0) {
        store.state.queryBuilderModule.queryBuilder.DOCUMENTBR.briResults[this.briIdex].checked=event
      }
      else{
        store.state.queryBuilderModule.queryBuilder.DOCUMENTBR.briResults.push({
            checked: event
        })
      }
  
  }
  }
  