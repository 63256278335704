
import i18n from "@/i18n";
import store from "@/store";
import { createTeam, executeGetTeam, executeSaveTeam, getUsersListToCreateManager, searchTeams } from "@/store/services/configuration/teamService";
import { TeamState } from "@/types";
import { mountSelectFields, unmountSelectFieldsV2 } from "@/utils";
import Notify from 'quasar/src/plugins/Notify.js';;
import { Module } from "vuex";

const teamModule: Module<TeamState, unknown> = {
  namespaced: true,
  state: {
    selectedTeam:{
        resourceUid: "",
        description : '',
        validity :{ from: "", until: "" },
        manager: {
            type: {
              value: ''
            }
        },
        members: [],
        secretary: {
          type: {
            value: ''
          }
         },
        },
        networkNode: {value:'CHERBOURG',label: i18n.global.t("users.networkNode.CHERBOURG")},
    isLoading: false,
  },
  getters: {
    getTeamEmpty() {
        return {
                "objectType": "odm.user.team",
                "systemUid": "odm-user",
                "businessData": null,
                "daaq": "/",
                "description": "",
                "validity": {},
                "manager": {
                    "objectType": "odm.user.leaseforgeuser",
                    "systemUid": "odm-user",
                    "resourceUid": ""
                },
                "members": [
                ]
            }
      },
      getTeamMemberEmpty() {
        return  {
            "businessData": null,
            "daaq": "/",
            "objectType": "odm.user.teammember",
            "systemUid": "odm-user",
            "validity": {},
            "member": {
                "resourceUid": "",
                "objectType": "odm.user.leaseforgeuser",
                "systemUid": "odm-user"
            }
        }
      },
      getInitialNetworkNode(){
        return  {
          value:'CHERBOURG',label: i18n.global.t("users.networkNode.CHERBOURG")
        }
      }
   
  },
  actions: {
    usersList({ commit }, payload) {
        const { filter, callback } = payload;
        getUsersListToCreateManager(filter)
          .then((res) => {
            callback(res);
          })
          .catch((e) => console.error(e));
    },
    async saveTeam({ commit }, payload) {
        const { team, callback} = payload
        await executeSaveTeam(team)
                .then((res:any) => {
                    Notify.create({
                        message: i18n.global.t('main.dialog.success', { entity: res.data?.resourceUid }),
                        color: "positive",
                    })
                    //mountSelectFields(res, ['manager'])
                    commit('setTeam', res.data);
                })
                .catch((e:any) => console.error(e))
      },
      async getTeam({ commit }, payload) {
       await executeGetTeam(payload)
                .then((res:any) => {
                    commit('setTeam', res.data);
                })
                .catch((e:any) => console.error(e))
      },

     setNetworkNode({ commit }, payload) {
        commit('setNetworkNode',payload);
    },
      cleanTeam({ commit }, payload) {
        commit('setInitial');
    },
    searchTeams({ commit }, payload) {
      const { filter, callback } = payload;
      searchTeams(filter)
        .then((res) => {
          callback(res);
        })
        .catch((e) => console.error(e));
    }
  },
  mutations: {
    setInitial(state) {
        state.selectedTeam = {
            resourceUid: "",
            description : '',
            validity :{ from: "", until: "" },
            manager: {
                type: {
                value: ''
                }
            },
            members: [],
            secretary: {
              type: {
                value: ''
              }
            }
        }
        state.networkNode = store.getters["teamModule/getInitialNetworkNode"]
    },
    setTeam(state, payload) {
        state.selectedTeam = payload
    },
    setNetworkNode(state, payload) {
        state.networkNode = payload
    }
 },
};

export default teamModule;
