import { Picklist, ScaleBuisinessRuleSection} from "@/types";

import {deepClone, getOperator, globalConfig, headers} from "@/utils";
import {settings} from "@/settings";
import axios from "axios";
import Notify from 'quasar/src/plugins/Notify.js';;
import moment from "moment";
import { api, apiAnonymousToken } from "@/auth/api";
import store from "@/store";
import { extract } from "@/commons/businessRules";
const api_query_url = settings.api_query_url;

export const BusnissRuleValueKeys: string[] = ["checked"];
export const outputFormConfiguration: any = {
  checked: ["checked"],
};
const buisinessRuleResults = {
  checked: true,
};
export function searchCheckPointsConfig(filter: any): Promise<Picklist> {
    return new Promise<Picklist>(async (resolve, reject) => {
      const url = settings.api_query_url;
  
      const qc_header: any = {
        qc: {
          queryId: "search-supportingdocument-check-points-config",
        },
      };
      qc_header.qc.parameters = {
        ...filter,
      };
      const operators:any = [];
     
      
      if (filter && filter.checkpointsid) {
        operators.push(
            getOperator(
              "lower(supportingdocument_check_points_config",
              "id)",
              "LIKE",
              globalConfig.formatters
                .getEqualValue("%" + filter.checkpointsid + "%")
                .toLowerCase(),
              "id"
            )
          );
      }

      if (filter && filter.i18nlabel) {
        operators.push(
            getOperator(
              "lower(supportingdocument_check_points_config",
              "i_18n_label)",
              "LIKE",
              globalConfig.formatters
                .getEqualValue("%" + filter.i18nlabel + "%")
                .toLowerCase(),
              "i_18n_label"
            )
          );
      }
      if (filter && filter.isEnable !== undefined && filter.isEnable.value !== undefined) {
        operators.push(
          getOperator(
            "supportingdocument_check_points_config",
            "enable",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.isEnable.value),
            "enable"
          )
        );
      }
      if (filter && filter.checkpointsdiscriminator !== undefined && filter.checkpointsdiscriminator.value !== undefined) {
        operators.push(
          getOperator(
            "supportingdocument_check_points_config",
            "_discriminator",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.checkpointsdiscriminator.value),
            "_discriminator"
          )
        );
      }
      if (filter && filter.typecode !== undefined && filter.typecode.value !== undefined) {
        operators.push(
          getOperator(
            "supportingdocument_check_points_config",
            "type_code",
            "EQUAL",
            globalConfig.formatters.getEqualValue(filter.typecode.value),
            "type_code"
          )
        );
      }
     
      if (operators.length > 0) {
        qc_header.qc.otherFilters = {
          expressions: [
            {
              and: {
                operators,
              },
            },
          ],
        };
        qc_header.qc.otherOrders = null;
        qc_header.qc.offset = 0;
        qc_header.qc.limit = 100;
      }
  
      const _headers = headers({
        qc: JSON.stringify(qc_header.qc),
      });
  
      axios
        .get(url, {
          headers: _headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
            Notify.create({
              message: `Query returned an error \n ${err}`,
              color: "negative",
            });
          });
    });
}


const supportingdocument = `odm-supportingdocument`
const baseUrl = `${settings.api_url}/${supportingdocument}/api/1/${supportingdocument}`

export async function saveFieldvalueconfig(fieldvalueconfig: any,edit:boolean) {
    if(edit){
        const Fieldvalueconfig= await updateFieldvalueconfig(fieldvalueconfig,fieldvalueconfig.resourceUid)
        return Fieldvalueconfig
    }else{
        const Fieldvalueconfig= await  createFieldvalueconfig(fieldvalueconfig)
        return Fieldvalueconfig
    }
}

export async function  createActionTrigger(actionTrigger: any) {  
    return api().post(`${baseUrl}/actionqueryconfig/`, actionTrigger)
  }

  export async function  createFieldvalueconfig(fieldvalueconfig: any) {  
    return apiAnonymousToken().post(`${baseUrl}/fieldvalueconfig/`, fieldvalueconfig)
  }

export async function  createdocumentvalueconfig(documentvalueconfig: any) {  
  return apiAnonymousToken().post(`${baseUrl}/documentvalueconfig/`, documentvalueconfig)
}
  export async function  updateFieldvalueconfig(fieldvalueconfig: any,resourceUid:any) {  
    return apiAnonymousToken().put(`${baseUrl}/fieldvalueconfig/`+resourceUid+'/', fieldvalueconfig)
  }
  export async function  getFieldvalueconfig(resourceUid: any) {  
    return api().get(`${baseUrl}/fieldvalueconfig/`+resourceUid+'/')
  }

  export async function  getdocumentvalueconfig(resourceUid: any) {  
    return api().get(`${baseUrl}/documentvalueconfig/`+resourceUid+'/')
  }

export async function  updatedocumentvalueconfig(documentvalueconfig: any) {  
  return apiAnonymousToken().put(`${baseUrl}/documentvalueconfig/${documentvalueconfig.resourceUid}/`, documentvalueconfig)
}

export async function  getbusinessrulevalueconfig(resourceUid: any) {  

    const busnissRuleDocumentValue =  await (await api().get(`${baseUrl}/businessrulevalueconfig/`+resourceUid+'/')).data

    if (busnissRuleDocumentValue.businessRuleValue?.rule) {
      
      
        (
          store.state.queryBuilderModule.queryBuilder[
            ScaleBuisinessRuleSection.DOCUMENTBR as any
          ] as any
        ).selectedBuisinessRule = busnissRuleDocumentValue.businessRuleValue.rule;
      
      const extracRule = extract(
        busnissRuleDocumentValue.businessRuleValue.rule,
        buisinessRuleResults
      );
      store.dispatch("queryBuilderModule/setbrResluts", {
        extracRule: extracRule,
        currentSection: ScaleBuisinessRuleSection.DOCUMENTBR,
      });
      busnissRuleDocumentValue.businessRuleValue = extracRule || {};
      
      busnissRuleDocumentValue.businessRuleValue.advancedConfigurationToggle = true;
      
    }

  return busnissRuleDocumentValue
}

export async function  updatebusinessrulevalueconfig(businessrulevalueconfig: any) {  
return apiAnonymousToken().put(`${baseUrl}/businessrulevalueconfig/${businessrulevalueconfig.resourceUid}/`, businessrulevalueconfig)
}

export async function  createbusinessrulevalueconfig(documentvalueconfig: any) {  
  return apiAnonymousToken().post(`${baseUrl}/businessrulevalueconfig/`, documentvalueconfig)
}