import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icons/red-alert-icon.svg'
import _imports_1 from '@/assets/icons/white-alert-icon.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: "Gestion Alert"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  style: {"width":"68px","margin-top":"-22px"},
  alt: "Gestion Alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_AlertDialog = _resolveComponent("AlertDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_btn, {
      class: "badge-danger btn tw-document-trigger-btn",
      onClick: _ctx.toggleShowControls,
      floating: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_badge, {
          color: "red",
          floating: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.alertList.length), 1)
          ]),
          _: 1
        }),
        (_ctx.alertList.length> 0)
          ? (_openBlock(), _createElementBlock("img", _hoisted_1))
          : (_openBlock(), _createElementBlock("img", _hoisted_2)),
        _createVNode(_component_q_tooltip, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("order.tooltip.alert")), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onClick"]),
    (_ctx.toggleShow)
      ? (_openBlock(), _createBlock(_component_AlertDialog, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}